.section_container {
    width: 80%;
    margin: 0 auto;

}

.bg_light {
    background-color: white;
}


.module_background {
    background-color: transparent;
    background-image: linear-gradient(180deg, #f6f5f4 63%, #f8f7f6 100%);
    /* transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s; */
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 120px 0px 120px 0px;
}

.module_background2 {
    background-color: white;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 120px 0px 120px 0px;
}

.main_content {
    margin-top: 65px;
}

.guest_no_buttons {
    position: absolute;
    border-radius: 10px;
    box-shadow: 0 2px 11px 0 rgb(181 127 250/10%);
    padding: 15px 30px;
    background-color: #fff;
    width: 350px;
    margin: 0 0 0 0%;
    display: none;
    z-index: 999;
    /* right: 4%;
    top: 100%; */
    display: block;
    margin-top: 10px;
    border: 1px solid #ddd;
}


.guest_no_buttons_item {
    width: 100%;
    margin: 25px 0;
}

.guest_no_buttons_labels {
    width: 60%;
    display: inline-block;
}

.guest_no_buttons_title_labels {
    font-weight: 700;
    color: #484848;
    font-size: 15px;

}

.guest_no_buttons_description_labels {
    color: #5d6475;
    font-size: 13px;
}

.guest_no_buttons_steppers {
    width: 40%;
    display: flex;
    flex-wrap: nowrap;
    float: right;
    justify-content: flex-end;
}

.guest_no_button_minus,
.guest_no_button_plus {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 1px solid #ddd;
    text-align: center;
    line-height: 20px;
    font-size: 15px;
    cursor: pointer;
    background: 0 0;
    padding: 0;
}

.guest_no_button_value {
    margin: 0 12px;
    font-size: 15px;
    line-height: 32px;
}

.close_btn {
    background-color: white;
    border: none;
    text-decoration: underline;
}

.close_btn:hover {
    color: #3874ff;
}

.property_listings_title {
    font-size: 20px;
    color: #484848;
    font-weight: 700;
    margin-bottom: 0;
    margin-top: 4rem;
}


@media only screen and (max-width: 768px) {


    .module_background {

        padding: 60px 0px 60px 0px !important;
    }

    .module_background2 {

        padding: 60px 0px 60px 0px !important;
    }

    .guest_no_buttons {
        position: absolute;
        border-radius: 10px;
        box-shadow: 0 2px 11px 0 rgb(181 127 250/10%);
        padding: 15px 30px;
        background-color: #fff;
        width: 300px !important;
        margin: 0 0 0 0%;
        display: none;
        z-index: 999;
        right: 0% !important;
        left: 0%;
        /* top: 80% !important; */
        display: block;
        margin-top: 10px;
    }

    .section_container {
        width: 90%;
    }



}


@media only screen and (min-width: 768px) and (max-width: 967px) {}


@media only screen and (min-width: 968px) {}