.forgot_link{
    text-align: center;
    width: 100%;
    display: flex;
    margin-top: 5px;
    margin-bottom: 15px;
    font-weight: 700;
    justify-content: center;
    align-items: center;
}

.forgot_link p{
    cursor: pointer;
    color: #484848 !important;
    font-size: 14px;
    margin-right: 10px;
    margin-left: 10px;
    white-space: nowrap;
}

.forgot_link p:hover{
    color: #3874ff !important;
}

.booking_btn{
    width: 100%;
    height: auto;
    border-radius: 4px !important;
    background-color: #3874ff !important;
    text-transform: none !important;
    padding: 0.7rem 0rem !important;
    font-size: 15px !important;
    font-weight: 700 !important;
    margin-bottom: 10px !important;
    outline: none;
    border: none;
    color: white;
}

.facebook_btn{
    width: 100%;
    height: auto;
    border-radius: 4px !important;
    background-color: #4b6cc9 !important;
    text-transform: none !important;
    padding: 0.7rem 0rem !important;
    font-size: 15px !important;
    font-weight: 700 !important;
    margin-bottom: 10px !important;
}

.facebook_btn:hover{
    background-color: #425398 !important;
}

.google_btn{
    width: 100%;
    height: auto;
    border-radius: 4px !important;
    background-color: #ed5565 !important;
    text-transform: none !important;
    padding: 0.7rem 0rem !important;
    font-size: 15px !important;
    font-weight: 700 !important;
    margin-bottom: 10px !important;
}

.google_btn:hover{
    background-color: #da4453 !important;
}

.twitter_btn{
    width: 100%;
    height: auto;
    border-radius: 4px !important;
    background-color: #00aced !important;
    text-transform: none !important;
    padding: 0.7rem 0rem !important;
    font-size: 15px !important;
    font-weight: 700 !important;
    margin-bottom: 10px !important ;
}

.twitter_btn:hover{
    background-color: #0790c5 !important;
}

.formInput input{
    padding: 14.5px 14px !important;
}

.formInput input:focus{
    border-color: #e3e7ef !important;
    color: #484848 !important;
    background-color: #e3e7ef !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    outline: none !important;
}


@media only screen and (min-width: 968px) and (max-width:1300px) {

    .forgot_link p{
        cursor: pointer;
        color: #484848 !important;
        font-size: 10px;
        margin-right: 5px;
        margin-left: 5px;
        white-space: nowrap;
    }

}