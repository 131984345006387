@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,500;0,700;0,800;1,500&display=swap');

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100dvh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}


.main-container {
    display: flex;
}



.title {
    font-size: 3rem;

    place-items: center;
    text-align: left;
}

/* Sidebar */
.admin-sidebar {
    background: rgb(0, 7, 61);
    color: white;
    height: 100dvh;
    overflow-y: auto;
    transition: all 0.3s ease-in-out 0s;
}



.top_section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 10px;
}

.logo {
    font-size: 18px;
    line-height: 0;
    text-decoration: none;
}

.bars2 {
    width: 30px;
    color: #000;
}

.hide {
    display: none;
}

.search {
    display: flex;
    align-items: center;
    /* margin: 10px 0; */
    height: 30px;
    padding: 10px 15px;
}

.search input {
    border: none;
    /* margin-left: 10px; */
    border-radius: 5px;
    background: rgb(238, 238, 238);
    color: black;
}

.routes {
    padding-top: 25px !important;
    display: flex;
    flex-direction: column;
    gap: 5px;
    height: calc(100vh - 127px);
    overflow-y: hidden;
    padding: 10px;
    overflow: auto;
    overflow-x: hidden;
}

.link2 {
    display: flex;
    color: #000;
    gap: 10px;
    padding: 5px 10px;
    border-right: 4px solid transparent;
    transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.link2:hover {
    border-right: 4px solid white;
    background: #eff2f6;
    transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
    color: #3e465b !important;
    align-items: center;
    border-radius: 6px !important;
}

.link2.active {

    color: #3874ff;
    font-weight: 600;
}

.link_text {
    white-space: nowrap;
    /* margin-top: 0.25rem; */
}

.menu2 {
    display: flex;
    color: black;
    padding: 5px 10px;
    border-right: 4px solid transparent;
    transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
    justify-content: space-between;
}

.menu_item {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
    justify-content: space-between;
}

.menu_container {
    display: flex;
    flex-direction: column;
}

.menu_container .link2 {
    padding-left: 20px;
    /* border-bottom: #000 0.5px solid; */
}


/* .header-card2 {
    background: #ffffff;
    box-shadow: 0px 14px 80px rgb(34 35 58 / 20%);
    transition: all .3s;
    text-align: left;
    position: relative;
    display: flex;
    min-width: 0;
    word-wrap: break-word;
    padding: 10px;
    margin: 1rem;
    border-radius: 10px;
} */





.logo3 {
    width: 150px;
}

/* ::-webkit-scrollbar {
    display: none;
} */



.btn-auth2 {
    background: #7367F0;
    color: white;
    font-weight: 600;
}

.sign-in-logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 15%;
}



.login-image {
    width: 80%;
}

.logout {
    font-size: 22px;
    margin-right: 15px;
    margin-left: 10px;
    color: #00073d;
}

.logout:hover {
    font-size: 22px;
    margin-right: 15px;
    margin-left: 10px;
    color: #00073d;
}

.grow {
    display: inline-block;
    vertical-align: middle;
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgb(0 0 0 / 0%);
    transition-duration: 0.3s;
    transition-property: transform;
}

.grow:hover,
.grow:focus,
.grow:active {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

/* .tablescroll {
    overflow-y: scroll;
    height: 650px;
    display: block;
} */

.mg10 {
    margin: 20px;
}



.user-img {
    border-radius: 50%;
    width: 30%;
}

.user-img2 {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 5px;
}

.user-img3 {
    width: 35px;
    height: 35px;
    border-radius: 50%;
}

.admin-detail {

    margin-left: 10px;
}

.admin-detail h6 {
    font-size: 17px;
    text-transform: capitalize;
    color: #7367F0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 135px;

}

.admin-detail p {
    font-size: 15px;
    color: #ac9f9f;
}



.table-heading2 a {
    text-decoration: none;
    background: #7367F0;
    color: white;
    padding: 0.5rem;
    border-radius: 5px;

}

.dropdown-menu a {
    text-decoration: none;
    background: none;
    color: black;
    padding: 0.5rem;
    border-radius: 0px;
    border-bottom: 1px solid #dedddd;
}

.add-heading {
    margin-left: 25px;
    margin-top: 20px;
}

.form-h {
    max-height: 75dvh;
}



.icon-card {

    background: #ffffff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    padding: 5px 15px;
    border-radius: 5px;
    transition: all .3s;
    text-align: left;
    /* z-index: 1; */
    font-size: 25px;
    margin-right: 20px;
}

.add-heading3 {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.table-h {
    max-height: 95dvh;
    /* max-height: 75dvh; */
}

.detail-h {
    max-height: 84dvh;
}



.form-btn3 {
    background-color: #7367F0;
    color: white;
}

.form-btn2 {
    background: #cfcfcf;
    margin-left: 15px;
}

.form-btn2:hover {
    color: #000;
}



.main2 {
   
    max-height: calc(100dvh - 65px);
    height: calc(100dvh - 65px);
    overflow: auto;
}



.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
    background: #efeff1 !important;
    border-radius: 0px 0px 10px 10px !important;
}

.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar,
.ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
    background: #EBEBEB !important;
    border-radius: 10px 10px 0px 0px !important;
}

.ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable,
.ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners {
    background: #EBEBEB !important;
    border-radius: 0px 0px 10px 10px !important;
}

.modal {
    top: 20%;
    position: absolute;
    left: 30%;
    display: block;
    /* justify-content: center; */
    width: auto;
    height: auto;
    right: 20%;
}

.card label {
    font-weight: 700;
}

.dropdown-menu[data-bs-popper] {
    right: 0;
    left: 0;
}

.overflow-y {
    overflow-y: auto;
}

.add-btn {
    display: flex;
    justify-content: space-between;
}

.add-btn a {
    text-decoration: none;
}

.logout {
    font-size: 22px;
    margin-right: 15px;
    margin-left: 10px;
    color: #00073d;
}

.logout:hover {
    font-size: 22px;
    margin-right: 15px;
    margin-left: 10px;
    color: #00073d;
}

.popup2 {
    position: relative;
    background: rgb(255 5 5 / 70%);
    padding: 0.5rem;
    border-radius: 10px;
    color: white;
    font-weight: 600;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.close-icon {
    text-decoration: none;
    color: white;
    font-size: 18px;
    font-weight: 900;
}

.MuiDataGrid-columnHeaderTitle {
    font-weight: 600 !important;
}

.add-btn {
    display: flex;
    justify-content: space-between;
}

.add-btn a {
    text-decoration: none;
}

.modal {
    top: 25%;
    position: absolute;
    left: 38%;
    display: block;
    /* justify-content: center; */
    width: auto;
    height: auto;
    right: 20%;
}

.modal-title {
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
}

.modal-title a {
    text-decoration: none;
    color: #fff;
    background: #000;
    padding: 0.4rem 0.8rem 0.2rem 0.8rem;
    font-size: 16px;
    /* margin-bottom: 0; */
    border-radius: 5px;
}

.modal-btn {
    display: flex;
    justify-content: center;
}

.form-btn2 {
    background: #cfcfcf;
    margin-left: 15px;
}

.form-btn2:hover {
    color: #000;
}

.blur {
    -webkit-filter: blur(2px);
    -moz-filter: blur(2px);
    -o-filter: blur(2px);
    -ms-filter: blur(2px);
    filter: blur(2px);
}



.detail {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.detail .data {
    font-weight: 400;
}

.heading {
    background: #e4eaf3;
    padding: 0.5rem;
    border-radius: 5px;
}

.detail2 {
    display: flex;
}

.detail2 .data {
    font-weight: 400;
}

.edit-h {
    height: 80dvh;
    overflow-y: auto;
}

.modal-button {
    font-size: 18px;
    margin: 0;
    padding: 3px 10px 1px 10px;
    font-weight: 600;
    background: black;
    color: white;
    /* line-height: 0; */
    border-radius: 4px;
    border: 0;
}

.overflow-y {
    overflow-y: auto;
}

.add-heading {
    margin-left: 25px;
    margin-top: 20px;
}

.form-h {
    max-height: calc(100vh - 171px);
}


.icon-card {

    background: #ffffff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    padding: 5px 15px;
    border-radius: 5px;
    transition: all .3s;
    text-align: left;
    z-index: 1;
    font-size: 25px;
    margin-right: 20px;
}

.verify {
    display: flex;
    justify-content: center;
    height: 100dvh;
    align-items: center;
    font-size: 30px;
}

.forgot-summary {
    color: #878484;
    font-size: 14px;
}

.user-img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 5px;
}

.dropdown-menu[data-bs-popper] {
    right: 0;
    left: 0;
}

.user {
    text-decoration: none;
}

.user h6 {
    margin-left: 10px;
    /* padding: 0; */
    margin-bottom: 0;
}

.margin-b-0 {
    margin-bottom: 0;
}





a {
    /* color: rgb(22, 191, 191); */
    text-decoration: none;
}



.react-pdf__Page__textContent {
    display: none;
}

.react-pdf__Page__annotations {
    display: none;
}

.pdf-view {
    width: 40%;
    border: 1px solid;
    z-index: 1;
    position: relative;
    height: 35rem;
    overflow: auto;
}

.profile-img {
    border-radius: 50%;
    width: 200px;
    height: 200px;
}

.gradient2 {
    background: #d0cfcf;
    border-radius: 10px;
    padding: 1rem;
    color: white;
}

.gradient2 p {
    color: #fff;
}

.gradient2 h6 {
    color: black
}



.routes a {
    text-decoration: none;
}

.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
    background: #efeff1 !important;
    border-radius: 0px 0px 10px 10px !important;
}

.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar,
.ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
    background: #fff !important;
    border-radius: 10px 10px 0px 0px !important;
}

.ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable,
.ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners {
    background: #fff !important;
    border-radius: 0px 0px 10px 10px !important;
}

.success-popup {
    position: relative;
    background: rgb(255 5 5 / 70%);
    padding: 1rem;
    border-radius: 10px;
    color: white;
    font-weight: 600;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.bg-full-screen-image {
    background: url(../public/images/vuexy-login-bg.jpg) no-repeat center center;
    background-size: cover;
}

/* .card {
    margin-bottom: 2.2rem;
    border: none;
    border-radius: 0.5rem;
    box-shadow: 0 4px 25px 0 rgb(0 0 0 / 10%);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
} */

.bg-authentication {
    background-color: #EFF2F7;
}

.flexbox-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100dvh;
    height: calc(var(--dvh, 1dvh) * 100);
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}



.login-summary {
    color: #626262;
}

@media only screen and (min-width: 768px) {
    .card-w {
        width: 55rem;
    }

    .sidebar-size {
        /* width: 4%; */
    }

    .sidebar-size2 {
        width: 16%;
    }

    .wdh1 {
        /* width: 84%; */

    }

    .wdh2 {
        width: 96%;
    }

    .sidebar-card {
        width: 18%;
        background: white;
        box-shadow: 0px 14px 80px rgb(34 35 58 / 20%);
        transition: all .3s;
        text-align: left;
        /* display: flex; */
        min-width: 0;
        word-wrap: break-word;
        
    }

    .header-list {
        display: none;
    }

    .border-r {
        border-right: 1px solid #000;
    }

    .header-card2 {

        background: #ffffff;
        /* box-shadow: 0px 14px 80px rgb(34 35 58 / 20%); */
        transition: all .3s;
        text-align: left;
        position: relative;
        display: flex;
        min-width: 0;
        word-wrap: break-word;
        padding: 5px 20px;
        border-bottom: 1px solid #cbd0dd;
    }

    .logo2 {
        width: 160px;
    }

    .modal2 {
        /* top: 0%; */
        position: absolute;
        left: 10%;
        display: block;
        /* justify-content: center; */
        width: auto;
        height: auto;
        right: 10%;
        z-index: 999;
        margin: auto;
    }

    .card {

        /* margin: auto; */
        background: #ffffff;
        box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
        /* padding: 40px 55px 45px 55px; */
        padding: 30px;
        border-radius: 15px;
        transition: all .3s;
        text-align: left;
    }

    .add-heading2 {
        display: flex;
        align-items: center;
        margin-left: 25px;
        margin-top: 7rem;
    }

    .table-heading2 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1.5rem 1.5rem 0rem 1.5rem;
        /* margin-top: 6.5rem; */
    }

    .admin-dashboard-pad {
        padding: 0rem 3rem 3rem 3rem !important;
    }

    .tab-btn2 {
        font-size: 20px;
        padding: 10px 60px;
        cursor: pointer;
        background: #ffffff;
        box-shadow: 0px 14px 80px rgb(34 35 58 / 35%);
        border: 0;
        outline: 0;
        border-radius: 5px;
        margin-right: 20px;
    }

}

.btn-auth3 {
    border: 1px solid #7367F0;
    color: #7367F0;
}


.sidebar.sidebar-size{
    width: 100%;
    max-width: 64px;
    overflow: hidden;
}
.sidebar.sidebar-size2 {
    width: 100%;
    max-width: 250px;
    overflow: hidden;
}
@media only screen and (max-width: 768px) {

    .dropdown-btn {
        /* padding: 0.3rem !important; */
        /* font-size: 0.8rem; */
      }

    .icon-card {
        background: #fff;
        border-radius: 5px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        font-size: 20px !important;
        margin-right: 20px;
        padding: 3px 8px !important;
        text-align: left;
        transition: all .3s;
        z-index: 1;
      }
    .modal {
        top: 25% !important;
        position: absolute;
        left: 5% !important;
        display: block;
        /* justify-content: center; */
        width: auto !important;
        height: auto !important;
        right: 5% !important;
        z-index: 1;
      }

    .modal {
        top: 25% !important;
        position: absolute;
        left: 5% !important;
        display: block;
        /* justify-content: center; */
        width: auto !important;
        height: auto !important;
        right: 5% !important;
        z-index: 1;
      }

    .main2 {
        padding-bottom: 0rem;
        max-height: calc(100dvh - 55px);
        height: calc(100dvh - 55px);
      }
    .sidebar-size {
        width: 0%;
        position: absolute;
    }

    .sidebar-size2 {
        width: 65%;
        position: absolute;
        z-index: 999;
    }

    .table-heading2 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.5rem 1.5rem 0rem 1.5rem;
        flex-wrap: wrap;
        margin-top: 1rem;
    }

    .card {

        background: #ffffff;
        box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
        /* padding: 40px 55px 45px 55px; */
        padding: 20px;
        border-radius: 15px;
        transition: all .3s;
        text-align: left;
        flex-direction: column;
    }
    .form-h{
        max-height: calc(100vh - 200px);
        margin-right: -5px;
        padding-right: 15px;
    }
    .form-h.v-form-h {
        max-height: initial;
        margin-right: 20px;
    }
    
    .header-card2 {
        background: #ffffff;
        /* box-shadow: 0px 14px 80px rgb(34 35 58 / 20%); */
        transition: all .3s;
        text-align: left;
        position: relative;
        display: flex;
        min-width: 0;
        word-wrap: break-word;
        padding: 5px 20px;
        border-bottom: 1px solid #cbd0dd;
    }

    .wdh1 {
        width: 100%;

    }

    .wdh2 {
        width: 100%;
    }

    .sidebar-card {
        width: 18%;
        background: white;
        box-shadow: 0px 14px 80px rgb(34 35 58 / 20%);
        transition: all .3s;
        text-align: left;
        /* position: relative; */
        /* display: flex; */
        min-width: 0;
        word-wrap: break-word;
    }

    .header-list2 {
        display: none !important;
    }

    .logo2 {
        width: 120px;
    }

    .modal2 {
        top: 50%;
        transform: translateY(-50%);
        position: absolute;
        left: 0%;
        display: block;
        /* justify-content: center; */
        width: auto;
        height: auto;
        right: 0%;
        z-index: 1;
    }

    .add-heading2 {
        display: flex;
        align-items: center;
        margin-left: 25px;
        margin-top: 6rem;
    }

    .admin-dashboard-pad {
        padding: 0rem 1.5rem 1.5rem 1.5rem !important;
    }

    .tab-btn2 {
        font-size: 12px;
        padding: 10px 16px;
        cursor: pointer;
        background: #ffffff;
        box-shadow: 0px 14px 80px rgb(34 35 58 / 35%);
        border: 0;
        outline: 0;
        border-radius: 5px;
        margin-left: 10px;
    }

}

@media only screen and (max-width: 576px) {
    .main2{
        padding-bottom: 1rem;
    }
}
@media only screen and (max-width: 599px) {
    .modal {
        position: fixed;
    }
}

.mail-seccess {
    text-align: center;
    background: #fff;
    border-top: 1px solid #eee;
    height: 100dvh;
    margin: auto;
    display: flex;
    align-items: center;
}

.mail-seccess .success-inner {
    display: inline-block;
    padding-bottom: 2rem;
    border-bottom: 2px solid #efefef;

}

.mail-seccess .success-inner h1 {
    font-size: 100px;
    text-shadow: 3px 5px 2px #3333;
    color: #006DFE;
    font-weight: 700;
}

.mail-seccess .success-inner h1 span {
    display: block;
    font-size: 25px;
    color: #333;
    font-weight: 600;
    text-shadow: none;
    margin-top: 20px;
}

.mail-seccess .success-inner h1 i {
    color: #16BFBF;
}

.mail-seccess .success-inner p {
    padding: 20px 15px;
}

.mail-seccess .success-inner .btn {
    color: #fff;
    font-size: 1rem;
    background-color: #F4B905;
    border: 1px solid #F4B905;
    font-weight: 600;
}

.mail-card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: 0.25rem;
    padding: 3rem;
    box-shadow: 0 4px 25px 0 rgb(0 0 0 / 30%);
    transition: all 0.3s ease-in-out;
}

.mail-seccess .success-footer {
    margin-top: 2rem;

}



.success-footer .resend-btn {
    color: black;
    background-color: gainsboro;
    border: lightgray;
    font-weight: 300;
}

.success-footer .resend-btn:hover {
    color: black;
}

.MuiDataGrid-cell--withRenderer .form-select {
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
}

.dropdown-btn2 {
    background: #b3b3b3 !important;
    border: none;
    padding: 0.5rem;
}

.dropdown-btn2:hover {
    background-color: #aeacac !important;
}

.dropdown-btn2:focus {
    background-color: #aeacac !important;
}

.main-link2 {
    color: black;
    width: 92%;
}

.main-link2:hover {
    color: white !important;
}

.menu2:hover .drop-btn {
    color: #3e465b !important;
}

.menu2:hover {
    background-color: #eff2f6;
    color: #3e465b !important;
    border-radius: 6px !important;
}

.menu2:hover .main-link2 {
    color: #3e465b !important;
}

.active-btn2 {
    background-color: #ced2da;
    color: #000;
    font-weight: 600;
}

.admin-bg-color1 {
    background-color: #f5f7fa;
}

.tab-btn2.active {
    opacity: 1;
    background: #7367F0;
    color: white;
}

.success-popup3 {
    position: absolute;
    background: #7367F0;
    padding: 1rem;
    border-radius: 10px;
    color: white;
    font-weight: 600;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: space-between;
    right: 2rem;
    top: 5rem;
}

.routes:hover {

    overflow-y: auto !important;
}