.main_heading h3 {
  font-size: 18px;
}

.main_heading p {
  font-size: 13px;
}
.custom_radio .readio_design{
  width: 100%;
  max-width: 100px;
}
.custom_radio .readio_design label {
  background: #f5f6fa;
  max-width: 105px;
  border-radius: 6px;
  border: 1px solid #c5cadd;
  overflow: hidden;
  padding: 15px 10px;
  min-width: 86px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  cursor: pointer;
  height: 100%;
  position: relative;
}

.custom_radio .readio_design label::after {
  content: '';
  position: absolute;
  top: 5px;
  right: 5px;
  height: 15px;
  width: 15px;
  background-color: #3874ff;
  border-radius: 15px;
  background-image:url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="0.75em" viewBox="0 0 448 512"><style>svg{fill:%23ffffff}</style><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>');
  color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  display: none;
}

.custom_radio .readio_design label img {
  width: 35px;
  height: 35px;
  object-fit: cover;
  margin: 0 auto;
}

.custom_radio .readio_design label p {
  font-size: 11px;
  margin: 0;
  font-weight: normal;
}

.custom_radio .readio_design input:checked+label {
  border: 1px solid #3874ff;
  background: #eee7ff;
}

.custom_radio .readio_design input:checked+label::after {
  display: inherit;
}

.custom_radio .readio_design input:checked+label p {
  font-weight: bold;
}

.swicht_form p {
  font-size: 13px;
  margin-bottom: 10px;
}

.v_card {
  background-color: #eee;
  border-radius: 10px;
  position: relative;
}

.v_card .v_card_header {
  border-radius: 10px;
}

.v_card .v_card_header .number {
  background-color: #acc5ff;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  font-size: 10px;
  position: absolute;
  top: -9px;
  left: -9px;
  color: #fff;
}

.v_card .v_card_header .cross {
  background-color: #acc5ff;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  font-size: 10px;
  cursor: pointer;
  position: absolute;
  top: -9px;
  right: -9px;
  color: #fff;
}

.v_card .v_card_body .vinput label {
  font-size: 12px;
}

.v_card .v_card_body .vinput input {
  font-size: 13px;
  padding: 13px 7px;
  height: 20px;
}

.v_card .v_card_body .vselect {
  min-width: 120px;
}

.v_card .v_card_body .vselect div {
  padding: 11.4px 7px;
  padding-right: 27px;
  font-size: 13px;
}

.v_card .v_card_body .unit_v_grid div:nth-child(5) div label,
.v_card .v_card_body .unit_v_grid div:nth-child(2) div label,
.v_card .v_card_body .unit_v_grid div:nth-child(8) div label {
  font-size: 12px;
}

.add_document .card_part {
  text-align: center;
  padding: 8px;
  position: relative;
}

.card_part .card_grids {
  justify-items: center;
  align-content: space-between;
  height: 100%;
}

.card_part .card_grids svg {
  font-size: 60px;
  color: #3874ff;
}

.add_document .card_part .delete_icon {
  position: absolute;
  top: 7px;
  right: 7px;
  cursor: pointer;
}

.add_document .card_part img {
  margin: 0;
  margin-bottom: 5px;
}

.add_document .card_part h5 {
  font-size: 12px;
  max-width: 100px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 25px;
}

.add_document .card_part button {
  font-size: 11px;
  border: 1.5px solid #eee;
  background: transparent;
  padding: 5px 8px;
  border-radius: 4px;
}

.listDescription{
 
}

.v_list_type{
  border: 1px solid #eee;

}
.v_work_status_top h3,
.v_work_status_top span{
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 0px;
  color: #484848;
  min-width: 140px;
}
.v_work_status_top span {
  width: 100%;
  max-width: 265px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.v_work_status_top p{
  width: 100%;
  max-width: 280px;
  font-size: 14px;
  overflow: hidden;
  margin-bottom: 0;
  color: #555555;
  font-weight: 300;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.v_work_status_top p svg,
.v_work_status_top span svg{
  font-size: 7px;
  background: #80a6ff;
  padding: 3px;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  color: #fff;
  margin-right: 8px;
}
.v_bg_success{
  background-color: #23b973 !important;
}
.v_work_status{
  -moz-column-count: 2;
  -moz-column-gap: 6px;
  -webkit-column-count: 2;
  -webkit-column-gap: 6px;
  column-count: 2;
  column-gap: 6px;
}
.v_work_status ul li{
  display: flex;
  gap: 6px;
  align-items: center;
  /* line-height: 40px; */
  color: #696969;
  font-size: 15px;
  margin-bottom: 20px;
}
.v_work_status ul li span{
  width: 17px;
  height: 17px;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
}

.v_list_type .work_btn button,
.v_list_type .work_btn svg{
  color: #929292;
  font-size: 16px;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
}
.v_list_type .work_btn button:hover,
.v_list_type .work_btn button:active,
.v_list_type .work_btn button:focus,
.v_list_type .work_btn button:hover svg,
.v_list_type .work_btn button:active svg,
.v_list_type .work_btn button:focus svg{
  background-color: #3874ff;
  color: #fff;
}
.v_list_type .v_grid_set{
  background-color: #f5f5f5;
  border-radius: 6px;
  padding: 10px;
}
.v_list_type .v_grid_set .v_list_type2{
  margin-bottom: 10px;
}
.v_list_type .v_grid_set .v_list_type2 img{
  width: 90px;
  height: 90px;
  object-fit: cover;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
}
.v_list_type .v_grid_set p{
  font-size: 14px;
    color: #0044e3;
}
.v_list_type .v_grid_set ul {
  margin-top: 10px;
}
.v_list_type .v_grid_set ul li,
.v_list_type .v_grid_set table tr td{
  font-size: 10px;
  /* background: #d1d1d1; */
  padding: 5px 9px;
  /* border-radius: 3px; */
}

.progress_bar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: radial-gradient(closest-side, white 79%, transparent 80% 100%), conic-gradient(#3874ff 1%, #c6c6c6 0); 
}