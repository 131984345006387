@import url('https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800');
/* @import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,500;600,700;0,800;1,500&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;500;600;700;800&display=swap');

* {
  box-sizing: border-box;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
span,
label,
div {
  /* font-family: "Nunito Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol" !important; */
  /* font-family: "Plus Jakarta Sans", Sans-serif !important; */
  font-family: 'Nunito Sans', sans-serif;
}



p {
  /* font-family: "Nunito Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol" !important; */

  /* font-family: "Inter", Sans-serif; */
  font-family: 'Nunito Sans', sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background: #f5f7fa !important;
}

.guest-home-page{
  /* max-height: 100dvh; */
  height: 100dvh;
  overflow: auto;
}

p {
  margin-bottom: 0rem;
}

h1,
h2,
h3,
h4,
h5,
h6,
label,
span {
  font-weight: 500;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg {
  background: #1C8EF9 !important;

}

.forgot-password,
.forgot-password a {
  font-size: 14px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
  font-weight: 600;
}

.forgot-password a {
  color: #167bff;
}

.card label {
  font-weight: 600;
  font-size: 14px;
}

.custom-control-label {
  font-weight: 400;
}

.form-control,
.form-select,
.css-13cymwt-control,
.css-13cymwt-control:hover,
.css-13cymwt-control:active {
  /* background-color: #EBEBEB !important; */
  background-color: #ffffff !important;
  border: none;
  border-radius: 5px;
  padding: 0.5rem;
  border: 1px solid #ccced1;
  box-shadow: none !important;
}

.form-control:disabled,
.form-control[readonly] {
  opacity: 1;
  background-color: #d1d4d44e !important;
}

.basic-multi-select {
  z-index: 99;
}



.barchart-container {
  width: 100% !important;
  height: 320px !important;
  display: flex !important;
  align-items: center !important;
}

.linechart-container {
  width: 100% !important;
  height: 320px !important;
  display: flex !important;
  align-items: center !important;
}

.chart-container {

  width: 140px !important;
  height: 100% !important;
  margin: auto !important;
}

.formik-error{
  color: red;
  font-size: 13px;
}

@media only screen and (min-width: 768px) and (max-width: 967px) {

  .routes {
    
    padding: 0px !important;
  }

}

@media only screen and (max-width: 1400px) {
  .link_text {
    white-space: nowrap;
    font-size: 0.7rem !important;
  }

  .route-icon svg {
    font-size: 10px;
  }

  .collapse-bar {
    font-size: 0.5rem !important;
    color: #000;
  }

  .bars {
    width: 15px !important;
  }

  .bottom_section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 5px !important;
    position: absolute;
    bottom: 1% !important;
    cursor: pointer;
  }

  .routes {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    height: 75dvh !important;
    overflow-y: auto;
  }

  .menu {
    display: flex;
    color: var(--dark);
    padding: 0px 10px 2px 10px !important;
    border-right: 4px solid transparent;
    transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
    /* justify-content: space-between; */
    align-items: center;
}
.document h3{
  font-size: 1.5rem !important;
}

.document p{
  font-size: 0.8rem !important ;
}
.card{
  padding: 30px 20px !important;
}
}


@media only screen and (min-width: 1400px) {}

@media (min-width: 1540px) {
  .col-xxxl-6 {
    flex: 0 0 auto !important;
    width: 50% !important;
  }

  .chart-container {
    width: 100px !important;
    height: 100% !important;
    margin: auto !important;
  }

  .linechart-container {
    width: 100% !important;
    height: 320px !important;
    display: flex !important;
    align-items: center !important;
  }

  .line2 .linechart-container {
    width: 100% !important;
    height: 200px !important;
    display: flex !important;
    align-items: center !important;
  }

  canvas {
    width: 100px !important;
    height: 100% !important;
    margin: auto !important;
  }

  .chart-title2 div {
    font-size: 0.8rem !important;
  }

  .badge {
    font-size: 0.55rem !important;
  }

  .dashboard-points {
    margin-bottom: 1rem;
  }

  .col-xxxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }



}

.places-image-slider .swiper-button-prev,
.places-image-slider .swiper-button-next {
  position: absolute;
  width: 38px !important;
  height: 38px !important;
  background-color: #ffffff;
  border-radius: 50%;
  box-shadow: 0 14px 80px rgba(34, 35, 58, .2) !important;
  padding: 1rem;
}


.places-image-slider .swiper-button-next:after,
.places-image-slider .swiper-button-prev:after {
  font-family: swiper-icons;
  font-size: var(--swiper-navigation-size);
  text-transform: none !important;
  letter-spacing: 0;
  font-variant: initial;
  line-height: 1;
  font-size: 1.1rem !important;
  font-weight: 900;
}


/* .main2::-webkit-scrollbar {
  width: 14px;
  height: 6px;
  background-color: #F5F5F5;
}

.main2::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #c9cdd3;
} */