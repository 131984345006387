
.footer_background{
    background-color: #edebe9;
    padding: 80px 0 50px 0;
}

.widget_title_footer {
    font-size: 15px;
    color: #484848;
    line-height: 1.6em;
    margin-bottom: 15px;
    font-weight: 700;
}

.select_currency{
    border-radius: 5px;
    height: 2.5rem;
    outline: 2px solid #dee2e6 !important;
    width: 50%;
    font-size: 0.8rem !important;
    border: none;
    /* padding-left: 0.5rem; */
    background-color: #fff;
}

.select_currency fieldset{
    border: none !important;
}

.footer_title{
    font-size: 16px;
    color: #484848;
    line-height: 1.6em;
    margin-bottom: 15px;
    /* text-transform: uppercase; */
    margin-top: 20px;
    font-weight: 700;
}

.textwidget {
    line-height: 1.6em;
}

.textwidget p {
    margin-bottom: 15px;
    color: #5d6475;
    font-weight: 600;
    font-size: 15px;
}

.property_listing {
    display: flex;
    align-items: center;
    max-height: 320px;
    margin-bottom: 20px;
}


.footer_img_wrapper img {
    height: 75px;
    width: 105px;
    border-radius: 4px;
    object-fit: cover;
}

.title_container {
    width: 100%;
    position: relative;
    z-index: 99;
    padding: 0 15px;
}

.category_name {
    display: inline-block;
    font-weight: 400;
    position: relative;
    color: #fff;
    z-index: 10;
    font-size: 18px;
    text-align: left;
   
    width: 100%;
}

.listing_title_unit {
    padding-right: 0;
    margin-top: 0;
    margin-bottom: 0;
    color: #484848;
    line-height: 1.2em;
    font-size: 15px;
    font-weight: 700;
}

.category_tagline {
    font-size: 12.5px;
    color: #5d6475;
}

.price_unit {
    padding: 0;
    font-size: 14px;
    bottom: 0;
    position: relative;
    width: 100%;
    background-color: transparent;
    margin-top: 5px;
    color: #484848;
    font-weight: 700;
}

.pernight {
    font-size: 12px;
    margin-left: -2px;
    font-weight: 600;
}

.contact_points{
    display: flex;
    color: #5d6475;
    margin-bottom: 15px;
    align-items: center;
}

.contact_points a:hover{
    color: #3874ff;
}

.contact_points a{
    display: flex;
    color: #5d6475;
    align-items: center;
}

.contact_points p{
    margin-left: 10px;
    font-weight: 500;

}

.social_links{
    width: 37px;
    height: 37px;
    background-color: #323840;
    color: #f0f0f0;
    margin: 0 10px 10px 0;
    text-align: center;
    display: inline-block;
    -webkit-transition: all .4s ease;
    -moz-transition: all .4s ease;
    -o-transition: all .4s ease;
    transition: all .4s ease;
    border-radius: 50%;
    line-height: 37px;
}

.social_links:hover{
    background-color: #3874ff;
    color: white;
}