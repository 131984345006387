.modal_header {
    position: relative;
    text-align: center;
    padding: 0;
    border-radius: 3px 3px 0 0;
    box-shadow: none;
    border: none;
}

.modal_title_big {
    color: #484848;
    margin-top: 0;
    margin-bottom: 30px;
    font-size: 24px!important;
    font-weight: 700;
    display: inline-block;
    width: 100%;

}


.login_background{
    width: 30%;
    position: absolute;
    padding: 45px 60px;
    height: auto;
    margin: auto;
    z-index: 999;
    background-color: #fff;
    border-radius: 10px;
        /* -webkit-transition: opacity .15s linear; */
        -o-transition: opacity .15s linear;
        transition: opacity .15s linear;
    
}

.popup_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent background */
    backdrop-filter: blur(4px);
    /* Apply blur effect to the background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  
  }

  .close {
    position: absolute;
    color: #fff;
    opacity: 1;
    top: 9px;
    right: 9px;
    text-shadow: none;
    font-weight: 400;
    cursor: pointer;
    width: 25px;
    height: 25px;
    line-height: 25px;
    background-color: #484848;
    border-radius: 50%;
    z-index: 99;
    border: none;
}

.booking_btn{
    width: 100%;
    height: auto;
    border-radius: 4px !important;
    background-color: #3874ff !important;
    text-transform: none !important;
    padding: 0.7rem 0rem !important;
    font-size: 15px !important;
    font-weight: 700 !important;
}

.terms_check{
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.terms_checkbox{
    width: 16px;
    height: 16px;
    margin-right: 10px !important;
}

.already_link{

    width: 100%;
    text-align: center;
    color: #484848;
    margin-top: 10px;
    font-size: 14px;
    text-align: center;
    display: block;
    font-weight: 700;
    background-color: #fff;
    border: none;

}

.already_link:hover{
    color: #3874ff;
}

.formInput input{
    padding: 14.5px 14px !important;
}

.formInput input:focus{
    border-color: #e3e7ef !important;
    color: #484848 !important;
    background-color: #e3e7ef !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    outline: none !important;
}

@media only screen and (max-width: 768px) {

    .login_background{
        width: 90%;
        position: absolute;
        padding: 45px 30px;
        height: auto;
        margin: auto;
        z-index: 999;
        background-color: #fff;
        border-radius: 10px;
            /* -webkit-transition: opacity .15s linear; */
            -o-transition: opacity .15s linear;
            transition: opacity .15s linear;
        
    }

}


@media only screen and (min-width: 768px) and (max-width: 967px) {

    .login_background{
        width: 70%;
        position: absolute;
        padding: 45px 30px;
        height: auto;
        margin: auto;
        z-index: 999;
        background-color: #fff;
        border-radius: 10px;
            /* -webkit-transition: opacity .15s linear; */
            -o-transition: opacity .15s linear;
            transition: opacity .15s linear;
        
    }

}


@media only screen and (min-width: 968px) {


}