.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.header-card {
  word-wrap: break-word;
  background: #fff;
  border-bottom: 1px solid #cbd0dd;
  display: flex;
  min-width: 0;
  padding: 5px 20px;
  position: relative;
  text-align: left;
  transition: all .3s;
}

.logo2 {
  width: 160px;
}

.profile-btn {
  box-shadow: none !important;
}

.user-img {
  border-radius: 50%;
  height: 40px;
  margin-right: 5px;
  width: 40px;
}

.search-input input[type=text],
.search-input2 input[type=text] {
  border: none !important;
  flex: 1 1;
  outline: none;
}

.search-input,
.search-input2 {
  align-items: center;
  display: flex;
  height: 35px !important;
  padding: 8px;
}

.search {
  align-items: center;
  display: flex;
  height: 30px;
  padding: 10px 15px;
}

.search-input {
  border: 1px solid #ccc;
  border-radius: 4px;
  border-radius: 2rem;
  width: 23rem;
}

.chart-input {
  background-color: #fff !important;
  border: 1px solid #cbd0dd !important;
  border-radius: 0.375rem !important;
  float: right;
  font-size: .8rem;
  height: 2rem;
  padding: 0.375rem 1rem;
  width: 60%;
}

.search input {
  background: #eee;
  border: none;
  border-radius: 5px;
  color: #000;
}

.grow {
  box-shadow: 0 0 1px transparent;
  display: inline-block;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  transition-duration: .3s;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  vertical-align: middle;
}

.grow:hover,
.grow:focus,
.grow:active {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.header-list {
  display: none;
}

.main-container {
  display: flex;
}




.chart-container {

  width: 130px !important;
  height: 100% !important;
  margin: auto !important;
}


@media only screen and (min-width: 1400px) {

  .arcChart-container canvas {
    width: 170px !important;
    height: 170px !important;
  }

}

@media only screen and (max-width: 1400px) {

  .booking-item p {
    font-size: 0.7rem !important;
  }

  .booking-item h6 {
    font-size: 0.6rem !important;
    font-weight: 700;
  }

  .arcChart-container canvas {
    width: 150px !important;
    height: 150px !important;
  }

  .chart-container {
    width: 120px !important;
    height: 100% !important;
    margin: auto !important;
  }

}


@media only screen and (min-width:1024px) and (max-width: 1300px) {

  .booking-item p {
    font-size: 0.5rem !important;
  }

  .booking .user-image {
    width: 25px !important;
    height: 25px !important;
  }

  .booking .name {
    font-size: 0.7rem !important;
  }

  .persons .message {
    font-size: 0.6rem !important;
    color: darkgray;
  }

  .line-elipsis {
    width: 120px !important;
  }

  .small-screen {
    flex-direction: column;
  }

  .month-select {
    padding: 0rem 0rem 0rem 0.5rem !important;

  }

  .label-select {
    font-size: 0.8rem;
    top: 30% !important;
  }

  .arcChart-container canvas {
    width: 130px !important;
    height: 130px !important;
  }

  .swipe-btn2 {
    position: absolute;
    right: 12% !important;
    /* top: 5%; */
  }



  .swipe-btn {
    position: absolute;
    right: 12% !important;
    top: 180% !important;
  }

  .chart-container {

    width: 85px !important;
    height: 100% !important;
    margin: auto !important;
  }

  .arc-point {
    font-size: 10px !important;
    font-weight: 600;
    text-align: center;
    margin-top: 15px !important;
  }

  .due h6 {
    font-size: 16px !important;
  }

}

@media only screen and (min-width: 768px) and (max-width: 967px) {

  .container_pad{
    margin: 0 2rem !important;
  }

  .report-card {
    position: absolute;
    /*top: 15% !important;
    *//*left: 35% !important;
    */z-index: 10 !important;
    border-radius: 0 !important;
    /* transform: none !important; */
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    -ms-border-radius: 0 !important;
    -o-border-radius: 0 !important;
}

  .property_heading h6 {
    font-size: 12px !important;
  }

  .sidebar-size {
    width: 0% !important;
  }

  .filter2 {
    text-align: right;
    /* border: 1px solid #cbd0dd; */
    border-radius: 26px;
    width: -moz-fit-content;
    /* width: 100%; */
    padding: 0.5rem 1rem;
    background: #ecf0f5;
    position: relative;
    margin: 0.8rem 0rem;
    width: 40% !important;
  }
  
  .booking-popup {
    left: 50% !important;
  }

  .search-input {
    border: 1px solid #ccc;
    border-radius: 4px;
    border-radius: 2rem;
    width: 15rem;
  }

  .group-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: -4px;
    /* display: flex; */
    /* flex-wrap: nowrap; */
    width: 100%;
  }

  .calendar-sidebar {
    width: 40% !important;
  }

  .routes {

    padding: 0px !important;
  }

  .maintenance-card-block svg {
    font-size: 50px !important;
    color: white;
    font-weight: 700;
    padding: 0rem 0.5rem !important;
  }

  .assigned {
    font-size: 0.7rem !important;
  }

  .booking .name {
    font-size: 0.7rem !important;
  }

  .booking .address {
    font-size: 0.6rem !important;
  }

  .small-screen {
    flex-direction: column;
  }

  .block-width {
    width: 33.33% !important;
  }

  .property-detail h6 {
    font-size: 0.8rem !important;
  }

  .property-detail p {
    font-size: 0.6rem !important;
  }

  .type span {
    font-size: 0.7rem !important;
    margin-left: 5px;
  }

  .filter-select2 {
    margin-bottom: 10px;
  }

  .textarea-input {
    font-size: 0.55rem !important;
  }

  .property-form-input {
    height: 1.3rem !important;
    font-size: 0.5rem !important;

  }

  .updated-form-input {
    height: 1.3rem !important;
    font-size: 0.5rem !important;

  }

  .input-heading {
    font-size: 0.7rem !important;
    font-weight: 600;
  }

  .swipe-btn {
    position: absolute;
    right: 15% !important;
    top: 190% !important;
  }

  .swipe-btn2 {
    position: absolute;
    right: 15% !important;
    /* top: 5% !important; */
  }

  .chart-container {

    width: 85px !important;
    height: 100% !important;
    margin: auto !important;
  }

  .arcChart-container {
    width: 130px !important;
    height: 80px !important;
    margin: auto !important;
  }

  .arcChart-container canvas {
    width: 130px !important;
    height: 130px !important;
  }

  .arc-point {
    font-size: 8px !important;
    font-weight: 600;
    text-align: center;
    margin-top: 10px !important;
  }

}

@media only screen and (max-width: 1024px) and (min-width: 968px) {

  .form-select.filter-select2:focus~label,
  .form-select.filter-select2:valid~label {

    font-size: 8px !important;
  }

  .label-select {
    font-size: 0.6rem !important;
  }

  .filter-select2 {
    margin-bottom: 10px;
    height: 2rem !important;
    padding: 0.2rem !important;
    background-position: right 0.1rem center !important;
    font-size: 0.7rem !important;
  }

  .filter-select3 {
    height: 2rem !important;
    padding: 0.2rem !important;
    background-position: right 0.1rem center !important;
    font-size: 0.7rem !important;
  }

  .month-text {
    font-size: 0.7rem;
    font-weight: 700 !important;
  }

  .textarea-input {
    font-size: 0.55rem !important;
  }

  .property-form-input {
    height: 1.3rem !important;
    font-size: 0.5rem !important;

  }

  .updated-form-input {
    height: 1.3rem !important;
    font-size: 0.5rem !important;

  }

  .arcChart-container {
    width: 100px !important;
    height: 80px !important;
    margin: auto !important;
  }

  .arcChart-container canvas {
    width: 100px !important;
    height: 100px !important;
  }

  .input-heading {
    font-size: 0.7rem !important;
    font-weight: 600;
  }



  .due h6 {
    font-size: 16px !important;
  }

  .select-input {
    background-color: #fff !important;
    border: 1px solid #cbd0dd !important;
    border-radius: 0.375rem !important;
    float: right;
    font-size: .6rem !important;
    height: 1.5rem !important;
    padding: 0.175rem 0.3rem !important;
    width: 100%;
  }

  .chart-title2 div {
    font-size: 0.8rem !important;
  }

  .chart-date {
    font-size: 0.7rem;
  }

  .chart-headings h3 {
    font-size: 1rem;
  }

  .chart-headings h6 {
    font-size: 0.8rem;
  }

  .chart-headings2 h6 {
    font-size: 0.5rem !important;
  }

  .chart-headings2 h5 {
    font-size: 0.9rem !important;
  }
}


@media only screen and (min-width: 768px) {



  .maintenance-pad {
    padding: 0rem 3rem 0rem 0rem;
  }

  .single-bar-chart {

    width: 100% !important;
    height: 450px !important;
    display: flex !important;
    align-items: center !important;
  }

  .mixed-chart {

    width: 100% !important;
    height: 450px !important;
    display: flex !important;
    align-items: center !important;
  }

  .single-line-chart {

    width: 100% !important;
    height: 400px !important;
    display: flex !important;
    align-items: center !important;
  }

  .sidebar-size2 {
    width: 16%;
  }

  .wdh1 {
    /* width: 84%; */
  }

  .wdh2 {
    width: 96%;
  }

  .franchise-pad2 {
    padding: 0rem 1.5rem 3rem 1.5rem !important;
  }
}

@media only screen and (max-width: 767px) {
  .form-border.v-form-border{
    border: 0;
    padding: 0 !important;
    border-radius: 0;
  }
  .report-card {
    position: absolute;
    /*top: 10% !important;
    *//*left: 12% !important;
    */z-index: 10 !important;
    border-radius: 0 !important;
    /* transform: none !important; */
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    -ms-border-radius: 0 !important;
    -o-border-radius: 0 !important;
}

  .filter2 {
    text-align: right;
    /* border: 1px solid #cbd0dd; */
    border-radius: 26px;
    width: -moz-fit-content;
    /* width: 100%; */
    padding: 0.5rem 1rem;
    background: #ecf0f5;
    position: relative;
    margin: 0.8rem 0rem;
    width: 70% !important;
  }

  .booking-popup {
    left: 4% !important;
    right: 4% !important;
  }

  .search-input {
    display: none;
  }

  .alignment {
    position: relative;
  }

  /* 
  .alignment .rdrCalendarWrapper {
    position: absolute;
    left: 16%;
} */

  .rdrMonths {
    display: block !important;

  }

  .rdrMonth {
    max-width: 300px;
    padding: 0 !important;
  }

  .franchise-pad2 {
    padding: 0rem 1.5rem 3rem 1.5rem !important;
  }

  .property-address {

    width: 150px !important;
    /* Set the desired width */
    white-space: pre-wrap !important;
    /* Prevent the text from wrapping */
    overflow: hidden;
    /* Hide any overflowed content */
    text-overflow: ellipsis;
    /* Display an ellipsis (...) for truncated text */

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

  }

  .discount-input-box span {
    font-size: 0.9rem;
  }


  .discount-block {
    padding: 0.5rem !important;
  }

  .discount-input {
    width: 40px !important;
    height: 25px !important;
    font-size: 0.9rem;
  }

  .discount-input-box {
    display: flex;
    align-items: center;
  }

  .price-btn {
    padding: 0.8rem !important;
    font-size: 1rem !important;
  }

  .price-input {
    border: 1px solid #dee2e6;
    padding: 0.5rem !important;
    border-radius: 5px;
    text-align: center;
    font-size: 0.8rem !important;
    font-weight: 800;
    background-color: white;
  }

  .divide {
    border-right: none !important;
    padding-right: 0 !important;
  }

  .dropdown-btn {
    /* padding: 0.3rem !important; */
    /* font-size: 0.8rem; */
  }

  .main2 {
    padding-bottom: 4rem;
  }

  .cancel-points {
    flex: none;
  }

  .chart-btn {
    padding: 0.3rem 1rem !important;
    font-size: 0.7rem !important;
  }

  .single-bar-chart {

    height: 320px !important;
  }

  .mixed-chart {

    height: 320px !important;
  }

  .single-line-chart {

    height: 300px !important;
  }

  .reservation-card1 {

    width: 90% !important;
  }

  .image-upload {
    position: absolute;
    top: 4% !important;
    right: 5% !important;
    left: 5% !important;
    overflow-y: scroll;
    max-height: 37rem;
  }

  .general-pad {
    padding: 1rem 0rem !important;
  }

  .block-width {
    width: 100% !important;
  }

  .amenities-list {
    padding: 0rem !important;
    margin-top: 1rem !important;
  }

  .image {
    width: 100% !important;
  }

  .preview {
    width: 100% !important;
  }

  .type {
    border-right: none !important;
    padding-left: 10px;
    min-height: auto !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px !important;

  }

  .type_unit {
    padding-left: 10px;
    /* height: 100%; */
    min-height: 50px;
    display: flex;
    margin-bottom: 10px !important;
    justify-content: space-between;
  }

  .calendar-sidebar {
    background: #ffffff;
    /* box-shadow: 0px 14px 10px rgba(34, 35, 58, 0.2); */
    box-shadow: -6px 10px 25px -3px rgba(34, 35, 58, 0.2);
    /* padding: 20px; */
    transition: all .3s;
    text-align: left;
    flex-direction: column;
    height: 100%;
    border-radius: 5px;
    padding: 0.5rem;
    width: 100% !important;
  }

  .timeline-container {
    width: 100% !important;
    margin-left: 0rem !important;
  }

  .unit {
    padding-left: 10px !important;
  }

  .rbc-toolbar .rbc-btn-group {
    margin-bottom: 1rem;
  }

  .profile-input-card {

    background: #ffffff;
    box-shadow: rgba(145, 158, 171, 0.3) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
    /* padding: 40px 55px 45px 55px; */
    padding: 16px 20px;
    border-radius: 5px;
    transition: all .3s;
    text-align: left;
    flex-direction: column;
    border: 1px solid #dee2e6;
    margin-top: 15px;
    height: 100%;
  }

  .transaction {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .transaction .select-width {
    width: 50% !important;
  }

  .sidebar-size {
    width: 0 !important;
  }

  .swipe-btn2 {
    position: absolute;
    right: 36% !important;
    /* top: 5% !important; */
  }

  .action-btn {
    margin-right: 0 !important;
  }

  .report-btn {
    margin-right: 0 !important;
  }

  .flex-sm-columns {
    flex-flow: column !important;
  }

  .card-margin {
    margin: 1rem 0.7rem 1rem 1rem !important;
  }

  .swipe-btn {
    position: absolute;
    /* right: 54%; */
    top: 50% !important;
    left: 64% !important;
    right: inherit !important;
  }

  .arcChart-container canvas {
    width: 180px !important;
    height: 180px !important;
  }

  .arc-point {
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    margin-top: 2px !important;
  }

  .booking-btn {
    padding: 0.5rem 0.5rem !important;
    margin-right: 10px;
    border: none;
    background: none;
    font-size: 0.75rem !important;
  }

  .profile-option {
    border: 1px solid #dee2e6;
    padding: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 0px 2px 0px rgba(34, 35, 58, .2);
  }

  .profile-option.active svg {
    background-color: #f2f2f2;
    color: #000 !important;
  }

  .only-large {
    display: none !important;
  }

  .only-desktop {
    display: none !important;
  }

  .only-mobile {
    display: flex !important;
  }

  .main-profile {
    height: 100%;
    display: block !important;
  }

  .profile-right {
    width: 100% !important;
    padding: 16px 20px;
    overflow-y: auto;
  }

  .profile-sidebar2 {
    /* background: #ffffff;
      box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2); */
    /* padding: 20px; */
    /* transition: all .3s;
      text-align: left;
      flex-direction: column; */
    width: 100% !important;

    /* height: 100%; */
    display: flex;
    justify-content: space-between;
    padding: 20px;
  }

  .bank-btn1 {
    border: none;
    background-color: #3874ff;
    color: white;
    padding: 0.5rem 1.5rem;
    border-radius: 3px;
    font-size: 0.7rem !important;
    width: 100%;
  }

  .bank-btn2 {
    border: none;
    background-color: #e6e6e6;
    color: black;
    padding: 0.5rem 1.5rem;
    border-radius: 3px;
    font-size: 0.7rem !important;
    margin-left: 0px !important;
    margin-top: 10px;
    width: 100%;
  }

  .wdh1,
  .wdh2 {
    width: 100%;
  }

  .sidebar {
    background-color: #fff;
    border-right: 1px solid #cbd0dd;
    color: #f4b905;
    color: var(--secondary);
    height: 100dvh;
    overflow-y: auto;
    transition: all .3s ease-in-out 0s;
    z-index: 2;
  }

  .table-heading {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 1.5rem 1.5rem 0rem 1.2rem !important;
  }

  .sidebar-size2 {
    position: absolute;
    width: 65%;
    z-index: 2;
  }

  .add-bank {
    display: block;
    height: 100%;
    left: 5% !important;
    position: absolute;
    right: 5% !important;
    top: 15%;
    width: auto;
    z-index: 999;
  }

  .radio-button {
    float: left;
    margin: 0 5px 0 0;
    width: 31% !important;
    height: 40px;
    position: relative;
  }

  .add-host {
    display: block;
    height: 100%;
    left: 3% !important;
    position: absolute;
    right: 3% !important;
    top: 2% !important;
    width: auto;
    z-index: 999;
  }

  .icon-card {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 14px 80px rgba(34, 35, 58, .2);
    font-size: 20px !important;
    margin-right: 20px;
    padding: 3px 8px !important;
    text-align: left;
    transition: all .3s;
    z-index: 1;
  }
}



@media only screen and (min-width: 767px) {

  .sidebar {
    background-color: #fff;
    border-right: 1px solid #cbd0dd;
    color: #3e465b;
    color: var(--dark);
    height: 100dvh;
    overflow-y: auto;
    transition: all .3s ease-in-out 0s;
    z-index: 1;
  }

  .table-heading {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 1.5rem 1.5rem 0;
  }

}

@media only screen and (min-width: 767px) {
  .sidebar-size2 {
    /* width: 16% !important; */
  }

  .sidebar-size {
    /* width: 4% !important; */
  }
}

.routes {
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 82dvh;
  overflow-y: hidden;
  padding-top: 25px !important;
  padding: 10px;
}

.routes:hover {
  overflow-y: auto !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.link.active {
  color: #3874ff;
  font-weight: 600;
}

.routes a {
  text-decoration: none;
}

.link,
.link:hover {
  align-items: center;
  transition: .2s cubic-bezier(.6, -.28, .735, .045);
}

.link {
  border-right: 4px solid transparent;
  color: #3e465b;
  color: var(--dark);
  display: flex;
  gap: 10px;
  padding: 5px 10px;
}

.link_text {
  margin-top: 0.25rem;
  white-space: nowrap;
}

.link_text {
  font-size: 0.9rem;
}

.menu {
  align-items: center;
  border-right: 4px solid transparent;
  color: #3e465b;
  color: var(--dark);
  display: flex;
  padding: 5px 10px;
  transition: .2s cubic-bezier(.6, -.28, .735, .045);
}

.routes a {
  text-decoration: none;
}

.main-link {
  color: #3e465b;
  color: var(--dark);
  /* width: 92%; */
}

.menu_item {
  align-items: center;
  display: flex;
  gap: 10px;
  justify-content: space-between;

}

.dropdown-btn-width {
  width: 8%;
}

.drop-btn {
  background: none;
  border: none;
  color: #525b75;
  padding: 0;
}

.bottom_section {
  align-items: center;
  bottom: 1%;
  cursor: pointer;
  display: flex;
  margin-top: 0.5rem;
  padding: 15px 22px;
}

.bars {
  width: 30px;
}

.collapse-bar,
.collapse-bar:hover {
  color: #000;
  font-size: .8rem;
}


.main2 {
  height: 91.5dvh;
  overflow: auto;
}

.sublink {
  padding-left: 3rem !important;
}

.routes::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #F5F5F5;
}

.routes::-webkit-scrollbar-thumb {
  border-radius: 10px;
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3); */
  background-color: #c9cdd3;
}
.smallscroll::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #F5F5F5;
}

.smallscroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3); */
  background-color: #c9cdd3;
}

.profile-img2 {
  border-radius: 50%;
  height: 50px;
  margin-right: 5px;
  width: 50px;
}

.profile-popup {
  background-position: 100%;
  background: #fff;
  border-radius: 0.375rem;
  padding: 1rem;
  position: fixed;
  right: 2%;
  top: 10%;
  width: 18rem;
  z-index: 99;
}

.profile-pic {
  text-align: center;
  width: 100%;
}

.profile-pic h6 {
  color: #000;
  font-size: .8rem !important;
  font-weight: 600;
  margin-top: 0.5rem !important;
  text-transform: capitalize;
}

.profile-popup:after {
  background: inherit;
  border-color: #dee2e6 !important;
  border-style: solid;
  border-top-left-radius: 0.25rem;
  border-width: 1px 0 0 1px;
  content: "";
  height: 1.5rem;
  left: auto !important;
  left: 1.5rem;
  position: absolute;
  right: 0.9rem;
  top: -13px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 1.5rem;
  z-index: -1;
}

.profile-url {
  align-items: center;
  color: #222834 !important;
  display: flex;
  font-size: .9rem;
  padding: 0.5rem;
}

.detail-btn2 {
  background: none;
  background-color: #eff2f6;
  border: 1px solid #d0d4da;
  border-radius: 0.375rem;
  color: #000;
  cursor: pointer;
  display: block;
  font-size: .8rem;
  font-weight: 600;
  height: 2rem;
  line-height: 1.2;
  padding: 0.525rem 0.8rem;
  text-align: center;
  white-space: nowrap;
}

.profile-url:hover {
  background-color: #eff2f6;
  border-radius: 10px;
  padding: 0.5rem;
}

a {
  text-decoration: none;
}

.profile-url span {
  color: #222834 !important;
  font-weight: 500 !important;
  margin-left: 0.5rem;
  margin-top: 0.1rem;
}

.user-img3 {
  border-radius: 50%;
  height: 35px;
  width: 35px;
}

.icon-card {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 14px 80px rgba(34, 35, 58, .2);
  font-size: 25px;
  margin-right: 20px;
  padding: 5px 15px;
  text-align: left;
  transition: all .3s;
  z-index: 1;
}

.add-heading3 {
  align-items: center;
  display: flex;
  margin-bottom: 10px;
}

.bg-color1 {
  background-color: #f5f7fa;
}

.main-profile {
  height: 100%;
  display: flex;
}

.profile-sidebar1 {
  background: #ffffff;
  box-shadow: 0px 14px 10px rgba(34, 35, 58, 0.2);
  /* padding: 20px; */
  transition: all .3s;
  text-align: left;
  flex-direction: column;
  width: 20%;
  height: 100%;
}

.profile-heading h5 {
  font-weight: 600;
  margin-bottom: 0 !important;
  padding: 16px 10px;
}

.profile-option {
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  padding: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.profile-option:hover {
  background-color: #f2f2f2;
}

.profile-option p {
  font-size: 0.8rem;
  color: #979797;
}

.profile-option h6 {
  color: #000;
  font-weight: 600;
  font-size: 0.96rem;
}

.profile-option.active {
  background-color: #f2f2f2;
}

.option-icon {
  /* font-size: 1.8rem; */
  width: 1.8rem;
  color: darkgray;
}

.profile-right {
  width: 80%;
  padding: 16px 20px;
  overflow-y: auto;
}

.profile-right h5 {
  font-weight: 600;
}

.profile-input-card {

  background: #ffffff;
  box-shadow: rgba(145, 158, 171, 0.3) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
  /* padding: 40px 55px 45px 55px; */
  padding: 16px 20px;
  border-radius: 5px;
  transition: all .3s;
  text-align: left;
  flex-direction: column;
  border: 1px solid #dee2e6;
  margin-top: 15px;
  height: 100%;
}

.profile-input-card p {
  font-size: 0.8rem;
  color: darkgray;
  font-weight: 500;
}

.profile-input-card h6 {
  font-size: 0.8rem;
  font-weight: 600;
  margin-top: 0.5rem;
}

.password-input {
  width: 100%;
  border: 2px solid #dee2e6;
  border-radius: 5px;
  height: 2rem;
}

.asterik {
  color: red;
  font-size: 1.3rem;
}

.asterik2 {
  color: red;
  font-size: 1rem;
}

.save-btn {
  border: none;
  background-color: #3874ff;
  color: white;
  padding: 0.5rem 1.5rem;
  border-radius: 3px;
  font-size: 0.9rem;
  margin-top: 2rem;
}

.bank-btn1 {
  border: none;
  background-color: #3874ff;
  color: white;
  padding: 0.5rem 1.5rem;
  border-radius: 3px;
  font-size: 0.9rem;
}

.bank-btn2 {
  border: none;
  background-color: #e6e6e6;
  color: black;
  padding: 0.5rem 1.5rem;
  border-radius: 3px;
  font-size: 0.9rem;
  margin-left: 15px;
}

.primary-btn {
  border: none;
  background-color: #3874ff;
  color: white;
  padding: 0.5rem 1.5rem;
  border-radius: 3px;
  font-size: 0.9rem;
}

.gray-btn {
  border: none;
  background-color: #e6e6e6;
  color: black;
  padding: 0.5rem 1.5rem;
  border-radius: 3px;
  font-size: 0.9rem;
}

.edit-input {
  width: 100%;
  border: none;
  height: 2rem;
  font-size: 0.8rem;
  /* font-weight: 600; */
}

.edit-input:focus-visible {
  border-radius: 5px;
  height: 2rem;
  outline: 2px solid #dee2e6 !important;

}

.edit-input2 {
  width: 100%;
  border: none;
  height: 2rem;
  font-size: 0.8rem;
  /* font-weight: 600; */
  color: darkgray;
}

.edit-input2:focus-visible {
  height: 2rem;
  outline: none !important;

}

.btn-margin {
  margin-left: 2px;
}

.add-bank {
  display: block;
  height: 100%;
  left: 35%;
  position: absolute;
  right: 35%;
  top: 10%;
  width: auto;
  z-index: 999;
}

.popup-heading {
  font-weight: 600;
  font-size: 1rem;
}

.ml-3 {
  margin-left: 20px;
}

.float-right {
  float: right;
}

.popup-card {

  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  /* padding: 40px 55px 45px 55px; */
  padding: 20px 20px;
  border-radius: 5px;
  transition: all .3s;
  text-align: left;
  flex-direction: column;
  border: 1px solid #dee2e6;
  margin-top: 15px;
  height: fit-content;
  max-height: 75dvh;
  overflow-y: auto;
}

.popup-card2 {

  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  /* padding: 40px 55px 45px 55px; */
  /* padding: 20px 20px; */
  border-radius: 5px;
  transition: all .3s;
  text-align: left;
  flex-direction: column;
  border: 1px solid #dee2e6;
  margin-top: 15px;
  height: fit-content;
  max-height: 85dvh;
  overflow-y: auto;
}

/* .popup-card2:hover {
  overflow-y: auto !important;
} */


.radio-button {
  float: left;
  margin: 0 5px 0 0;
  width: 32%;
  height: 40px;
  position: relative;
}

.radio-button label,
.radio-button input {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 2px solid #dee2e6 !important;
}

.radio-button2 {
  float: left;
  margin: 0 5px 0 0;
  width: 32%;
  height: 40px;
  position: relative;
}

.radio-button2 label,
.radio-button2 input {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 2px solid #dee2e6 !important;
}

.radio-button2 input[id="choice2"] {
  opacity: 0.011;
  z-index: 100;
  border: 2px solid #dee2e6;
}

.radio-button2 input[id="choice2"]:checked+label {
  background: #3874ff;
  border-radius: 4px;
  color: white;
  padding-bottom: 2.2rem;
}

.radio-button input[id="choice"] {
  opacity: 0.011;
  z-index: 100;
  border: 2px solid #dee2e6;
}

.radio-button input[id="choice"]:checked+label {
  background: #3874ff;
  border-radius: 4px;
  color: white;
  padding-bottom: 2.2rem;
}

.radio-button label {
  cursor: pointer;
  z-index: 90;
  line-height: 1.8em;
  font-size: 0.9rem;
}

.radio-button2 label {
  cursor: pointer;
  z-index: 90;
  line-height: 1.8em;
  font-size: 0.9rem;
}

.input-heading {
  font-size: 0.8rem;
  font-weight: 600;
}

.only-large {
  display: block;
}


.only-mobile {
  display: none;
}

.profile-icon {
  border: 1px solid #dee2e6;
  padding: 0.5rem;

}

.bg-card {
  background-position: 100%;
  background-size: contain;
  background: #fff;
  border-radius: 0.475rem;
}



.arcChart-container {
  width: 150px;
  height: 80px !important;
  margin: auto !important;
}

.arc-pad {
  padding: 1rem 1rem 1rem 1rem;
}



.bullet-item {
  border-radius: 2px;
  display: block;
  height: 0.5rem;
  width: 1rem;
}

.arc-progress {
  /* Remove radius */
  border-radius: 0;
}

.arc-point {
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  margin-top: 30px;
}

.due {
  text-align: center;
}

.due h6 {
  font-size: 22px;
}

.due p {
  font-size: 12px;
  font-weight: 600;
}

.color1 {
  color: #24b8fd;
}

.red {
  color: red;
}

.select-input {
  background-color: #fff !important;
  border: 1px solid #cbd0dd !important;
  border-radius: 0.375rem !important;
  float: right;
  font-size: .8rem;
  height: 1.8rem;
  padding: 0.375rem 1rem;
  width: 100%;
}

.product-slider {
  position: relative;
}

.product-slider .swiper-button-prev,
.product-slider .swiper-button-next {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px !important;
  height: 30px !important;
  background-color: white;
  border: 1px solid #dee2e6 !important;
  border-radius: 50%;
  box-shadow: 0 14px 80px rgba(34, 35, 58, .2) !important;
  /* Add other styles as needed */
  padding: 1.3rem;
  display: flex;
}

/* .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    left: 90% !important;
    right: 0 !important;
  } */

.product-slider .swipe-btn {
  position: absolute;
  right: 9%;
  top: 180%;
}

.product-slider .swipe-btn2 {
  position: absolute;
  right: 9%;
  /* top: 5%; */
}

.product-slider .swiper-button-next {
  right: -108px !important;
}

.property_name {
  font-weight: 600;
  margin-top: 10px;
  /* height: 50px; */
}

.property-img {
  border-radius: 50%;
  height: 40px;
  margin-right: 5px;
  width: 40px;
  object-fit: cover;
}

.guest-property-img {
  border-radius: 50%;
    height: 75px;
    margin-right: 5px;
    width: 75px;
    object-fit: cover;
}

.date {
  font-size: 0.9rem;
}

.room {
  text-transform: capitalize;
  font-size: 13px;
  color: #827e7e;
  font-weight: 600;
}

.product-slider .swiper-button-next:after,
.product-slider .swiper-button-prev:after {
  font-family: swiper-icons;
  font-size: var(--swiper-navigation-size);
  text-transform: none !important;
  letter-spacing: 0;
  font-variant: initial;
  line-height: 1;
  font-size: 1.1rem !important;
  font-weight: 900;
}


.card-margin {
  margin-top: 2rem;
}

.booking-tab-btn {
  background-color: white;
  border: 1px solid #dee2e6 !important;
  padding: 0.5rem 1rem;
  border-radius: 30px;
  margin-right: 10px;
  font-size: 0.9rem;
}

.group-btn {
  margin-top: 1rem;
}

.booking-tab-btn.active {
  background-color: #3874ff !important;
  color: white;
}

.booking-link {
  color: #000;
}

.booking-link:hover {
  color: #3874ff;
}

.booking-btn {
  padding: 0.5rem 1rem;
  margin-right: 10px;
  border: none;
  background: none;
}


.booking-btn.active {
  color: #3874ff;
  border-bottom: 2px solid #3874ff;
}

.print-btn {
  background: none;
  border: 2px solid darkgray;
  border-radius: 6px;
  padding: 0.2rem 1rem;
}

.export-btn {
  background: none;
  border: 2px solid darkgray;
  border-radius: 6px;
  padding: 0.2rem 1rem;
  margin-right: 10px;
}

.adult {
  font-size: 0.75rem;
}

.guest {
  color: #000;
  font-weight: 600;
  /* text-decoration: underline; */
}

.financial-icon {
  font-size: 2rem;
  color: skyblue;
}

.report-btn {
  background: white;
  border: 1px solid #cbd0dd;
  border-radius: 6px;
  background: white;
  border-radius: 6px;
  padding: 0rem 1rem;
  height: 1.8rem;
  font-size: 0.75rem;
  margin-right: 10px;
  /* width: 100%; */
  white-space: nowrap;
}

.action-btn {
  background: white;
  border: 1px solid #cbd0dd;
  border-radius: 6px;
  background: white;
  border-radius: 6px;
  padding: 0rem 1rem;
  height: 1.8rem;
  font-size: 0.75rem;
  /* width: 100%; */
  white-space: nowrap;
}

.mr-2 {
  margin-right: 10px;
}

.report-card {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 99;
  border-radius: 0 !important;
  transform: translate(-50%, -50%);
  width: 100%;
}

.report-heading {
  border-bottom: 1px solid #ededed;
  padding: 0.8rem;
  font-size: 14px;
}

.report-option {
  border-bottom: 1px solid #ededed;
  padding: 0.8rem;
  font-size: 12px;
  display: flex;
  align-items: center;
}

.report-option input[type=checkbox] {
  margin-right: 10px;
}

.cancel-btn {
  border: none;
  background-color: #363636;
  color: white;
  padding: 0.5rem;
}

.done-btn {
  border: none;
  background-color: #3874ff;
  color: white;
  padding: 0.5rem;
}

.cancel-btn2 {
  border: none;
  background-color: #363636;
  color: white;
  margin-right: 10px;
  padding: 0.5rem 1.5rem;
  border-radius: 7px;
}

.apply-btn {
  border: none;
  background-color: #3874ff;
  color: white;
  padding: 0.5rem 1.5rem;
  border-radius: 7px;
}


.popup-container {
  position: relative;
  z-index: 1;
}


.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  backdrop-filter: blur(4px);
  /* Apply blur effect to the background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;

}

.calendar-card {
  position: absolute;
  left: -136px;
  top: 39px;
  background: white;
  padding: 1rem;
  /* border: 1px solid darkgray; */
  box-shadow: 0 14px 80px rgba(34, 35, 58, .2);
  border-radius: 10px;
  z-index: 2;
}

.rdrDefinedRangesWrapper {
  display: none;
}

.chart-date {
  line-height: 1;
}

.chart-headings2 h6 {
  font-size: 0.8rem;
}

.review-message {
  font-size: 14px;
  color: #868686;
}

.green-circle {
  width: 30px;
  height: 30px;
  background-color: #01bb01;
  border-radius: 50%;
  border: 1px solid lightgray;
  box-shadow: 0 4px 8px rgba(76, 175, 80, 0.3);
  transition: transform 0.2s ease-in-out;
}

.yellow-circle {
  width: 30px;
  height: 30px;
  background-color: #ffc400;
  border-radius: 50%;
  border: 1px solid lightgray;
  box-shadow: 0 4px 8px rgba(255, 193, 7, 0.3);
  transition: transform 0.2s ease-in-out;
}

.onboarding-link {
  color: #3874ff;
  /* text-decoration: underline; */
  text-transform: capitalize;
  font-weight: 600;
}

.onboarding-link:hover {
  color: #3874ff;
  text-decoration: underline;
  cursor: pointer;
}

.add-btn3 {
  border: none;
  background-color: #3874ff;
  color: white;
  padding: 0.5rem 1.5rem;
  border-radius: 7px;
}

.add-host {
  display: block;
  height: 100%;
  left: 35%;
  position: absolute;
  right: 35%;
  top: 8%;
  width: auto;
  z-index: 999;
}

.add-head .popup-heading {
  font-size: 1rem;
}

.add-head p {
  font-size: 0.85rem;
}

.add-head {
  background-color: #e5f0ff;
  box-sizing: border-box;
  padding: 15px 15px;
}

/* .form_input p{
  font-size: 0.9rem;
  font-weight: 600;
} */

.form_input input {
  font-weight: 500 !important;
}

.popup-card2::-webkit-scrollbar {
  width: 10px;
  height: 6px;
  background-color: #F5F5F5;
}

.popup-card2::-webkit-scrollbar-thumb {
  border-radius: 10px;
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3); */
  background-color: #c9cdd3;
}


.popup-card::-webkit-scrollbar {
  width: 10px;
  height: 6px;
  background-color: #F5F5F5;
}

.popup-card::-webkit-scrollbar-thumb {
  border-radius: 10px;
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3); */
  background-color: #c9cdd3;
}

.hide-footer .MuiDataGrid-footerContainer {
  display: none;
}

.rating {
  display: flex;
  align-items: center;
}

.stars-container {
  color: #fdd835;
  margin-right: 5px;
}

.star {
  display: inline-block;
  width: 20px;
  height: 20px;
  /* background-color: #fdd835; */
}

.rating-value {
  font-size: 18px;
  font-weight: bold;
}

.form-pad {
  padding: 15px;
}

.margin-t-2 {
  margin-top: 2rem !important;
}

.transaction .select-width {
  width: 100%;
}

.table-card {
  background-color: rgb(255, 255, 255);
  color: rgb(42, 53, 71);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 7px;
  box-shadow: rgba(145, 158, 171, 0.3) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
  overflow: hidden;
  width: 100%;
  background-image: none;
  padding: 0px;
  border: none;
  position: relative;
  padding: 16px;
}

.filter {
  text-align: right;
  /* border: 1px solid #cbd0dd; */
  border-radius: 26px;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0.8rem 1rem;
  /* background: #e6effa; */
  background: #ecf0f5;
  height: 3rem;
}

.filter:focus-visible {
  border: 1px solid blue;
}
.navcont .MuiInputBase-root {
  border-radius: 40px;
  background: #ecf0f5;
  border: 0 !important;
}
.navcont .MuiInputBase-root .MuiSelect-select{
  padding: 12.5px;
}
.navcont .MuiFormControl-root .MuiFormLabel-root{
  line-height: 1em;
  font-size: 14px;
}
.navcont .MuiFormControl-root .MuiOutlinedInput-notchedOutline{
  border-color: rgb(0 0 0 / 0%) !important;
}
.filter input {
  border: none;
  margin-left: 10px;
  background: none;
}

.filter input::placeholder {
  /* color: rgb(224, 220, 220); */
  color: darkgray;
}

.filter input:focus-visible {
  outline: none !important;
}

.search-icon {

  max-height: 2em;
  -webkit-box-align: center;
  align-items: center;
  white-space: nowrap;
  color: darkgray;
  margin-right: 8px;
}

.property-add-btn {
  border: none;
  background-color: #3874ff;
  color: white;
  padding: 0.5rem 1.5rem;
  border-radius: 7px;
  font-size: 13px;
  display: flex;
  align-items: center;
}

.property-card {
  background: #ffffff;
  /* box-shadow: -6px 5px 10px 9px rgb(146 148 161 / 20%); */
  /* box-shadow: 0 0 20px #ddd !important; */
  box-shadow: 0 0 20px #f6f3f3 !important;
  padding: 10px 10px;
  border-radius: 8px;
  transition: all .3s;
  text-align: left;
  justify-content: space-between;
  margin-top: 15px;
  height: auto;
  cursor: pointer;
  border: 1px solid #dee2e6;
}

.property-image {
  width: 75px;
  height: 55px;
  border-radius: 5px;
  margin-right: 10px;
  object-fit: fill;
}

.property-detail {
  display: flex;
  align-items: center;
}

.property-detail h6 {
  font-weight: 700;
  font-size: 0.9rem;
}

.property-detail p {
  color: darkgray;
  font-size: 0.85rem;
}

.cardLabel label{
  font-weight: normal !important;
}

.prop-head {
  font-weight: 700;
  font-size: 0.9rem;
}

.type {
  border-right: 1px solid #dee2e6;
  padding-left: 10px;
  /* height: 100%; */
  min-height: 50px;
  display: flex;
  align-items: center;
}



.type span {
  font-size: 0.8rem;
  margin-left: 5px;
}

.unit {
  padding-left: 10px;
}

.unit span {
  margin-left: 5px;
  font-size: 0.8rem;
}


.filter-btn {

  padding: 0.7rem 0.7rem;
  /* background: #e6effa; */
  background: #ecf0f5;
  border: none;
  border-radius: 10px;
  margin-left: 10px;

}

.filter-btn svg {
  font-size: 24px;
  color: darkgray;
}

.filter-select {
  padding: 0.8rem 0.8rem;
  /* background: #e6effa; */
  background: #ecf0f5 !important;
  border: none;
  border-radius: 10px;
  width: 100%;
  height: 3rem;
  cursor: pointer;
}

.filter-dropdown {
  position: absolute;
  top: 25%;
  right: 6px;
  font-size: 24px;
}


.filter-select option {
  padding: 1rem !important;
  background-color: #ecf0f5;
  border-bottom: 1px solid #000;
  /* Additional styling for the option element */
}

.filter-select option:hover {
  background-color: #000;
  /* Additional styling for the option element on hover */
}

.label-select {
  position: absolute;
  top: 25%;
  left: 5%;
}

.form-select.filter-select:focus~label,
.form-select.filter-select:valid~label {
  display: block;
  position: absolute;
  top: -8px !important;
  left: 16px !important;
  font-size: 13px !important;
  font-weight: 600 !important;
}

.close-btn2 {
  position: absolute;
  top: 24%;
  right: 16%;
  border: none;
  background: none;
  font-size: 16px;
  background: #dadee3;
  border-radius: 50%;
  color: #6b6969;
  height: 1.5rem;
  /* padding: 2px 10px 0; */
  width: 1.5rem;
  font-weight: 700;
}

.close-btn {
  position: absolute;
  top: 15%;
  right: 10%;
  border: none;
  background: none;
  font-size: 20px;
  background: #dadee3;
  border-radius: 50%;
  color: #6b6969;
  height: 2rem;
  /* padding: 2px 10px 0; */
  width: 2rem;
}

.block-heading {
  display: flex;
  width: 100%;
}

.block-heading h6 {
  width: 100%;
  text-align: center;
  font-weight: 600;
}

.blocks h6 {
  font-size: 0.9rem;
  font-weight: 600;
}

.block-heading svg {
  cursor: pointer;
}

.blocks p {
  font-size: 0.74rem;
  line-height: 13px;
  margin-top: 3px;

}

.block-icon2 {
  margin-right: 10px;
}

.block-icon2 svg {
  font-size: 24px;
}



.property-block-card {
  background: #ffffff;
  /* box-shadow: -6px 5px 10px 9px rgb(146 148 161 / 20%); */
  box-shadow: 1px 1px 4px 5px rgb(146 148 161 / 20%);
  /* padding: 40px 55px 45px 55px; */
  padding: 15px 13px;
  border-radius: 8px;
  transition: all .3s;
  text-align: left;
  /* flex-direction: column; */
  /* border: 1px solid #dee2e6; */
  /* margin-top: 15px; */
  height: auto;
  cursor: pointer;
  height: 100%;
  width: 100%;
}

.property-block-card2 {
  text-align: center;
  background: #ffffff;
  /* box-shadow: 2px 2px 10px 3px rgb(146 148 161 / 20%); */
  box-shadow: 0 0 5px #ddd;
  padding: 10px 6px;
  border-radius: 8px;
  transition: all .3s;
  height: auto;
  height: 100%;
  /* border:  1px solid rgba(0, 0, 0, .125); */
}

.summary {
  font-size: 0.75rem;
  margin-bottom: 5px;
}

.upload-img2 div {
  position: absolute;
  border-radius: 5px;
  width: 67rem !important;
  height: 15rem !important;
  border: 2px dashed #ccc;
}

.upload-img2 img {
  width: 20%;
}

.upload-img2 {
  width: 100%;
  margin: auto;
  text-align: center;
}

.radio-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  border: 1px solid #ccc;
  border-radius: 50%;
  text-align: center;
  line-height: 18px;
  margin-right: 5px;
  left: -6px;
  position: absolute;
  background: white;
  color: darkgray;
  font-size: 0.8rem;
  top: 0;
}

.checked {
  background-color: green !important;
  color: white;
}

.radio-label {
  position: relative;
  margin-left: 20px;
}

.amenities-pad {
  padding: 0.8rem 0rem;
}

.amenities-list {
  /* overflow-y: scroll;
  max-height: 27rem; */
  padding: 1rem;
  overflow-x: hidden;
  margin-top: 0rem;
}



/* .amenities-list::-webkit-scrollbar {
  width: 8px;
  height: 6px;
  background-color: #F5F5F5;
}

.amenities-list::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #c9cdd3;
} */

.amenities-category {
  font-weight: 600;
}

.upload-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 120px;
  border: 2px dashed #ccc;
  cursor: pointer;
  position: relative;
}

.upload-icon {
  font-size: 48px;
  color: #ccc;
  line-height: 0;
  margin-bottom: 1rem;
}

.upload-text {
  margin-top: 10px;
  color: #999;
}

.hidden-input {
  display: none;
}

.textarea-input {
  border: 1px solid #dee2e6 !important;
  outline: 1px solid #dee2e6 !important;
  border-radius: 5px;
  font-size: 0.85rem !important;
}

.mobile-head {
  font-size: 0.8rem;
  font-weight: 600;
  margin-right: 10px;

}

.rct-item {
  border-radius: 20px;
  font-weight: 600;
  font-size: 0.8rem !important;
  border: none !important;
  box-shadow: 0 4px 8px rgba(56, 116, 255, 0.3);
  /* background: #3874ff !important; */
}


.calendar-sidebar {
  background: #ffffff;
  /* box-shadow: 0px 14px 10px rgba(34, 35, 58, 0.2); */
  box-shadow: -6px 10px 25px -3px rgba(34, 35, 58, 0.2);
  /* padding: 20px; */
  transition: all .3s;
  text-align: left;
  flex-direction: column;
  height: 100%;
  border-radius: 5px;
  padding: 0.5rem;
  width: 20%;
}

.resource-container {
  height: 50px;
  /* Adjust the height value as per your requirement */
}

/* .rct--hl-even {
  height: 45px !important;
}

.rct--hl-odd{
  height:  45px !important;
} */

/* .rct--header-root div:first-child{
  display: none;
} */

/* .rct--outer{
height: 260px;
} */

/* .rct--sidebar {
  display: none !important;
} */


.react-calendar-timeline .rct-header-root {
  background: white !important;
}

.rct-dateHeader span {
  color: black !important;
  /* font-weight: 600; */
  position: sticky;
  position: -webkit-sticky;
  left: 0px;
  overflow: hidden;
  display: inline-block;
  border-radius: 2px;
  padding: 0 6px;
  height: 100%;
  display: flex;
  align-items: center;
}

.rct-dateHeader.rct-dateHeader-primary {
  justify-content: left !important;
}

.rct--scroll {
  width: 100% !important;
}

.rct-scroll div {
  z-index: 0;
}

.rct--calendar-header {
  width: 100% !important;
}

.group-img {
  width: 40px;
  height: 30px;
  border-radius: 5px;
  margin-right: 10px;

}

.group-title {
  font-size: 0.8rem;
}

.group-unit {
  color: darkgray;
  font-size: 0.8rem;
  margin-top: -5px;
}

.search_filter {
  padding: 0rem 0rem 0 1.5rem;
}


.filter2 {
  text-align: right;
  /* border: 1px solid #cbd0dd; */
  border-radius: 26px;
  width: -moz-fit-content;
  /* width: 100%; */
  padding: 0.5rem 1rem;
  background: #ecf0f5;
  position: relative;
  margin: 0.8rem 0rem;
  width: 20%;
}

.filter2:focus-visible {
  border: 1px solid blue;
}

.filter2 input {
  border: none;
  margin-left: 10px;
  background: none;
  position: absolute;
  left: 0;
}

.filter2 input::placeholder {
  /* color: rgb(224, 220, 220); */
  color: darkgray;
}

.filter2 input:focus-visible {
  outline: none !important;
}

.prop-pad {
  padding: 0.3rem;
  cursor: pointer;
}

.timeline-container .rct--event {
  height: 40px;
  /* Adjust the height value as per your requirement */
}

.timeline-container {
  width: 100%;
  padding: 0.5rem;
  background: #ffffff;
  /* box-shadow: 0px 14px 10px rgba(34, 35, 58, 0.2); */
  box-shadow: -6px 10px 25px -3px rgba(34, 35, 58, 0.2);
  /* padding: 20px; */
  transition: all .3s;
  text-align: left;
  flex-direction: column;
  /* width: 100%; */
  /* height: 100%; */
  border-radius: 5px;
  /* padding-bottom: 1rem; */
  margin-left: 1rem;
}

.timeline-container .rct--header-row .rct--header-group {
  height: 60px !important;
  /* Adjust the height value as per your requirement */
}



.rct-dateHeader {
  background: white !important;
  /* border-bottom: none !important; */
  border-top: 1px solid #bbb !important;
}

.react-calendar-timeline .rct--calendar-header {
  border: none !important;
}

.react-calendar-timeline {
  border-top: 1px solid #bbb !important;
  border-bottom: 1px solid #bbb !important;
  border-left: 2px solid #bbb !important;
  border-right: 2px solid #bbb !important;

  /* border-radius: 15px; */
  overflow: hidden;

  height: 100% !important;
}

.rct--hl-odd {
  background: white !important;
}

.rct--item-content {
  padding: 0 15px !important;
}

/* .rct--calendar-header div:nth-child(2) {
  display: none !important;
} */

.listing {
  font-size: 1.3rem;
  padding: 0rem 0.5rem;
}


.timeline .rct-today .rct-date-header {
  background-color: #FF0000 !important;
  /* Replace with your desired background color */
}

.rbc-event {

  border-radius: 20px !important;
  padding: 2px 10px !important;
  box-shadow: 0 4px 8px rgba(56, 116, 255, 0.3);
  background: #2196f3 !important;
  /* background: #e9e4e4 !important;
  border: 1px solid #cccccc;
  color: black; */

}



/* .calendar-card2{

  width: 100%;
  padding: 0.5rem;
  background: #ffffff;
  box-shadow: -6px 10px 25px -3px rgba(34, 35, 58, 0.2);
  transition: all .3s;
  text-align: left;
  border-radius: 5px;

} */

.timeline-container {
  overflow-x: auto !important;
}

.timeline-container .rct--header-container,
.timeline-container .rct--events {
  overflow-x: scroll !important;
  scroll-snap-type: both mandatory !important;
  scrollbar-width: none !important;
  /* Hide the scrollbar for a cleaner look */
  -ms-overflow-style: none !important;
  /* Hide the scrollbar for Internet Explorer */
}

.timeline-container .rct--header-container::-webkit-scrollbar,
.timeline-container .rct--events::-webkit-scrollbar {
  display: none !important;
  /* Hide the scrollbar for WebKit browsers */
}

.form-border {
  border: 1px solid #cbd0dd;
  border-radius: 10px;
}

.bg-white {
  background: white;
}

.property-form-input {
  border-radius: 5px;
  height: 2rem;
  outline: 2px solid #dee2e6 !important;
  width: 100%;
  font-size: 0.8rem;
  border: none;
  padding-left: 0.5rem;
  background-color: white !important;
}

.property-select-input {
  border-radius: 5px;
  height: 2rem;
  outline: 2px solid #dee2e6 !important;
  width: 100%;
  font-size: 0.8rem !important;
  border: none;
  /* padding-left: 0.5rem; */
}

.custom-select-input {
  border-radius: 5px;
  height: 2rem;;
  width: 100%;
  font-size: 0.8rem !important;
  border: none;
  /* padding-left: 0.5rem; */
}

.updated-form-input {
  border-radius: 5px;
  height: 2rem;
  width: 100%;
  font-size: 0.8rem;
  border: none;
  padding-left: 0.5rem;
  background-color: white !important;
}

.popup-summary {
  color: darkgray;
  font-size: 0.8rem;
}

.page-container {
  background-color: white;
  margin: 1rem;
  box-shadow: 73px 38px 195px 105px rgb(245 247 250 / 20%);
  border-radius: 05px;
  border: 1px solid #dee2e6;
}



.image {
  width: 50%;
}

.preview {
  width: 50%;
  overflow-y: auto;
  height: 20rem;
  overflow-x: hidden;
  margin-right: -20px !important;
  padding-right: 20px !important;
}

.block-width {
  width: 20%;
}

.two-line-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.property-main-image {
  height: 18rem;
  width: 100%;
  border-radius: 5px;
  cursor: pointer;
  object-fit: fill;
}

.property-image2 {

  /* height: 100%;
  width: 100%;
  border-radius: 5px;
  cursor: pointer; */

  height: 70px;
  width: 110px;
  border-radius: 5px;
  cursor: pointer;
  object-fit: fill;

}


.address-icon svg {
  font-size: 22px;
  color: #3874ff;
}

.address {
  color: darkgray;
}

.prop-title {
  font-weight: 800;
}

.general-point p {
  color: #c0c0c0;
  font-size: 0.9rem;
}

.general-point h6 {
  font-weight: 600;
  margin-top: 4px;
}

.prop-description {
  font-size: 0.9rem;
  color: darkgray;
  text-align: justify;
}

.general-pad {
  padding: 1rem 3rem;
}

.edit-link {
  color: #000;
  text-decoration: underline;
  text-align: right;
  /* display: block;
  width: 100%; */
  font-weight: 600;
  border: none;
  background: none;
}

.edit-link:hover {
  color: #3874ff;
}

.descriptions p {
  font-weight: 600;
}

.descriptions h6 {
  font-size: 0.85rem;
  color: darkgray;
  text-align: justify;
}

.heading-desc {
  font-size: 1.2rem;
  font-weight: 700;
}

.parameter {
  font-weight: 600;
  font-size: 1.2rem;
}

/* .amenities-heading {
  font-weight: 600;
  color: #656565;
} */

.edit-image-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  /* background: rgb(1 1 1 / 50%); */
  background: rgb(56 116 255 / 70%);
  padding: 0.3rem 1rem;
  color: white;
  font-weight: 600;
  border: none;
  width: fit-content;
  border-radius: 20px;
  box-shadow: 4px 4px 24px 0px rgba(56, 116, 255, 0.3);
}

.image-upload {
  position: absolute;
  top: 20%;
  right: 20%;
  left: 20%;
}


.dropdown {
  position: relative;
}


.card-drop {
  /* Styles for the card block */
  background-color: white;
  border: 1px solid #ebebeb;
  padding: 1rem;
  /* cursor: pointer; */
}




.filter-select2 {
  padding: 0.8rem 0.8rem;
  /* background: #e6effa; */
  background-color: white !important;
  width: 100%;
  height: 3rem;
  cursor: pointer;
  border-radius: 5px;
}

.filter-select3 {
  padding: 0.8rem 0.8rem;
  /* background: #e6effa; */
  background-color: white !important;
  border: none;
  width: 100%;
  height: 3rem;
  cursor: pointer;
  border-radius: 5px;
}

.filter-select3:focus {
  border: none !important;
  box-shadow: none !important;
}

.form-select.filter-select2:focus~label,
.form-select.filter-select2:valid~label {
  display: block;
  position: absolute;
  top: -9px !important;
  left: 12px !important;
  font-size: 13px;
  font-weight: 600 !important;
  background: white;
}

.month-select {
  display: flex;
  align-items: center;
  border: 1px solid #ccced1;
  background-color: white;
  padding: 0rem 0rem 0rem 1rem;
  border-radius: 5px;
}

.month-text {
  margin-right: 10px;
}



.month-select span {
  font-weight: 600;
}

.total-paid h6 {
  font-size: 1rem;
  font-weight: 700;

}

.total-paid p {
  margin-left: 10px;
  font-size: 1rem;

}

.drop-open-icon {
  transition: all 0.3s ease 0s;
  transform: rotate(180deg) translateZ(0px);
  color: #3874ff;
  cursor: pointer;
}

.drop-open-icon2 {
  transition: all 0.3s ease 0s;
  transform: rotate(180deg) translateZ(0px);
  cursor: pointer;
}

.drop-close-icon2 {

  transition: all 0.3s ease 0s;
  transform: rotate(0deg) translateZ(0px);
  cursor: pointer;

}

.drop-close-icon {

  transition: all 0.3s ease 0s;
  transform: rotate(0deg) translateZ(0px);
  color: #3874ff;
  cursor: pointer;

}

.paid-amount {
  margin-right: 10px;
  text-decoration: underline;
  color: #3874ff;
  cursor: pointer;
  font-weight: 700;
}

.transaction-card h6 {
  font-size: 0.9rem;
}

.transaction-card p {
  background-color: #ebebeb;
  padding: 0.1rem 0.3rem;
  border-radius: 5px;
  font-size: 0.7rem;
  margin-left: 10px;
  font-weight: 600;
}

.all-details {
  font-size: 0.8rem;
  margin-top: 4px;
}

.booking-link {
  text-decoration: underline;
  color: #3874ff;
  cursor: pointer;
  font-size: 0.9rem;

}

.reservation {
  font-size: 0.9rem;
  font-weight: 600;
}

.paid-amount2 {
  font-size: 0.9rem;
  font-weight: 600;
}

.inner-content span {
  font-size: 0.9rem;
  color: #969595;
}

.help-link {
  text-decoration: underline;
  color: #3874ff;
  cursor: pointer;
  font-size: 0.9rem;
}

.dropdown-content {
  padding-right: 2rem;
}

.export-btn2 {
  background: none;
  border: 1px solid darkgray;
  border-radius: 6px;
  padding: 0.2rem 1rem;
  font-size: 0.8rem;
  font-weight: 600;
}

.dropdown>.card-drop:first-child {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.dropdown>.card-drop:not(:first-child) {
  border-top: none !important;
}

.dropdown>.card-drop:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.reservation-card1 {
  background: white;
  padding: 1rem;
  width: 60%;
  margin: auto;
  box-shadow: -6px 5px 25px -3px rgb(146 148 161 / 20%);
  /* box-shadow: -6px 5px 10px 9px rgb(146 148 161 / 20%); */
  border-radius: 5px;
  border: 1px solid #dee2e6 !important;
}

.booking-detail {
  font-size: 0.8rem;
  color: darkgray
}

.bold-800 {
  font-weight: 800;
}

.bold-700 {
  font-weight: 700;
}

.bold-600 {
  font-weight: 600;
}

.pointer {
  cursor: pointer;
}

.filter-size {
  padding: 0.5rem 0.8rem !important;
  height: 2.5rem !important;
}

.black-dot {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #5e5e5e;
  box-shadow: -6px 5px 10px 9px rgb(146 148 161 / 20%);
  margin-left: 4px;
  margin-right: 4px;
}

.conversation {
  /* background-color: #f5f5f5; */
  /* height: 100%; */
  width: 100%;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;

}
.conversation {
  max-height: calc(100dvh - 61dvh);
}
.questions-you-can-ask .scroll-container {
  max-height: calc(100dvh - 68dvh);
}
.conversation-heading {
  padding: 1rem;
}

.conversation-heading h5 {
  font-size: 16px;
  font-weight: 700;
}

.persons {
  display: flex;
  align-items: center;
}

.persons .name {
  font-size: 0.9rem;
}

.persons .message {
  font-size: 0.7rem;
  color: darkgray;
}

.line-elipsis {
  width: 150px;
  /* Set the desired width */
  white-space: nowrap;
  /* Prevent the text from wrapping */
  overflow: hidden;
  /* Hide any overflowed content */
  text-overflow: ellipsis;
  /* Display an ellipsis (...) for truncated text */
}

.persons .user-image {
  border-radius: 50%;
  height: 35px;
  margin-right: 5px;
  width: 35px;
  border: 1px solid #dddddd;
}

.message-status {
  font-size: 0.8rem;
  color: #636363;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.page-container2 {
  background-color: white;
  margin: 1rem;
  border-radius: 5px;
  /* border: 1px solid #dee2e6; */
  box-shadow: -6px 5px 10px 9px rgb(146 148 161 / 20%);
}

.search_icon {
  color: darkgray;
  font-size: 21px;
}

.person-search {
  border: 1px solid lightgray;
  border-radius: 20px;
  padding: 0.1rem 0.5rem;
  cursor: pointer;
}

.border-r-2 {
  border-right: 2px solid lightgray;
}

.booking-item p {
  color: darkgray;
  font-size: 0.8rem;
  font-weight: 600;
}

.booking-item h6 {
  font-size: 0.7rem;
  font-weight: 700;
}


.booking {
  display: flex;
  align-items: center;
}

.booking .name {
  font-size: 0.85rem;
}

.booking .address {
  font-size: 0.75rem;
  color: black;
}


.booking .user-image {
  border-radius: 50%;
  height: 35px;
  margin-right: 5px;
  width: 35px;
}

.list-head {

  font-size: 0.7rem;
  color: darkgray;
  font-weight: 600;

}

.assigned {
  font-weight: 600;
  font-size: 0.9rem;
}

.booking-item .status {
  color: #01bb01;
}

.scroll-container {
  max-height: 36rem;
  overflow-y: hidden;

}

.scroll-container:hover {
  overflow-y: auto;
}

.scroll-container::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #F5F5F5;
}

.scroll-container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3); */
  background-color: #c9cdd3;
}

.person-active {
  background-color: white;
  /* box-shadow: -6px 5px 10px 9px rgb(146 148 161 / 20%); */
  box-shadow: -6px 6px 50px 9px rgb(146 148 161 / 20%);
}

.h-42 {
  height: 40rem;
}

.message-type-box {
  position: absolute;
  bottom: 0;
  /* padding: 1rem; */
  
  display: flex;
  align-items: center;
  border-radius: 5px;
  border: 2px solid lightgray;
  width: 100%;
  padding: 5px;
  background: whitesmoke;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.message-type-box2 {
  position: absolute;
  bottom: 0;
  /* padding: 1rem; */
  height: 6rem;
  
  display: flex;
  align-items: center;
  border-radius: 5px;
  border: 2px solid lightgray;
  width: 100%;
  padding: 5px;
  background: whitesmoke;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.change-editior .ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar,
.change-editior .ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
  background: white !important;
  border-radius: 0px !important;
}

.change-editior .ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable,
.change-editior .ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners {
  background: white !important;
  border-radius: 0px !important;
  height: 6rem !important;
  border-bottom: none !important;
}

.change-editior {
  background-color: white;
}

.send-btn {
  padding: 0.3rem 1.3rem;
  color: white;
  background-color: #3874ff;
  border: none;
  border-radius: 6px;
}

.btn-position {
  display: flex;
  justify-content: space-between;
}

.sender-message {
  background-color: #e8e8e8;

  padding: 0.7rem;
  border-radius: 0px 15px 15px 14px;
  margin-left: 1rem;
}

.receiver-message {

  background-color: #d3d3d3;
  padding: 0.7rem;
  border-radius: 15px 0px 15px 14px;
  margin-right: 1rem;

}

.message-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.chat-section {
  overflow-y: auto;
  /* max-height: 31.5rem; */
  /* padding-bottom: 100px !important; */
}

.chat-section:hover {
  overflow-y: auto;
}

.chat-section::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #F5F5F5;
}

.chat-section::-webkit-scrollbar-thumb {
  border-radius: 10px;
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3); */
  background-color: #c9cdd3;
}

.message-time {
  font-size: 0.75rem;
  color: darkgray;
  font-weight: 600;
  margin-top: 5px;
}

.message-text {
  font-size: 0.9rem;
  color: #757171;
}

.booking-card-pad {
  padding: 0rem 1rem 0rem 1rem;
}

.select-message {
  border: none;
  font-size: 0.9rem;
}

.unit-table {
  background-color: #eeeeee;
}

.occupancy-points h5 {
  font-size: 1rem;
}

.occupancy-points p {
  font-weight: 700;
}


tbody .unit-table {
  box-shadow: 0px 14px 21px -5px rgb(146 148 161 / 20%);
}

tr.unit-table:first-child {
  background-color: #000;
}

.active {
  color: #3874ff;
  font-weight: 700;
}

.drop-open-icon2.active {
  color: #3874ff;
  font-weight: 700;
}

.mytable.table>:not(:last-child)>:last-child>* {
  border-bottom-color: lightgray;
}

.report-card-block {
  text-align: left;
  background: #ffffff;
  /* box-shadow: 2px 2px 10px 3px rgb(146 148 161 / 20%); */
  box-shadow: 0 0 5px #ddd;
  padding: 15px;
  border-radius: 8px;
  transition: all .3s;
  height: auto;
  cursor: pointer;
  height: 100%;
  border: 1px solid rgba(0, 0, 0, .125);
}

.report-card-block h5 {
  font-weight: 700;
  font-size: 1.1rem;
}

.report-card-block p {
  font-size: 0.9rem;
  color: darkgray;
  font-weight: 600;
}

.report-link {
  text-align: right;
}



.search-filter {
  /* text-align: right; */
  border: 1px solid rgba(0, 0, 0, .125);
  border-radius: 8px;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0.8rem 1rem;
  background-color: #ffffff;
  height: 3rem;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  display: flex;
  align-items: center;
}

.search-filter:focus-visible {
  border: 1px solid blue;
}

.search-filter input {
  border: none;
  margin-left: 10px;
  background: none;
}

.search-filter input::placeholder {
  /* color: rgb(224, 220, 220); */
  color: darkgray;
}

.search-filter input:focus-visible {
  outline: none !important;
}

.month-select2 {
  display: flex;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, .125);
  background-color: white !important;
  padding: 0.5rem 5rem 0.5rem 0.5rem;
  border-radius: 5px;
  /* height: 2rem; */
  /* box-shadow: 2px 2px 10px 3px rgb(146 148 161 / 20%); */
  box-shadow: 0 0 5px #ddd;
}

.year-select2 {
  display: flex;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, .125);
  background-color: white !important;
  padding: 0.5rem 4rem 0.5rem 0.5rem;
  border-radius: 5px;
  /* height: 2rem; */
  /* box-shadow: 2px 2px 10px 3px rgb(146 148 161 / 20%); */
  box-shadow: 0 0 5px #ddd;
}

.chart-btn {
  padding: 0.5rem 1.5rem;
  background-color: white;
  border: none;
  font-size: 0.9rem;
}

.chart-btn:first-child {
  border-radius: 5px 0px 0px 5px;
  border-right: 1px solid rgba(0, 0, 0, .125);
}

.chart-btn:last-child {
  border-radius: 0px 5px 5px 0px;
  border-left: 1px solid rgba(0, 0, 0, .125);
}

.all-btn {

  /* box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2); */
  border: 1px solid rgba(0, 0, 0, .125) !important;
  border-radius: 5px;
  display: flex;
  width: fit-content;
  /* box-shadow: 2px 2px 10px 3px rgb(146 148 161 / 20%) ; */
  box-shadow: 0 0 5px #ddd;

}

.chart-btn.active {
  background-color: #3874ff;
  color: white;
}

.box-shd {
  /* box-shadow: 2px 2px 10px 3px rgb(146 148 161 / 20%) !important; */
  box-shadow: 0 0 5px #ddd !important;
}

.cancel-heading {
  font-size: 0.8rem;
  border-bottom: 2px solid #dee2e6;
}

.cancel-sub-heading {
  font-size: 0.7rem;
}

.cancel-status-bar {
  width: 100%;
  background-color: #85d37a;
  height: 1.8rem;
  display: flex;
  align-items: center;
  color: white;
  font-weight: 700;
  font-size: 0.8rem;
  justify-content: center;
  border: 1px solid #fff;

}

.cancel-points {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.cancel-points .point-1 {
  background-color: #85d37a;
  width: 10px;
  height: 10px;
  margin-right: 10px;
}

.cancel-points h6 {
  font-size: 0.7rem;
  margin-top: 3px;
}

.cancel-points .point-2 {
  background-color: #ffaa00ab;
  width: 10px;
  height: 10px;
  margin-right: 10px;
}

.cancel-points .point-3 {
  background-color: #3874fff5;
  width: 10px;
  height: 10px;
  margin-right: 10px;
}

.rate-bar {

  width: 100%;
  background-color: #e8e8e8;
  height: 1.8rem;
  color: black;
  font-size: 0.8rem;
  border: 1px solid #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;

}

.rate-line {
  width: 100px;
  height: 1px;
  background-color: darkgray;
  z-index: 10000000;
}

.circle2 {
  width: 10px;
  height: 10px;
  border: 2px solid darkgray;
  border-radius: 50%;
}


.rate-bar::after {
  content: '';
  height: 62px;
  width: 2px;
  position: absolute;
  background-color: darkgray;


}


.maintenance-card-block {
  display: flex;
  justify-content: space-between;
  text-align: left;
  background: #ffffff;
  box-shadow: 0 0 5px #ddd;
  /* padding: 15px; */
  transition: all .3s;
  height: auto;
  height: 100%;
  border-radius: 10px;
}

.maintenance-icon1 {
  display: flex;
  align-items: center;
  background-color: #7be47b;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.maintenance-card-block .value-1 {
  color: #7be47b;
}

.maintenance-icon2 {
  display: flex;
  align-items: center;
  background-color: #ffc46c;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.maintenance-card-block .value-2 {
  color: #ffc46c;
}

.maintenance-icon3 {
  display: flex;
  align-items: center;
  background-color: #6cb1fb;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.maintenance-card-block .value-3 {
  color: #6cb1fb;
}

.maintenance-icon4 {
  display: flex;
  align-items: center;
  background-color: #b081f8;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.maintenance-card-block .value-4 {
  color: #b081f8;
}


.maintenance-icon5 {
  display: flex;
  align-items: center;
  background-color: #fa625b;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.maintenance-card-block .value-5 {
  color: #fa625b;
}

.maintenance-card-block svg {
  font-size: 75px;
  color: white;
  font-weight: 700;
  padding: 0rem 0.8rem;
}

.maintenance-detail {
  width: 100%;
  text-align: right;
  padding: 1rem;
}

.grp-btn {
  text-align: end;
}

.approve-btn {
  background-color: #3874ff;
  color: white;
  border: none;
  padding: 0.2rem 0.8rem;
  border-radius: 4px;
  font-weight: 700;
  font-size: 0.8rem;
}

.decline-btn {
  background-color: #ff483f;
  color: white;
  border: none;
  padding: 0.2rem 0.95rem;
  border-radius: 4px;
  font-weight: 700;
  font-size: 0.8rem;
}



.noti-scroll {
  overflow-y: hidden;
  height: 35rem;
  padding: 2px;
}

.h-40 {
  height: 40rem;
}


.noti-scroll:hover {
  overflow-y: auto !important;
}


.noti-scroll::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #F5F5F5;
}

.noti-scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3); */
  background-color: #c9cdd3;
}


.notifications {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.notifications span {
  font-size: 0.85rem;
  font-weight: 700;
  color: #3874ff;
}

.notifications h6 {
  font-weight: 700;
  font-size: 0.95rem;
}

.notifications p {
  color: #bbbbbb;
  font-size: 0.85rem;
  line-height: 1.2;
}

.check-label {
  margin-left: 10px;
  margin-top: 3px;
  margin-top: 2px;
}

.check2 input {
  width: 1rem;
  height: 1rem;
}

.check-label2 {
  margin-left: 10px;

}

.check2 {
  margin-right: 20px;
  align-items: center;
}

.booking-popup {
  position: absolute;
  background-color: white;
  /* padding: 1rem; */
  color: black;
  font-weight: 600;
  z-index: 1;
  bottom: 3%;
  right: 2%;
  z-index: 10;
  left: 75%;
  box-shadow: -6px 10px 25px -3px rgba(34, 35, 58, 0.2);
}

.close-popup-btn {
  background-color: white;
  border: none;
  font-size: 1.2rem;
  color: darkgray;
}

.booking-popup p {
  font-weight: 500;
  color: black;
  font-size: 0.9rem;
}

.itinery-btn {
  background-color: #3874ff;
  width: 100%;
  border: none;
  color: white;
  font-weight: 700;
  padding: 0.5rem 1rem;
  border-radius: 5px;
}

.middle-booking {
  height: 18rem;
  overflow-y: auto;
  padding: 0.5rem 1rem 1rem 1rem;
}


.middle-booking::-webkit-scrollbar {
  width: 8px;
  height: 6px;
  background-color: #F5F5F5;
  position: absolute;
  right: 0;
  top: 0%;
  bottom: 100%;
}

.middle-booking::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #c9cdd3;
}

.bottom-div {
  background-color: white;
  padding: 1rem;
}

.top-div {
  background-color: white;
  padding: 0.5rem 1rem;
}

.booking-popup .top-div {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 9px 15px -7px !important;
}


.booking-popup .bottom-div {
  /* box-shadow: 0px 0px 15px 0px rgba(34, 35, 58, 0.2); */
  box-shadow: rgba(0, 0, 0, 0.15) 0px -9px 15px -7px !important;
}

.review-link:hover {
  color: #3874ff;
}


.notification-popup {
  background-position: 100%;
  background: #fff;
  border-radius: 0.375rem;
  /* padding: 1rem; */
  position: absolute;
  right: -50%;
  top: 200%;
  width: 18rem;
  z-index: 99;
  width: 20rem;
}


.notification-popup:after {
  background: inherit;
  border-color: #dee2e6 !important;
  border-style: solid;
  border-top-left-radius: 0.25rem;
  border-width: 1px 0 0 1px;
  content: "";
  height: 1.5rem;
  left: auto !important;
  left: 1.5rem;
  position: absolute;
  right: 0.9rem;
  top: -13px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 1.5rem;
  z-index: -1;
}

.noti-summary {
  font-size: 0.9rem;
  color: darkgray;
}

.noti-heading {
  font-size: 0.9rem;
}

.noti-heading {
  font-size: 0.85rem;
}

.notification {
  padding: 0.5rem;
  margin-bottom: 5px;
}
 
.unread-notification {
  background-color: #eeeeee;
}
.notification:hover {
  background-color: #eff2f6;
  border-radius: 5px;

}
.notification-scroll .notification {
  border: 1px solid #eee;
  border-radius: 5px;
  transition: all 0.5s;
}

.notification-scroll .notification svg {
  background-color: #fff;
  width: 40px;
  min-width: 40px;
  height: 40px;
  padding: 9px;
  border: 1px solid #eee;
  border-radius: 50%;
}

.notification-scroll {
  overflow-y: hidden;
  height: 20rem;
  padding: 10px 10px 10px 10px;
}

.notification-scroll:hover {
  overflow-y: auto !important;
}


.notification-scroll::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #F5F5F5;
}

.notification-scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3); */
  background-color: #c9cdd3;
}

.all-btn2 {
  background-color: white;
  border: none;
  padding: 0.3rem 1rem;
  border-radius: 25px;
  font-weight: 700;
  font-size: 0.9rem;
}

.unread-btn {
  background-color: white;
  border: none;
  padding: 0.3rem 1rem;
  border-radius: 25px;
  font-weight: 700;
  font-size: 0.9rem;
}

.all-btn3 {
  background-color: white;
  border: none;
  padding: 0.3rem 1rem;
  border-radius: 25px;
  font-weight: 700;
  font-size: 1rem;
}

.unread-btn2 {
  background-color: white;
  border: none;
  padding: 0.3rem 1rem;
  border-radius: 25px;
  font-weight: 700;
  font-size: 1rem;
}

.all-btn2.active {
  background-color: #e7f3ff;
}

.unread-btn.active {
  background-color: #e7f3ff;
}

.see_all_link {
  color: #3874ff;
  padding: 0.2rem 0.5rem;
}

.see_all_link:hover {
  background-color: #e7f3ff;
  border-radius: 5px;
}

.notification-card {
  background: white;
  /* padding: 1rem; */
  /* width: 50%; */
  /* margin: auto; */
  /* box-shadow: -6px 5px 10px 9px rgb(146 148 161 / 20%); */
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  padding: 0 8px;
  padding-bottom: 5px;
}
.notification-card .notification {
  border: 1px solid #eee;
  border-radius: 5px;
  transition: all 0.5s;
}
.notification-card .notification svg {
  background-color: #fff;
  width: 50px;
  height: 50px;
  padding: 9px;
  border: 1px solid #eee;
  border-radius: 50%;
}
.basic-multi-select2 {

  border-color: #dee2e6 !important;
  border-radius: 6px;
  border-style: solid;
  border-width: 2px !important;
  max-height: 40px;
  min-height: 36px;
  margin-top: -1px;

}

.basic-multi-select3 {

  border-color: #dee2e6 !important;
  border-radius: 6px;
  border-style: solid;
  border-width: 1px !important;
  max-height: 40px;
  min-height: 36px;
  margin-top: -1px;

}

.pricing-block {
  border: 1px solid #dee2e6;
  border-radius: 5px;
  background-color: #f4f4f4;
  padding: 3rem;
}

.price-btn {

  background-color: white;
  border: 1px solid #dee2e6;
  padding: 1.2rem;
  font-size: 1.5rem;
  line-height: 0;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 5px #ddd;
}

.price-input {
  border: 1px solid #dee2e6;
  padding: 1rem;
  border-radius: 5px;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 800;
  background-color: white;
}

.price-summary {
  text-align: center;
  font-size: 0.9rem;
  margin-top: 10px;
}

.discount-block {
  border: 1px solid #dee2e6;
  border-radius: 5px;
  background-color: #f4f4f4;
  padding: 1rem;
}

.discount-input {
  height: 35px;
  width: 50px;
  border: none;
  text-align: center;
  border-radius: 8px;

  font-weight: 700;
}

.discount-input fieldset {
  border: none;
}

.discount-input:focus {
  outline: none;
}

.discount-input-box {
  height: 40px;
  width: fit-content;
  border: 2px solid #dee2e6;
  text-align: center;
  border-radius: 8px;
  margin-right: 10px;
  font-weight: 700;
  background-color: white;

}

.discount-input-box span {
  font-weight: 800;
}

.discount-type {
  margin-top: 4px;
}

.discount-type h6 {
  font-size: 0.9rem;
}

.discount-type p {
  font-size: 0.9rem;
  color: darkgray;
}

.discount-checked {
  height: 25px;
  width: 25px;
  border: 2px solid #dee2e6;
  text-align: center;
  border-radius: 8px;
  margin-right: 10px;
}

.discount-checked svg {
  font-size: 28px;
}

.cancel-input {
  min-width: 20px;
  min-height: 20px;
  max-width: 20px;
  max-height: 20px;
}

.cancel-label h6 {
  font-size: 1rem;
}

.cancel-label p {
  font-size: 0.8rem;
  color: darkgray;
}

.new-tag {
  background-color: #e1dfdf;
  padding: 0.25rem 0.5rem;
  color: black;
  font-size: 0.6rem;
  font-weight: 800;
  border-radius: 5px;
  margin-bottom: 0;
}


.number-btn p {
  background-color: white;
  border: 2px solid #d4d4d4;
  color: #d4d4d4;
  padding: 0.65rem;
  font-size: 1.2rem;
  line-height: 0;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.number-input {
  border-radius: 5px;
  height: 2rem;
  outline: none;
  font-size: 0.8rem;
  border: none;
  padding-left: 0.5rem;
  -moz-appearance: textfield;
  /* For Firefox support */
  -webkit-appearance: none;
  appearance: none;
  width: 15%;
  padding-left: 0;
}

.number-input:focus {
  /* outline: 2px solid #dee2e6 !important; */
  -moz-appearance: textfield;
  /* For Firefox support */
  -webkit-appearance: none;
  appearance: none;
}

/* Hide the inner and outer spin buttons (Chrome, Safari, Edge) */
.number-input::-webkit-inner-spin-button,
.number-input::-webkit-outer-spin-button,
.price-input::-webkit-inner-spin-button,
.price-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.divide {

  border-right: 1px solid #d4d4d4;
  padding-right: 15px;

}

.security p {
  font-size: 0.8rem;
}

.security-checked {
  height: 16px;
  width: 16px;
  border: 2px solid #dee2e6;
  text-align: center;
  border-radius: 8px;
  /* color: #3874ff !important; */
}

.security-checked svg {
  font-size: 22px;
}

.security-description h6 {

  font-size: 0.8rem;

}

.security-description p {
  font-size: 0.75rem;
  color: darkgray;

}

.height-2 {
  height: 2.2rem !important;
}


.not-found-text {
  font-size: 0.8rem;
  text-align: center;
  margin-bottom: 5px;
}

.change-filter {
  padding: 0.4rem 1rem;
  margin: 0rem 0rem 0.2rem 0rem;

}

.property-select-input fieldset {
  border: none !important;
}

.property-form-input fieldset {
  border: none !important;
}

.basic-multi-select2 fieldset {
  border: none !important;
}

.basic-multi-select3 fieldset {
  border: none !important;
}

.textarea-input textarea {
  border: none !important;
}

.property-form-input input {
  padding-left: 0.5rem !important;
  padding-top: 0.3rem;
  padding-right: 0.2rem;
  padding-bottom: 0.4rem;
  font-size: 0.9rem;
  font-weight: 400 !important;
}

.updated-form-input input {
  padding-left: 0.5rem !important;
  padding-top: 0.3rem;
  padding-right: 0.2rem;
  padding-bottom: 0.4rem;
  font-size: 0.9rem;
  font-weight: 400 !important;
}

.menu-item {
  font-size: 0.8rem !important;
  border: none !important;
}

.property-address {

  width: 250px;
  /* Set the desired width */
  white-space: nowrap;
  /* Prevent the text from wrapping */
  overflow: hidden;
  /* Hide any overflowed content */
  text-overflow: ellipsis;
  /* Display an ellipsis (...) for truncated text */

}

.amenities-checked {
  height: 16px;
  width: 16px;
}

.amenities-checked svg {
  font-size: 18px;
}

.radio-size {
  padding: 0px !important;
}

.radio-size svg {
  font-size: 20px;
}


.image-slider .swiper-button-prev,
.image-slider .swiper-button-next {
  position: absolute;
  width: 30px !important;
  height: 30px !important;
  background-color: #ffffffd6;
  border-radius: 50%;
  box-shadow: 0 14px 80px rgba(34, 35, 58, .2) !important;
  padding: 1rem;
}


.image-slider .swiper-button-next:after,
.image-slider .swiper-button-prev:after {
  font-family: swiper-icons;
  font-size: var(--swiper-navigation-size);
  text-transform: none !important;
  letter-spacing: 0;
  font-variant: initial;
  line-height: 1;
  font-size: 1.1rem !important;
  font-weight: 900;
}

.img-del-btn {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  background: none;
  font-size: 18px;
  background: #d3d3d3b3;
  border-radius: 50%;
  color: #6b6969;
  height: 1.5rem;
  /* padding: 2px 10px 0; */
  width: 1.5rem;
  line-height: 1;
  color: black;
  font-weight: 600;
  /* /* box-shadow: 0 0 5px #ddd; */
}

.pagination-position {
  display: flex;
  justify-content: flex-end;
}

.rdrDateDisplayWrapper {
  display: none;
}

.image-gallery-play-button {
  display: none !important;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: 600px !important;
}


.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100dvh; */
  margin-top: 20px;
}

.loader {
  border: 8px solid #dcdcdc;
  border-top: 8px solid #3874ff;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


.single_calendar .react-calendar {
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 0.3rem;
}

.single_calendar .react-calendar__navigation button {
  font-size: 25px;
}

/* .single_calendar .react-calendar__navigation__prev-button, .single_calendar .react-calendar__navigation__prev2-button, .single_calendar .react-calendar__navigation__next-button, .single_calendar .react-calendar__navigation__next2-button{
  background-color: lightgray;
  margin-right: 10px;
} */

.single_calendar .react-calendar__navigation__label__labelText {
  font-size: 16px !important;
}

.single_calendar .react-calendar__tile {
  /* margin: 5px !important; */
  /* flex: 0 0 8.2857% !important; */
  border: 2px solid white;
  border-radius: 5px;
}

.single_calendar .react-calendar__month-view__days__day--weekend {
  color: #000;
}

/* .single_calendar .react-calendar__tile {
  background-color: #e6e6e6;
} */

/* .single_calendar .react-calendar__month-view__weekdays__weekday{
  flex: 0 0 8.2857% !important;
} */

/* Add your custom styles for tiles */
/* .booked {
  background-color: red;
  color: white;
} */

.custom-tile {
  width: 100%;

  text-align: center;
}

.text {
  font-size: 12px;
  margin-top: 4px;
}


.booked-tile {
  background-color: #3874ff !important;
  color: white !important;
}

.available-tile {
  background-color: #70edfc !important;
  color: black;
}

.past-tile {
  background: repeating-linear-gradient(-45deg, rgb(255, 255, 255), rgb(255, 255, 255) 0px, rgb(235, 235, 235) 2px, rgb(252, 252, 252) 3px) !important;
  color: lightgray !important;
}

.blocked-tile {
  background: repeating-linear-gradient(-45deg, rgb(182, 182, 182), rgb(182, 182, 182) 0px, rgb(235, 235, 235) 2px, rgb(252, 252, 252) 3px) !important;
  color: darkgray !important;
}

.today {
  background-color: #70fcae !important;
  color: black;
}

.custom-timeline-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  /* Add transparency */
  font-size: 20px;
}

.border_active {
  border-bottom: 3px solid #3874ff;
  /* background-color: #3874ff !important;
  color: white;
  border-radius: 5px; */
}

.property_heading {
  cursor: pointer;
  padding: 0.7rem 1rem 0.7rem 1rem;
  ;
  /* background-color: #f1f0f0;
  border-radius: 5px; */
  margin-right: 20px;
}

.property_heading h6 {
  font-size: 15px;
  width: max-content;
}

.border_active h6 {
  color: #3874ff;
  font-weight: 600;
}

.borderBottom {
  border-bottom: 2px solid #dee2e6 !important;
}

.property_upper {
  display: flex;
  overflow-x: auto;
}

.calendar_sidebar {
  width: 250px;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  /* margin-left: 20px; */
  padding-left: 10px;
}

.inputWidth .MuiBox-root {
  display: block;

}

.rbc-row-content {
  height: 100%;
}

/* .rbc-row {
  height: 100%;
} */

.custom-date-cell p {
  position: absolute;
  bottom: 0;
  margin-left: 10px;
  color: darkgray;
}



.place_card {
  position: absolute;
  /* top: 85%; */
  /* right: 10%; */
  z-index: 10000;
  margin-top: 10px;
  border: 1px solid #ddd;
  background-color: white;
  border-radius: 5px;
  width: 40%;
}

.place_card2 {
  position: absolute;
  /* top: 85%; */
  /* right: 10%; */
  z-index: 10000;
  margin-top: 10px;
  border: 1px solid #ddd;
  background-color: white;
  border-radius: 5px;
  width: 150%;
}

.placeList {
  padding: 0.5rem 0.5rem;
  display: flex;
  border-bottom: 1px solid #ddd;
}

.placeList svg {
  font-size: 20px;
  color: #ddd;
  margin-top: 2px;
  margin-right: 2px;
}

.placeList:hover svg {

  color: #fff;
}

.placeList:hover {
  background-color: #3874ff;
  color: white;
}

.placeList p {
  width: 100%;
  font-size: 14px;
}

.place_card2 .placeList p {
  width: 100%;
  font-size: 12px;
}

.place_card2 .placeList svg {
  font-size: 18px;
  margin-top: 1px;
  margin-right: 2px;
}

/* .image-gallery-left-nav{
  left: 15% !important;
}

.image-gallery-right-nav {
  right: 15% !important;
} */

.image-gallery-right-nav .image-gallery-svg {
  width: 50px !important;
}

.image-gallery-icon:hover {
  color: #3874ff !important;
}

.custom-item {
  height: 50px !important;
  border: 1px solid #ddd;
  box-shadow: none !important;
  background: none !important;
  color: #000 !important;
  border: 1px solid #ddd !important;
  margin-top: -9px !important;
  border-radius: 0px !important;
  display: flex !important;
  justify-content: flex-end !important;

}

.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-6,
.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-0 {
  background: none !important;
}

.slash {
  font-size: 24px !important;
  font-weight: bold !important;
  color: blue !important;
  /* You can change the color */
}

.today-marker {
  background-color: red !important;
  /* Other custom styles */
}


.slash-box {

  border: 1px solid darkgray !important;
  height: 50px !important;
  box-shadow: none !important;
  color: #000 !important;
  padding: 0px !important;
  border-radius: 0px !important;
  margin-top: -9px;
  text-align: center;
  display: block !important;
  background: linear-gradient(to left top, rgb(211 211 211) 0%, rgb(211 211 211) calc(50% - 1px), rgb(124 124 124) 50%, rgb(211 211 211) calc(50% + 1px), rgb(211 211 211) 100%) !important;
}


.card {

  /* margin: auto; */
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  /* padding: 40px 55px 45px 55px; */
  padding: 30px;
  border-radius: 15px;
  transition: all .3s;
  text-align: left;
}

.booked-item {
  z-index: 100 !important;
  border: none !important;
  text-transform: capitalize !important;
}


.amenities-popup {
  top: 50%;
  left: 50%;
  /* right: 20%; */
  /* bottom: 50px; */
  transform: translate(-50%, -50%);
  z-index: 1111;
  max-height: calc(100vh - 100px);
  width: calc(100% - 20px);
  max-width: 330px;
  overflow-x: hidden;
  margin: 0 auto;
  /* margin-bottom: 60px; */
  border-radius: 5px;
  background: #fff;
  height: auto;
  overflow: auto;
  /* max-height: 90dvh; */
  position: absolute;
}
.amenities-popup.v-amenities-popup{
  width: calc(100% - 30px);
  max-width: 1000px;
}

.modal_title2 {
  display: flex;
  justify-content: space-between;
  padding: 1rem 1rem 0rem 0rem;
}

.modal_title2 button {
  border: none;
  background: #3874ff;
  color: white;
  font-weight: 700;
  padding: 0.1rem 0.5rem;
  border-radius: 5px;
  font-size: 18px;
}

.add-amenities-btn {
  background: none;
  border: 1px solid #ccced1;
  border-radius: 5px;
  padding: 0.5rem;
}

.loader2{
  border: 4px solid #dcdcdc;
    border-top: 4px solid #3874ff;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 2s linear infinite;
}

.loader2-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100dvh; */
  /* margin-top: 20px; */
}


.property-list-car {
  background: #f3f3f3;
  border: 1px solid #dee2e6;
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.property-list-car img {
  height: 113px;
  object-fit: cover;
  border-radius: 10px;
}
.property-list-car h3 {
  font-size: 20px;
  text-transform: capitalize;
  margin-bottom: 6px;
  font-weight: 900;
}
.property-list-car h5 {
  font-size: 13px;
  margin-bottom: 7px;
  font-weight: 700;
}
.property-list-car p {
  color: #b6b6b6;
  font-size: 14px;
  margin-bottom: 8px;
}
.property-list-car pre {
  margin-bottom: 0;
  font-family: 'Nunito Sans', sans-serif;
}
.property-list-car .input-with-button {
  display: flex;
  gap: 10px;
  justify-content: space-between;
}
.property-list-car .all-button {
  display: grid;
  gap: 5px;
  width: 100%;
}
.property-list-car .all-button button {
  background: #3874ff;
  color: #fff;
  padding: 7px;
  border-radius: 5px;
  border: 0;
  font-size: 12px;
}
.property-list-car .input-with-button .three-dot span {
  font-size: 30px;
  height: 30px;
  width: 30px;
  line-height: 43px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #5a5a5a;
  color: #fff;
  padding-bottom: 14px;
  border-radius: 4px;
}
.radio-size span{
  color: #3874ff !important;
}
/* Add this CSS to your stylesheet or component-specific CSS file */
.card-button {
  cursor: pointer; /* Change cursor to pointer on hover to indicate clickability */
  padding: 10px; /* Add padding for a button-like feel */
  border: none; /* Remove the border for a flat button style */
  border-radius: 4px; /* Add some rounded corners for a button appearance */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Add a subtle shadow for depth */
  transition: background-color 0.3s; /* Add a smooth background color transition on hover */

  /* Add more styling as needed */
}


.loader-small {
  border: 4px solid #dcdcdc;
  border-top: 4px solid #3874ff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;

}

.loader-small {
    border: 4px solid #dcdcdc;
    border-top: 4px solid #3874ff;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;

}



.suggestion-list {
  width: 100%;
  background: transparent;
  position: relative;
  padding: 22px;
}

.message-type-box button {
  padding: 24px 31px;
}

.suggestion-list input.messageTxt {
  background: transparent;
}
.suggestion-list .listingData{
  position: absolute;
  bottom: 61px;
  background: #fff;
  width: 100%;
  border: 1px solid #eee;
  max-width: 250px;
  border-radius: 4px;
  box-shadow: 4px 4px 4px #eee;
  margin-bottom: 0;
  max-height: 400px;
  overflow: auto;
}
.suggestion-list .listingData p{
  font-size: 15px;
  padding: 10px 15px;
  letter-spacing: 0.5px;
  font-weight: 600;
}
.suggestion-list ul {
  padding: 0px;
  list-style: none;
}

.suggestion-list ul li {
  font-size: 14px;
  line-height: 31px;
  border-bottom: 1px solid #eeee;
  padding: 10px 18px;
  cursor: pointer;
}
.suggestion-list ul li:last-child {
  border-bottom: 0;
}
.suggestion-list ul li.dropwdownBox:hover  {
  background-color: #f1f1f1;
  /* padding: 0.rem; */
  /* border-radius: 5px; */
  /* margin-right: 1rem; */
}
.suggestion-list ul li.dropdownBox.hilight{
  background-color: #f1f1f1;
}


.infomation_icon {
  font-size: 23px;
  color: #000;
}

@media screen and (max-width: 599px) {
  .v-table-card-design .MuiGrid-root {
    position: relative;
  }
  .v-table-card-design .MuiBox-root .MuiGrid-item{
    padding-left: 0 !important;
    align-items: center;
    border-bottom: 1px solid #d9d9d9;
    padding: 10px;
    padding-right: 0;
    margin-bottom: 0 !important;
    position: relative;
  }
  .v-table-card-design .MuiBox-root .MuiGrid-item:nth-child(1) {
    padding-top: 0;
    border-bottom: 0;
    padding-bottom: 0;
  }
  .v-table-card-design .MuiBox-root .MuiGrid-item:last-child {
    position: absolute;
    top: 5px;
    right: 0px;
    border-bottom: 0;
    /* background: #eeeeee; */
    width: 30px;
    height: 33px;
    padding: 0;
    display: flex;
    justify-content: center;
    border-radius: 2px;
    border-top-right-radius: 5px;
  }
  .v-table-card-design .MuiBox-root .MuiGrid-item:nth-last-child(2){
    border-bottom: 0;
    padding-bottom: 0;
    min-height: 10px;
  }
  .v-table-card-design .MuiBox-root .MuiGrid-item h6.prop-head {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0px;
  }
  
  
}
