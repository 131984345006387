.v_talble_setup{
  background-color: #eee;
  padding: 20px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.v_talble_setup table {
  border-collapse: separate !important;
  border-spacing: 0 15px;
}
.v_talble_setup table thead tr th{
  font-size: 12px;
  font-weight: 700;
  padding-bottom: 0;
    padding-top: 0;
}
.v_talble_setup table tbody tr td{
  background-color: #fff;
}
.v_talble_setup table tbody tr td:first-child{
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.v_talble_setup table tbody tr td:last-child{
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.v_talble_setup table tbody .v_unit{
  min-width: 225px;
}
.v_talble_setup table tbody .v_unit p{
  font-size: 11px;
}
.v_talble_setup table tbody .v_unit .v_left_svg{
  background-color: #eee;
  padding: 13px;
  width: 50px;
  height: 50px;
  border-radius: 6px;
}
.v_talble_setup table tbody .v_unit h3{
  font-size: 12px;
  font-weight: 700;
}
.v_talble_setup table tbody .v_unit ul li{
  font-size: 11px;
  display: flex;
  align-items: center;
  gap: 0px;
}
.v_talble_setup table tbody .v_unit ul li svg{
  font-size: 21px;
  color: #3874ff;
  /* background: #3874ff; */
  padding: 4px;
  border-radius: 3px;
}
.v_talble_setup table tbody .v_unit p.managed_by{
  font-size: 11px;
}
.v_talble_setup table tbody .v_managed_By{
  min-width: 95px;
  padding-top: 24px;
}
.v_talble_setup table tbody .v_managed_By p{
  font-size: 11px;
}
.v_talble_setup table tbody .v_current_status{
  min-width: 93px;
}
.v_talble_setup table tbody .v_current_status h6{
  font-size: 12px;
}
.v_talble_setup table tbody .v_current_status p{
  font-size: 11px;
  padding-top: 19px;
}
.v_talble_setup table tbody .v_available_from{
  min-width: 100px;
  padding-top: 19px;
}
.v_talble_setup table tbody .v_available_from p{
  font-size: 11px;
}
.v_talble_setup table tbody .v_tenancy{
  min-width: 116px;
}
.v_talble_setup table tbody .v_tenancy p{
  font-size: 11px;
  line-height: 15px;
}
.v_talble_setup table tbody .v_listing svg{
  cursor: pointer;
}
.v_talble_setup table tbody .v_listing_status{
  min-width: 120px;
  padding-top: 19px;
}
.v_talble_setup table tbody .v_listing_status p{
  font-size: 11px;
}
.v_talble_setup table tbody .v_listing_dropdown{
  min-width: 17px;
  position: relative;
  text-align: right;
}
.v_talble_setup table tbody .v_listing_dropdown .v_listing_dropdown_list{
  position: absolute;
  top: 36px;
  right: 0;
  margin: 0;
  background: #fff;
  padding: 10px;
  border: 1px solid #ddd;
  box-shadow: 4px 4px 4px #eee;
  border-radius: 10px;
  text-align: left;
  z-index: 9;
  min-width: 122px;
}
.v_talble_setup table tbody tr:last-child td .v_listing_dropdown .v_listing_dropdown_list{
  bottom: 36px;
  top: inherit;
}
.v_talble_setup table tbody tr:first-child td .v_listing_dropdown .v_listing_dropdown_list{
  top: 36px;
  bottom: inherit;
}
.v_talble_setup table tbody .v_listing_dropdown .v_listing_dropdown_list li{
  font-size: 11px;
  display: flex;
  align-items: center;
  gap: 9px;
  padding: 6px 0;
  border-bottom: 1px solid #eee;
}
.v_talble_setup table tbody .v_listing_dropdown .v_listing_dropdown_list li:first-child{
  padding-top: 0;
}

.v_talble_setup table tbody .v_listing_dropdown .v_listing_dropdown_list li:last-child{
  border-bottom: 0;
  padding-bottom: 0;
}
.v_talble_setup table tbody .v_listing_dropdown .v_listing_dropdown_list li svg{
  font-size: 12px;
}
.v_listing_dropdown_list li{
  font-size: 11px;
  display: flex;
  align-items: center;
  gap: 9px;
  cursor: pointer;
  padding: 6px 0;
  border-bottom: 1px solid #eee;
}
 .v_listing_dropdown_list li:first-child{
  padding-top: 0;
}

 .v_listing_dropdown_list li:last-child{
  border-bottom: 0;
  padding-bottom: 0;
}
 .v_listing_dropdown_list li svg{
  font-size: 12px;
}

.file_upload_design{
  background-color: #ededed;
  max-width: 250px;
  text-align: center;
  padding: 20px 30px;
  border-radius: 15px;
}
.file_upload_design svg{
  font-size: 62px;
  color: #9f9f9f;
}
.file_upload_design p span{
  font-size: 14px !important;
  color: #3874ff !important;
}
.upload_image_list{
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
}
.upload_image_list .upload_image_list_grid{
  position: relative;
  margin-top: 15px;
}
.upload_image_list .upload_image_list_grid img{
  background-color: #eee;
  height: 100px;
  width: 100px;
  object-fit: contain;
  padding: 10px;
  border-radius: 10px;
}
.upload_image_list .upload_image_list_grid svg{
  position: absolute;
  top: 0;
  right: 0;
  background-color: #fc3737;
  color: #fff;
  padding: 5px;
  border-radius: 7px;
  cursor: pointer;
}

label .v_information{
  font-size: 17px;
    color: #3874ff;
}


/* popup parts */
.right_side_popu{
  background-color: #000000b5;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
}
.right_side_popu .inner_part{
  background-color: #fff;
  width: 100%;
  max-width: 400px;
  min-height: 100dvh;
  height: 100%;
  margin-left: auto;
  overflow-y: auto;
}
.right_side_popu .inner_part .top_part{
  position: relative;
  padding: 20px 20px;
  border-bottom: 1px solid #eee;
  background-color: #eee;
}
.right_side_popu .inner_part .top_part h5{
  font-size: 14px;
  font-weight: bold;
  color: #3874ff;
  margin: 0;
}
.right_side_popu .inner_part .top_part svg{
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 17px;
}
.right_side_popu .inner_part .mid_part{
  padding: 20px;
  border-bottom: 1px solid #eee;
}
.right_side_popu .inner_part .mid_part h5{
  font-size: 12px;
  margin-bottom: 10px;
  border-bottom: 1px solid #eee;
  padding-bottom: 5px;
}
.right_side_popu .inner_part .mid_part ul li{
  font-size: 11px;
}
.right_side_popu .inner_part .mid_part ul li p{
  font-size: 11px;
  font-weight: bold;
}

.v_certificate_missing{
 padding: 1px 5px;
 margin-left: 5px;
 border: 1px solid rgb(237, 72, 72);
 color: rgb(237, 72, 72);
 border-radius: 3px;
}