@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,500;0,700;0,800;1,500&display=swap");

:root {
  /* --primary: #00838C;
  --secondary: #000000; */
  --primary: #324438;
  --secondary: #f4b905;
  --dark: #3e465b;
}
:focus-visible {
  outline: none !important;
}
.z-index-3 {
  z-index: 99;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.main-container {
  display: flex;
}

.title {
  font-size: 3rem;

  place-items: center;
  text-align: left;
}

.bottom_section {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  padding: 15px 22px;
  /* position: fixed; */
  bottom: 1%;
  cursor: pointer;
  margin-top: 0.5rem;
  padding-left: 18px !important;
}

.logo {
  font-size: 18px;
  line-height: 0;
}

.bars {
  width: 30px;
}

.hide {
  display: none;
}

.search2 {
  display: flex;
  align-items: center;
  /* margin: 10px 0; */
  height: 30px;
  padding: 10px;
}

.search2 input {
  border: none;
  margin-left: 10px;
  border-radius: 5px;
  color: black;
}

.routes {
  /* margin-top: 15px; */
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.sublink {
  padding-left: 3rem !important;
}

.link {
  display: flex;
  color: var(--dark);
  gap: 10px;
  padding: 5px 10px;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  align-items: center;
}

.link:hover {
  border-right: 4px solid white;
  background: #eff2f6;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  color: #3e465b !important;
  align-items: center;
  border-radius: 6px !important;
}

.link.active {
  color: #3874ff;
  font-weight: 600;
}

.link_text {
  white-space: nowrap;
  font-size: 0.9rem;
}

.menu {
  display: flex;
  color: var(--dark);

  padding: 5px 10px;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  /* justify-content: space-between; */
  align-items: center;
}

.menu_item {
  display: flex;
  gap: 10px;
  justify-content: space-between;
}

.menu_container {
  display: flex;
  flex-direction: column;
  /* background-color: rgb(220, 220, 220); */
}

.menu_container .link {
  padding-left: 20px;
  /* border-bottom: #fff 0.5px solid; */
}

.header-card {
  background: #ffffff;
  /* box-shadow: 0px 14px 80px rgb(34 35 58 / 20%); */
  transition: all 0.3s;
  text-align: left;
  position: relative;
  display: flex;
  min-width: 0;
  word-wrap: break-word;
  padding: 5px 20px;
  border-bottom: 1px solid #cbd0dd;
}
.navbar-light .navbar-toggler {
  box-shadow: none;
}

.logo2 {
  width: 100px;
}

.logo3 {
  width: 140px;
}

.sign-in {
  text-decoration: none;
  margin-right: 10px;
  color: white;
  padding: 8px;
  border-radius: 5px;
  background-color: var(--primary);
}

.sign-up {
  text-decoration: none;
  color: white;
  padding: 8px;
  border-radius: 5px;
  background-color: #00073d;
}

/* ::-webkit-scrollbar {
  display: none;
} */

.login-image {
  width: 80%;
}

.sign-in-logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 15%;
}

.grow {
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgb(0 0 0 / 0%);
  transition-duration: 0.3s;
  transition-property: transform;
}

.grow:hover,
.grow:focus,
.grow:active {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

/* .tablescroll {
  overflow-y: scroll;
  height: 650px;
  display: block;
} */

.mg10 {
  margin: 20px;
}

.table-h {
  max-height: 75dvh;
}

.detail-h {
  max-height: 84dvh;
}

.form-btn {
  background-color: var(--primary);
  color: white;
}

.logout {
  font-size: 22px;
  margin-right: 15px;
  margin-left: 10px;
  color: #00073d;
}

.logout:hover {
  font-size: 22px;
  margin-right: 15px;
  margin-left: 10px;
  color: #00073d;
}

.popup {
  position: relative;
  background: rgb(255 5 5 / 70%);
  padding: 0.5rem;
  border-radius: 4px;
  color: white;
  font-weight: 600;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
}

.close-icon {
  text-decoration: none;
  color: white;
  font-size: 18px;
  font-weight: 900;
}

.MuiDataGrid-columnHeaderTitle {
  font-weight: 600 !important;
}

.add-btn {
  display: flex;
  justify-content: space-between;
}

.add-btn a {
  text-decoration: none;
}

.modal {
  top: 20%;
  position: absolute;
  left: 30%;
  display: block;
  width: auto;
  height: auto;
  right: 20%;
}

.modal-title {
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}

.modal-title a {
  text-decoration: none;
  color: #fff;
  background: var(--secondary);
  padding: 0.4rem 0.8rem 0.2rem 0.8rem;
  font-size: 16px;
  border-radius: 5px;
}

.modal-btn {
  display: flex;
  justify-content: center;
}

.form-btn2 {
  background: #cfcfcf;
  margin-left: 15px;
}

.form-btn2:hover {
  color: var(--secondary);
}

.blur {
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -o-filter: blur(2px);
  -ms-filter: blur(2px);
  filter: blur(2px);
}

.detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.detail .data {
  font-weight: 400;
}

.heading {
  background: #e4e2e2;
  padding: 0.5rem;
  border-radius: 5px;
}

.detail2 {
  display: flex;
}

.detail2 .data {
  font-weight: 400;
}

.edit-h {
  height: 80dvh;
  overflow-y: auto;
}

.add-h {
  max-height: 80dvh;
  overflow-y: auto;
}

.modal-button {
  font-size: 18px;
  margin: 0;
  padding: 0px 10px 0px 10px;
  font-weight: 600;
  background: var(--secondary);
  color: white;
  border-radius: 5px;
}

.overflow-y {
  overflow-y: auto;
}

.table-heading a {
  text-decoration: none;
  background: var(--primary);
  color: white;
  padding: 0.5rem;
  border-radius: 5px;
}

.add-heading {
  margin-left: 25px;
  margin-top: 20px;
}

.form-h {
  max-height: 75dvh;
}

.add-heading2 {
  display: flex;
  align-items: center;
  margin-left: 25px;
  margin-top: 2rem !important;
}

.icon-card {
  background: #ffffff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 5px 15px;
  border-radius: 5px;
  transition: all 0.3s;
  text-align: left;
  /* z-index: 1; */
  font-size: 25px;
  margin-right: 20px;
}

.verify {
  display: flex;
  justify-content: center;
  height: 100dvh;
  align-items: center;
  font-size: 30px;
}

.forgot-summary {
  color: #878484;
  font-size: 14px;
}

.user-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 5px;
}

.dropdown-menu[data-bs-popper] {
  right: 0;
  left: 0;
}

.user {
  text-decoration: none;
}

.user h6 {
  margin-left: 10px;
  margin-bottom: 0;
}

.margin-b-0 {
  margin-bottom: 0;
}

.add-heading3 {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.btn-auth {
  position: relative;
  background: var(--primary);
  color: var(--secondary);
  font-weight: 600;
  padding: 0.7rem;
  border: none;
  border-radius: 20px 20px 20px 20px;
  font-weight: 700;
  background-image: linear-gradient(
      to bottom,
      var(--primary) 50%,
      var(--secondary) 50%
    ),
    linear-gradient(to bottom, var(--secondary) 50%, transparent 50%);
  -webkit-background-clip: text, padding-box;
  background-clip: text, padding-box;
  -webkit-text-fill-color: transparent;
  color: transparent;
  background-size: 100% 200%;
  background-position: bottom;
  transition: background-position 0.3s ease-in-out;
}

.btn span {
  font-weight: 600;
}

.btn-auth:hover {
  background-position: top;
}

.react-pdf__Page__textContent {
  display: none;
}

.react-pdf__Page__annotations {
  display: none;
}

.pdf-view {
  width: 40%;
  border: 1px solid;
  z-index: 1;
  position: relative;
  height: 35rem;
  overflow: auto;
}

.profile-img {
  border-radius: 50%;
  width: 200px;
  height: 200px;
}

.gradient2 {
  background: #d0cfcf;
  border-radius: 10px;
  padding: 1rem;
  color: white;
}

.gradient2 p {
  color: #fff;
}

.gradient2 h6 {
  color: var(--secondary);
}

.main2 {
  height: 100dvh;
  overflow: auto;
}

.routes a {
  text-decoration: none;
}

.ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
  background: #efeff1 !important;
  border-radius: 0px 0px 10px 10px !important;
}

.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar,
.ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
  background: #efeff1 !important;
  border-radius: 10px 10px 0px 0px !important;
}

.ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable,
.ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
  background: #efeff1 !important;
  border-radius: 0px 0px 10px 10px !important;
}

.success-popup2 {
  position: absolute;
  background: rgb(22 191 191 / 80%);
  padding: 1rem;
  border-radius: 10px;
  color: white;
  font-weight: 600;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  right: 2rem;
  top: 5rem;
}

.dropdown-menu {
  padding: 0;
  width: 100%;
  min-width: 10rem !important;
}

.dropdown-menu a {
  text-decoration: none;
  background: none;
  color: var(--secondary);
  padding: 0.5rem;
  border-radius: 5px;
}

.dropdown-btn {
  background: var(--secondary) !important;
  border: none;
  padding: 0.5rem;
  font-weight: 600;
}

.dropdown-btn:hover {
  background-color: var(--secondary) !important;
}

.dropdown-btn:focus {
  background-color: var(--secondary) !important;
}

.email-popup {
  background: var(--secondary);
  color: white;
  border-radius: 10px;
  padding: 0.5rem;
  margin-bottom: 20px;
  font-weight: 600;
}

.header-list2.v-header-list2 sup {
  background-color: #3874ff;
  width: 20px;
  display: flex;
  height: 20px;
  position: absolute;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  top: -5px;
  right: -9px;
  color: #fff;
  font-size: 10px;
}

@media only screen and (min-width: 767px) {
  .document_modal {
    position: absolute;
    left: 25%;
    display: block;
    width: auto;
    height: auto;
    right: 25%;
    z-index: 999;
    margin: auto;
  }

  .container_pad {
    margin: 0 2rem;
  }

  .sidebar-size {
    /* width: 4% !important; */
  }

  .sidebar-size2 {
    /* width: 16% !important; */
  }

  .wdh1 {
    /* width: 84%; */
  }

  .wdh2 {
    width: 96%;
  }

  .sidebar {
    background-color: #ffffff;
    color: var(--dark);
    height: calc(100dvh - 65px);
    overflow-y: auto;
    transition: all 0.3s ease-in-out 0s;

    border-right: 1px solid #cbd0dd;
    z-index: 1;
  }

  .header-list {
    display: none;
  }

  .border-r {
    border-right: 1px solid var(--secondary);
  }

  .modal2 {
    /* top: 0%; */
    position: absolute;
    left: 10%;
    display: block;
    width: auto;
    height: auto;
    right: 10%;
    margin: auto;
  }

  .login-heading {
    color: black;
    font-size: 4rem;
    font-weight: 800;
    line-height: 1.5em;
  }

  .login-summary2 {
    color: #0a102f;
    font-size: 1.125rem;
    margin-top: 2rem;
    font-weight: 400;
    line-height: 1.666em;
  }

  .border-right {
    border-right: 1px solid #dee2e6 !important;
  }

  .tab-btn {
    font-size: 20px;
    padding: 10px 60px;
    cursor: pointer;
    background: #ffffff;
    box-shadow: 0px 14px 80px rgb(34 35 58 / 35%);
    border: 0;
    outline: 0;
    border-radius: 5px;
    margin-right: 20px;
  }

  .table-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 1.5rem 0rem 1.5rem;
    /* margin-top: 5rem; */
  }

  .wdh1 .navbar {
    position: fixed;
    right: 0%;
    left: 16%;
    z-index: 999;
  }

  .wdh2 .navbar {
    position: fixed;
    right: 0%;
    left: 4%;
    z-index: 999;
  }

  .dashboard-pad {
    padding: 0rem 3rem 3rem 3rem !important;
  }

  .dashboard-pad2 {
    padding: 0rem 3rem 1rem 3rem !important;
  }

  .pad-right {
    padding-right: 1rem;
  }

  .pad-left {
    padding-left: 1rem;
  }
}

@media only screen and (max-width: 768px) {
  .owner-width {
    width: 90% !important;
  }
  .document_detail_modal {
    position: absolute;
  }
  .modal-title {
    font-size: 18px !important;
    font-weight: 600;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .document_modal {
    position: absolute;
    left: 0%;
    display: block;
    width: auto;
    height: auto;
    right: 0%;
    z-index: 999;
    margin: auto;
  }

  .container_pad {
    margin: 0;
  }

  .chart-title p {
    font-size: 0.8rem;
  }

  .chart-title div {
    font-size: 1.2rem !important;
  }

  .table-heading a {
    padding: 0.3rem !important;
    font-size: 0.8rem;
  }

  .dropdown-btn {
    /* padding: 0.3rem !important; */
    /* font-size: 0.8rem; */
  }

  .icon-card {
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    font-size: 20px !important;
    margin-right: 20px;
    padding: 3px 8px !important;
    text-align: left;
    transition: all 0.3s;
    z-index: 1;
  }

  .modal {
    top: 25% !important;
    position: absolute;
    left: 5% !important;
    display: block;
    /* justify-content: center; */
    width: auto !important;
    height: auto !important;
    right: 5% !important;
    z-index: 1;
  }

  .main2 {
    padding-bottom: 4rem;
  }

  .bars {
    margin-right: 10px;
  }

  .bottom_section {
    justify-content: start !important;
    position: relative !important;
  }

  .sidebar-size {
    width: 0% !important;
  }

  .sidebar-size2 {
    width: 65% !important;
    position: absolute;
    /* z-index: 10; */
  }

  .table-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 1.5rem 0rem 1.5rem !important;
    flex-wrap: wrap;
    /* margin-top: 4rem; */
  }

  .wdh1 {
    width: 100%;
  }

  .section_4 {
    padding: 2.5rem 2rem 2.5rem 2rem !important;
  }

  .wdh2 {
    width: 100%;
  }

  .sidebar {
    background-color: #ffffff;
    color: var(--secondary);
    height: calc(100dvh - 55px);
    overflow-y: auto;
    transition: all 0.3s ease-in-out 0s;
    border-right: 1px solid #cbd0dd;
    z-index: 7;
  }

  .header-list2 {
    display: none !important;
  }

  
  /* .mb5 {
    margin-bottom: 5rem !important;
  } */

  .modal2 {
    top: 10%;
    position: absolute;
    left: 0%;
    display: block;
    width: auto;
    height: auto;
    right: 0%;
    z-index: 1;
  }

  .login-heading {
    color: black;
    font-size: 2.6rem;
    font-weight: 800;
    line-height: 1.5em;
  }

  .login-summary2 {
    color: #0a102f;
    font-size: 1.125rem;
    margin-top: 2rem;
    font-weight: 400;
    line-height: 1.666em;
  }

  .detail-btn {
    padding: 0.5rem 1rem;
    margin-top: 10px;
  }

  .container2:before {
    left: 42% !important;
  }

  .card-footer {
    position: relative !important;
    margin-bottom: 0 !important;
  }

  .summary-w {
    width: 90% !important;
  }

  .tab-btn {
    font-size: 12px;
    padding: 10px 16px;
    cursor: pointer;
    background: #ffffff;
    box-shadow: 0px 14px 80px rgb(34 35 58 / 35%);
    border: 0;
    outline: 0;
    border-radius: 5px;
    margin-left: 10px;
  }

  .wdh1 .navbar {
    position: fixed;
    right: 0%;
    left: 0%;
    z-index: 5;
  }

  .wdh2 .navbar {
    position: fixed;
    right: 0%;
    left: 0%;
    z-index: 5;
  }

  .dashboard-pad {
    padding: 0rem 1.5rem 1.5rem 1.5rem !important;
    padding: 0 15px !important;
  }
  .footer-title {
    padding: 15px 15px !important;
  }
  .dashboard-pad2 {
    padding: 0rem 1.5rem 1rem 1.5rem !important;
  }

  .button-container {
    text-align: left !important;
  }

  .button-container div button {
    width: 75% !important;
  }

  .main-container.v-myopct {
    position: relative;
  }
  .main-container:before {
    content: "";
    /* background: rgba(66, 165, 245, 0.69); */
    /*display: grid;
    */
    /* fill: rgba(66, 165, 245, 0.69); */
    /* -webkit-backdrop-filter: blur(4px); */
    /* backdrop-filter: blur(4px); */
    position: absolute;
    width: 0%;
    height: 100%;
    z-index: 6;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
  }
  .main-container.v-myopct:before {
    width: 100%;
  }
  .form-h {
    max-height: fit-content;
  }
}

.input-group-btn {
  position: absolute;
  right: 15px;
  top: 7px;
  z-index: 10;
}

.total {
  margin-top: -2.5rem;
  margin-left: 1rem;
}

.app-bg {
  height: 100%;
  background: url("../public/images/landing-bg.png");
  /* background-position: bottom center; */
  /* background-size: contain; */
  /* background-repeat: no-repeat; */
}

.highlight {
  background-color: var(--secondary);
  padding: 0rem 0.5rem 0.5rem 0.5rem;
}

.log-heading {
  color: var(--primary);
  font-weight: 700;
}

.login-input {
  border-radius: 20px 20px 20px 20px !important;
}

.bg1 {
  background-color: #d92550 !important;
}

.bg2 {
  background-color: #16aaff !important;
}

.bg3 {
  background-color: #444054 !important;
}

.bg4 {
  background-color: #3f6ad8 !important;
}

.bg5 {
  background-color: #f7b924 !important;
}

.sign-borad-card {
  background-image: linear-gradient(
    to top,
    #c471f5 0%,
    #fa71cd 100%
  ) !important;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(26, 54, 126, 0.125);
  border-radius: 4px;
  padding: 1.5rem 1.5rem 0rem;
  color: #fff;
}

.sign-borad-card h6 {
  font-size: 0.8rem;
}

.link:hover .icon {
  color: #3e465b;
}

.resend-btn {
  color: black;
  background: var(--secondary);
  border: none;
  padding: 0.3rem 1rem;
  border-radius: 2px;
  font-weight: 600;
  font-size: 13px;
}

/* .menu_container:has(a.link.active){
  background-color: black;
} */
.main-link {
  color: var(--dark);
  /* width: 92%; */
}

.main-link:hover {
  color: white !important;
}

.menu:hover {
  background-color: #eff2f6;
  color: #3e465b !important;
  border-radius: 6px !important;
}

.menu:hover .main-link {
  color: #3e465b !important;
}

.menu:hover .drop-btn {
  color: #3e465b !important;
}

.drop-btn {
  border: none;
  padding: 0;
  background: none;
  color: #525b75;
}

.dropdown-btn-width {
  width: 8%;
}

.tooltip-12 {
  z-index: 10;
  top: 25px;
  background-color: #f2f2f2;
  color: #000;
  border-radius: 5px;
  opacity: 0;
  position: absolute;
  -webkit-transition: opacity 0.5s;
  -moz-transition: opacity 0.5s;
  -ms-transition: opacity 0.5s;
  -o-transition: opacity 0.5s;
  transition: opacity 0.5s;
  width: 325px;
  padding: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  display: none;
}

.hover-12:hover .tooltip-12 {
  opacity: 1;
  display: block;
}

.custom-tooltip {
  position: relative;
  border-radius: 0px !important;
}

.email-modal {
  top: 30%;
  position: absolute;
  left: 40%;
  display: block;
  /* justify-content: center; */
  width: auto;
  height: auto;
  right: 20%;
  z-index: 10;
}

.email-modal-btn button {
  padding: 0.3rem 1.3rem;
}

.button-gr {
  width: fit-content;
  display: inline;
  float: right;
  border-radius: 0.5rem;
  border: 1px solid #d5d7da;
}

.chart-button1 {
  background: #ffffff;
  color: #000;
  border: none;
  font-size: 12px;
  font-weight: 600;
  padding: 5px 15px;
  border-radius: 0.5rem 0rem 0rem 0.5rem;
}

.chart-button2 {
  background: #ffffff;
  color: #000;
  border: none;
  font-size: 12px;
  font-weight: 600;
  padding: 5px 15px;
  border-left: 1px solid #d3d3d3;
  border-right: 1px solid #d3d3d3;
}

.chart-button3 {
  background: #ffffff;
  color: #000;
  border: none;
  font-size: 12px;
  font-weight: 600;
  padding: 5px 15px;
  border-radius: 0rem 0.5rem 0.5rem 0rem;
}

.chart-button1:hover,
.chart-button2:hover,
.chart-button3:hover {
  background-color: #eaecef;
}

.active-btn {
  background-color: #ced2da;
  color: #000;
  font-weight: 600;
}

.scroll-x {
  overflow-x: auto;
  width: 100%;
  display: block;
}

.form-btn:hover {
  color: white;
}

.bg-color1 {
  background-color: #f5f7fa;
}

.chart-title div {
  font-size: 1.5625rem;
  font-weight: 700;
  font-family: "Nunito Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol" !important;
  line-height: 0.8;
  margin-bottom: 0.25rem;
}

.chart-title p {
  color: #525b75;
  line-height: 1.2 !important;
  font-family: "Nunito Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol" !important;
}

.chart-title2 div {
  font-size: 1rem;
  font-weight: 700;
  font-family: "Nunito Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol" !important;
  line-height: 1;
  margin-bottom: 0.25rem;
}

.chart-title2 p {
  color: #525b75;
  font-size: 0.8rem;
  line-height: 1.2;
  font-family: "Nunito Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol" !important;
  font-weight: 600;
}

.bg-card {
  background-size: contain;
  background-position: right;
  border-radius: 0.375rem;
  background: white;
}

.bg-holder {
  /* position: absolute; */
  width: 100%;
  min-height: 100%;
  top: 0;
  left: 0;

  overflow: hidden;
  will-change: transform, opacity, filter;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-repeat: no-repeat;
  z-index: 0;
}

.chart-bg {
  background-image: url(../public/images/chart-bg.png);
  background-position: top right;
  background-size: contain;
}

.badge {
  background-color: #ffefca;
  border: 1px solid #ffcc85;
  color: #bc3803;
}

.card-body h3 {
  font-weight: 600;
}

.card-footer {
  position: absolute;
  bottom: 0;
  margin-bottom: 25px;
  width: 100%;
}

.bg-light {
  background-color: white !important;
}

.total-sum {
  font-weight: 600;
  font-size: 1.25rem;
}

.dougnnut h6 {
  font-weight: 600;
}

.dougnnut p {
  font-size: 13.5px;
}

.dougnnut-value .value {
  font-weight: 600;
}

.bullet-item {
  height: 0.5rem;
  width: 1rem;
  border-radius: 2px;
  display: block;
}

.footer-title {
  padding: 1.5rem 3rem;
}

.piechart-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: auto;
}

.pending-date {
  font-size: 0.7rem !important;
  color: #6e7891;
}

.pending-heading {
  font-size: 0.8rem !important;
  font-weight: 600;
}

.pending-summary {
  font-size: 0.8rem !important;
}

.scroll-y {
  overflow-y: auto;
}

.pending-h {
  height: 20rem !important;
}

.pending-icon {
  background: #e5ebff;
  color: blue;
  padding: 0.25rem;
  font-size: 1.5rem;
  border-radius: 50%;
  z-index: 1;
}

.text-center {
  text-align: center !important;
}

.container2:before {
  content: "";
  border-left: 1px dashed #a5a7ac;
  height: 100%;
  display: flex;
  left: 16%;
  top: 15%;
  position: relative;
}

.badge2 {
  background-color: #3874ff;
  border: 1px solid blue;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
}

.doughnut-data {
  position: relative;
  top: -15.7rem;
  left: 5.9rem;
  background: #e7e6e9;
  padding: 2rem;
  border-radius: 50%;
  height: 10rem;
  width: 10rem;
  text-align: center;
}

.detail-btn {
  border: 1px solid #d0d4da;
  padding: 0.525rem 0.8rem;
  border-radius: 4px;
  background: none;
  font-size: 0.8rem;
  color: #000;
  line-height: 1.2;
  white-space: nowrap;
  font-weight: 600;
  height: 35px;
}

.detail-btn:hover {
  background-color: #eff2f6;
  color: #000;
  font-weight: 600;
}

.summary-w {
  width: 50%;
}

.ButtonGroup {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.tab-btn.active {
  opacity: 1;
  background: var(--secondary);
  color: white;
}

.add-btn2 {
  margin-left: 10px;
  text-decoration: none;
  background: var(--primary);
  color: white;
  padding: 0.4rem;
  border-radius: 5px;
}

.mt5 {
  margin-top: 5rem !important;
}

.mt7 {
  margin-top: 7rem !important;
}

.span-gr {
  position: absolute;
  margin-left: 5px;
  height: 25px;
  display: flex;
  align-items: center;
}

.input-gr {
  padding-left: 25px;
  height: 20px;
}

label span {
  color: red;
  font-size: 1.3rem;
}

.success {
  background-color: #16aaff;
  color: white;
}

.modal-button2 {
  font-weight: 500;
  font-size: 1.3rem;
  margin-left: 0.5rem;
  color: #5f6368;
  border: none;
  background: none;
}

.modal-button2:hover {
  font-size: 1.3rem;
  margin-left: 0.5rem;
  padding: 2px 10px 0px 10px;
  font-weight: 500;
  background: #e3dfdf;
  color: #6b6969;
  border: none;
  border-radius: 50%;
  width: 2.3rem;
  height: 2.3rem;
}

.event-summary {
  font-size: 0.8rem;
  color: #3c4043;
}

.event .title {
  color: #3c4043;
  font-size: 22px;
}

.color-box {
  background-color: rgb(0, 201, 255);
  padding: 0.55rem;
  border-radius: 0.5rem;
  width: 1rem;
  height: 1rem;
  margin-top: 0.5rem;
  margin-left: 0.8rem;
}

.event-btn {
  position: absolute;
  top: 7%;
  right: 3%;
}

.chart-input {
  background-color: white !important;
  height: 2rem;
  padding: 1rem;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  padding-left: 1rem;
  font-size: 0.8rem;
  border-radius: 0.375rem !important;
  width: 60%;
  float: right;
  border: 1px solid #cbd0dd !important;
}

.dashboard-heading {
  margin-top: 2.5rem;
}

.collapse-bar {
  font-size: 0.8rem;
  color: #000;
}

.collapse-bar:hover {
  font-size: 0.8rem;
  color: #000;
}

/* .mb5 {
  margin-bottom: 5rem !important;
} */

.search-input {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  width: 23rem;
  border-radius: 2rem;
  height: 35px !important;
}

.search-input2 {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  width: 10rem;
  height: 35px !important;
  margin-right: 10px !important;
}

.search-input input[type="text"] {
  border: none !important;
  outline: none;
  flex: 1;
  /* padding: 0 8px; */
}

.search-input2 input[type="text"] {
  border: none !important;
  outline: none;
  flex: 1;
  padding: 0 8px;
}

.search input:focus {
  box-shadow: none !important;
}

.search-input:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.profile-popup {
  position: fixed;
  top: 10%;
  right: 2%;
  background-position: right;
  border-radius: 0.375rem;
  background: white;
  padding: 1rem;
  width: 18rem;
  z-index: 99;
}

.profile-pic {
  text-align: center;
  width: 100%;
}

.profile-pic h6 {
  color: #000;
  margin-top: 0.5rem !important;
  font-size: 0.8rem !important;
  font-weight: 600;
  text-transform: capitalize;
}

.profile-url {
  color: #222834 !important;
  font-size: 0.9rem;
  padding: 0.5rem;
  display: flex;
  align-items: center;
}

.profile-url span {
  color: #222834 !important;
  margin-left: 0.5rem;
  margin-top: 0.1rem;
  font-weight: 500 !important;
}

.profile-url:hover {
  background-color: #eff2f6;
  padding: 0.5rem;
  border-radius: 10px;
}

.detail-btn2 {
  border: 1px solid #d0d4da;
  padding: 0.525rem 0.8rem;
  border-radius: 0.375rem;
  background: none;
  font-size: 0.8rem;
  color: #000;
  line-height: 1.2;
  white-space: nowrap;
  font-weight: 600;
  height: 2rem;
  background-color: #eff2f6;
  display: block;
  text-align: center;
  cursor: pointer;
}

.detail-btn2:hover {
  background-color: #d9dce1;
}

.profile-popup:after {
  right: 0.9rem;
  left: auto !important;
}

.profile-popup:after {
  content: "";
  position: absolute;
  z-index: -1;
  width: 1.5rem;
  height: 1.5rem;
  top: -13px;
  left: 1.5rem;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  background: inherit;
  border-top-left-radius: 0.25rem;
  border-width: 1px 0 0 1px;
  border-style: solid;
  border-color: #dee2e6 !important;
}

.mt4 {
  margin-top: 4rem !important;
}

.profile-img2 {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 5px;
}

.mb4 {
  margin-bottom: 4rem !important;
}

.profile-btn {
  box-shadow: none !important;
}

.section_4 {
  padding: 3rem 3rem 1.8rem 3rem;
}

.MuiDataGrid-virtualScroller {
  overflow-y: hidden !important;
}

.MuiDataGrid-virtualScroller:hover {
  overflow-y: auto !important;
}

.MuiDataGrid-virtualScroller::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #f5f5f5;
}

.MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb {
  border-radius: 10px;
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3); */
  background-color: #b5b9c1;
}

.point-link {
  font-size: 0.8rem;
  font-weight: 600;
}

.routes::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #f5f5f5;
}

.routes::-webkit-scrollbar-thumb {
  border-radius: 10px;
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3); */
  background-color: #c9cdd3;
}

.heading-bold {
  font-weight: 600;
}

.tenancy-heading {
  padding: 1.5rem 1rem 1.5rem 1rem;
  display: flex;
  align-items: baseline;
}

.tenancy-heading h3 {
  font-weight: 600;
}

.tenancy-heading span {
  color: #808080;
}

.upper-title h6 {
  font-weight: 600;
}

.upload-img {
  width: 100%;
  margin: auto;
  text-align: center;
}

.upload-img img {
  width: 20%;
}

.upload-img div {
  position: absolute;
  border-radius: 5px;
  width: 67rem !important;
  height: 15rem !important;
  border: 2px dashed #ccc;
}

.upload-img input {
  width: 67rem !important;
  height: 15rem !important;
  opacity: 0;
}

.pay-btn {
  background-color: #3874ff;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  margin-right: 1rem;
  font-size: 0.9rem;
}

.button-container {
  text-align: right;
}

.maintanance-card {
  /* margin: auto; */
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2) !important;
  /* padding: 40px 55px 45px 55px; */
  padding: 1.2rem;
  border-radius: 10px;
  transition: all 0.3s;
  text-align: left;
}

.maintanance-upper {
  display: flex;
  align-items: center;
}

.maintanance-upper .icon {
  background-color: #fa4f4f;
  padding: 0.1rem;
  font-size: 1.4rem;
  border-radius: 50%;
  color: white;
}

.maintanance-upper .icon2 {
  background-color: #2591f9;
  padding: 0.3rem;
  font-size: 1.4rem;
  border-radius: 50%;
  color: white;
}

.maintanance-upper .icon3 {
  background-color: #82ff82;
  padding: 0.3rem;
  font-size: 1.3rem;
  border-radius: 50%;
  color: white;
}

.maintanance-upper h5 {
  font-weight: 600;
  margin-left: 0.5rem;
  color: #000;
}

.arrow-icon {
  font-size: 1.2rem;
  color: #000;
}

.maintain-summary {
  font-size: 0.9rem;
  color: #000;
}

.work {
  font-weight: 600;
  color: #000;
}

.maintain-mg {
  margin: 0.7rem;
}

.tb-heading {
  font-weight: 600;
}

.tb-summary {
  color: #817f7f;
}

.maintain-border {
  border-bottom: 1px solid #eae5e5;
}

.maintain h3 {
  font-weight: 600;
}

.maintain p {
  color: #a4a4a4;
}

.issue-pending {
  color: #ff0000;
  border-radius: 5px;
  padding: 0.3rem;
  /* font-weight: 600; */
  text-transform: capitalize;
}

.issue-progress {
  color: #2881ff;
  border-radius: 5px;
  padding: 0.3rem;
  /* font-weight: 600; */
  text-transform: capitalize;
}

.issue-Completed {
  color: #009700;
  border-radius: 5px;
  padding: 0.3rem;
  /* font-weight: 600; */
  text-transform: capitalize;
}

.priority-1 {
  color: #ff0000;
  border-radius: 5px;
  padding: 0.3rem;
  /* font-weight: 600; */
  text-transform: capitalize;
}

.priority-2 {
  color: #009700;
  border-radius: 5px;
  padding: 0.3rem;
  /* font-weight: 600; */
  text-transform: capitalize;
}

.formSelect {
  border-radius: 5px !important;
  height: 2.5rem !important;
  padding: 0rem 1rem !important;
  background-color: white !important;
}

.inventory h6 {
  font-size: 0.8rem;
  font-weight: 600;
}

.inventory p {
  font-size: 0.8rem;
}

.search-list {
  position: absolute;
  top: 85%;
  background-color: #fff;
  width: 23rem;
  border: 1px solid #ccc;
  border-radius: 5px !important;
  z-index: 10;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  /* padding: 40px 55px 45px 55px; */
  /* padding: 10px 0px; */
  border-radius: 15px;
  transition: all 0.3s;
  text-align: left;
  overflow: auto;
  max-height: 20rem;
}

.search-list a {
  display: block;
  color: black;
  padding: 0.5rem;
}

.search-list a:hover {
  color: #000;
  background-color: #e0edff;
  padding: 0.5rem;
  /* border-radius: 5px; */
}

.search-list::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #f5f5f5;
}

.search-list::-webkit-scrollbar-thumb {
  border-radius: 10px;
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3); */
  background-color: #c9cdd3;
}

.rm-filter-btn {
  text-align: right;
  margin-bottom: 1rem;
}

.rm-filter-btn button {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 0.3rem 1rem;
}

.document h3 {
  font-size: 1.5rem;
  font-weight: 600;
}

.document p {
  font-size: 0.85rem;
  color: darkgray;
}

.landlord-card {
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2) !important;
  padding: 15px;
  border-radius: 5px;
  transition: all 0.3s;
  text-align: left;
}

.dashboard-point .point-value {
  font-size: 1.4rem;
  font-weight: 600;
  color: #3874ff;
}

.dashboard-point .point-heading {
  color: #000;
  font-weight: 600;
  margin-top: 0.5rem;
}

.circle {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #d4d1d1;
}

.borderRight {
  flex: 1;
  border-right: 1px solid #d4d1d1;
  padding: 10px;
}

.borderRight:last-child {
  border-right: none !important;
}

.x-hidden {
  overflow-x: hidden;
}

.unit-heading {
  font-weight: 700;
  margin-bottom: 10px;
}

.label-size {
  font-size: 14px;
}

.unit-input {
  font-size: 14px;
  padding: 0.5rem;
}

.addBtn {
  margin-left: 10px;
  background: #3874ff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 0.3rem 0.5rem;
}

.outlinedBtn {
  margin-left: 10px;
  background: none;
  color: darkgray;
  border: 1px solid darkgray;
  border-radius: 5px;
  padding: 0.3rem 0.5rem;
}

.thead_gray {
  background-color: #eaeaea;
}

.export-link {
  cursor: pointer;
}

.shd-none {
  box-shadow: none !important;
}

.outstanding-price {
  border-right: 1px solid;
  margin-right: 10px;
  padding-right: 10px;
  font-weight: 700;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}

.detail-item {
  padding: 12px 10px;
  background: linear-gradient(
    180deg,
    rgb(195 194 194 / 20%) 0,
    rgba(209, 209, 209, 0.2) 100%
  );
  border: 1px solid #bbb;
  border-radius: 3px;
  width: 100%;
  display: block;
  cursor: pointer;
}

.detail-item .title {
  font-weight: 700;
  font-size: 14px;
  color: #3874ff;
}

.detail-item .price {
  color: #000;
}

.detail-item .days {
  color: #000;
  font-size: 12px;
}

.detail-item:hover {
  background-image: linear-gradient(-180deg, #e8eaed 0, #fbfcfd 99%);
}

.footer-block {
  justify-content: flex-end;
  display: flex;
  padding: 10px;
  align-items: center;
  position: absolute;
  right: 0;
  bottom: 0;
}

.footer-block p {
  font-size: 13px;
}

.footer-block span {
  margin-left: 5px;
  margin-right: 5px;
  line-height: 0;
}

.footer-block a {
  font-size: 13px;
}

.block-header {
  padding: 14px 10px;
  border-bottom: 1px solid #bbb;
}

.block-header h5 {
  font-size: 18px;
  margin-bottom: 0;
  cursor: pointer;
}

.block-header h5:hover {
  color: #3874ff;
}

.block-middle {
  min-height: 250px;
}

.task-tab {
  position: relative;
}

.task-tab:after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  width: 100%;
  border-bottom: 1px solid #e2e2e2;
  z-index: 1;
}

.block-tab {
  position: relative;
  padding: 9px;
  margin: 0 2px;
  display: inline-block;
  color: #767676;
  text-decoration: none;
  border: 1px solid #e2e2e2;
  border-bottom: 0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background: linear-gradient(-180deg, #fff 75%, #e8eaed 100%);
  cursor: pointer;
}

.block-tab.selected {
  font-weight: 700;
  color: #191919;
  background: #fff;
  border-left: 1px solid #bbb;
  border-right: 1px solid #bbb;
  border-top: 1px solid #bbb;
  z-index: 2;
}

.task-detail {
  color: darkgray;
  font-size: 12px;
}

.modal_document {
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  z-index: 1111;
  overflow-x: hidden;
  margin: 0 auto;
  margin-bottom: 60px;
  border-radius: 5px;
  background: #fff;
  height: auto;
  overflow: visible;
  margin: auto;
  position: absolute;
}

.modal_document-container {
  padding: 30px;
}

.modal_title {
  margin: -30px -30px 30px -30px;
  padding: 25px 30px 20px 25px;
  line-height: 22px;
  font-size: 18px;
  font-weight: 700;
  font-family: ProximaNova, arial, sans-serif;
  color: #4f4f52;
  word-wrap: break-word;
  background-color: #f9f9f9;
  border-radius: 5px 5px 0 0;
  border-bottom: 2px solid #e2e2e2;
  display: flex;
  justify-content: space-between;
}

.modal_title h1 {
  font-size: 18px;
}

.modal_title button {
  border: none;
  background: none;
}

.file-upload {
  width: 100%;
  margin-top: 45px;
  /* line-height: 100px; */
  background-color: #fff;
  color: #767676;
  text-align: center;
  border-radius: 7px;
  border: 1px dashed #767676;
  cursor: pointer;
  justify-content: center;
  /* display: flex; */
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
}

.hidden {
  display: none !important;
}

.thead-background {
  background: #f4f4f4;
}

.table-border {
  border: 1px solid #dee2e6 !important;
}

.modal_actions {
  margin: 30px -30px -30px -30px;
  padding: 20px 30px;
  text-align: left;
  font-family: ProximaNova, arial, sans-serif;
  border-top: 1px solid #e2e2e2;
}

.margin-top--lg {
  margin-top: 30px !important;
}

.document_detail_modal {
  top: initial;
  z-index: 1111;
  overflow-x: hidden;
  margin: 0 auto;
  margin-bottom: 60px;
  background: #fff;
  margin: auto;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100dvh;
  /* position: absolute; */
  overflow-y: auto;
}

.document-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  backdrop-filter: blur(4px);
  /* Apply blur effect to the background */
  /* display: flex;
  justify-content: center;
  align-items: center; */
  z-index: 10;
}

.document-heading {
  text-align: left;
  color: white;
  margin-bottom: 30px;
  margin-top: 20px;
}

.document-img {
  width: 100%;
  height: calc(100vh - 100px);
  object-fit: contain;
  padding-bottom: 20px;
}

.document-detail {
  margin-bottom: 15px;
}

.document-detail h6 {
  color: #767676;
  font-size: 11px;
  font-weight: 700;
  line-height: 13px;
  text-transform: uppercase;
  margin-bottom: 0;
}

.document-detail p {
  font-size: 13px;
  color: black;
}

.document-title {
  border-bottom: 1px solid #dee2e6 !important;
  padding-bottom: 0.5rem;
  font-weight: 700;
}

.sharing-card {
  padding: 30px 15px;
  border-style: solid;
  border-width: 1px;
  border-color: #e2e2e2;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 1px 10px 2px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  color: #4f4f52;
}

.file-sharing-toggle__description {
  display: block;
  font-weight: 400;
  font-size: 12px;
  margin-left: 10px;
  margin-top: 5px;
}

.file-sharing-toggle__title {
  display: block;
  font-weight: 700;
  font-size: 14px;
  margin-left: 10px;
}

.document-middle {
  overflow-y: auto;
  height: 100dvh;
}

.timeline-icon {
  background: #3874ff;
  border-radius: 50%;
  min-width: 25px;
  min-height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-dashboard-block {
  display: block;
  margin: 10px;
  height: 10px;
}

.profile_img {
  width: 200px;
  max-height: 200px;
  margin-bottom: 20px;
  border-radius: 5px;
}

.form-select:disabled,
.form-select[readonly] {
  opacity: 1;
  background-color: #d1d4d4 !important;
}

.signup-radio {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.or {
  font-size: 20px;
  font-weight: 700;
}

.browse-link {
  color: #3874ff;
}

/* Reduce expenses */

/* html,
body {

  margin: 0;

  padding: 0;

  background-color: #eee;

} */

.all-head .mian-heading {
  font-size: 40px;

  text-transform: uppercase;

  font-weight: 900;
}

.card-part-list {
  background: #fff;

  padding: 20px;

  border-radius: 10px;

  border: 4px solid #4db6a1;
}

.card-part-list table {
  background-color: #fff;

  border: 1px solid #ccc;

  width: 100%;

  position: relative;
}

.card-part-list table:last-child {
  margin-bottom: 0;
}

.card-part-list table td {
  padding: 0;

  border-left: 1px solid #ccc;
}

.card-part-list table td:first-child {
  border-left: 0;
}

.card-part-list table td.first-grid {
  min-width: 155px;
}

.card-part-list table .top-image img.first-img {
  max-width: 92px;
}

.card-part-list table td.first-grid .top-image {
  /* max-width: 55px; */

  width: 155px;

  height: 185px;

  display: flex;

  align-items: center;

  justify-content: center;
}

.card-part-list table td.sec-grid {
  min-width: 138px;

  border-left: 1px solid #ccc;

  vertical-align: middle;

  background-color: #d4d4d4;
}

.card-part-list table .price-part .price-top-part {
  background-color: #d4d4d4;

  padding: 12px;

  width: 100%;
}

.card-part-list table .price-part .price-top-part p {
  margin-bottom: 7px;

  font-size: 17px;

  font-weight: 700;
}

.card-part-list table .price-part .price-top-part h6 {
  font-size: 30px;

  font-weight: 800;

  margin-bottom: 0;
}

.card-part-list table .price-part .price-bottom-part {
  padding: 16px;
}

.card-part-list table .price-part .price-bottom-part p {
  font-size: 14px;

  line-height: 16px;

  font-weight: 600;

  margin-bottom: 10px;
}

.card-part-list table .price-part .price-bottom-part u {
  font-weight: bold;
}

.card-part-list table .legal-cover-setup {
  padding: 0 16px;

  /* max-width: 159px; */
}

.card-part-list table .legal-cover-setup .legal-top-part {
  border-bottom: 1px solid #ccc;

  text-align: center;

  min-height: 86px;

  display: flex;

  align-items: center;

  justify-content: center;
}

.card-part-list table .legal-cover-setup .legal-top-part p {
  font-weight: 900;

  margin-bottom: 0;

  line-height: 18px;
}

.card-part-list table .legal-cover-setup .legal-bottom-part {
  min-height: 103px;

  display: grid;

  align-items: center;

  align-content: start;

  padding-top: 17px;
}

.card-part-list table .legal-cover-setup .legal-bottom-part p {
  margin-bottom: 0;

  text-align: center;

  font-size: 14px;
}

.card-part-list table .legal-cover-setup .legal-bottom-part p i.fa {
  font-size: 30px;
}

.card-part-list
  table
  .legal-cover-setup
  .legal-bottom-part
  p.nubmer-with-price {
  font-size: 20px;
}

.card-part-list
  table
  .legal-cover-setup
  .legal-bottom-part
  p.nubmer-with-price
  i.fa {
  font-size: 20px;
}
.card-part-list table td.third-grid {
  min-width: 130px;
}
.card-part-list table td.six-grid {
  min-width: 150px;

  vertical-align: middle;
}

.card-part-list table .last-button-setup {
  display: grid;

  align-content: center;

  justify-content: center;
}

.card-part-list table .last-button-setup button.btn {
  background: #3874ff;

  color: #fff;

  width: fit-content;

  font-size: 20px;

  text-transform: uppercase;

  font-weight: 700;

  padding: 9px 30px;

  margin-bottom: 10px;
}

.card-part-list table .last-button-setup a.btn {
  color: #000;

  font-weight: bold;

  text-transform: capitalize;
}

.imgBlockCont {
  width: 45px;
  min-width: 45px;
  height: 39px;
  overflow: hidden;
}

.firstLtr {
  width: 100%;
  display: inline-block;
  background: #d3d3d3;
  border-radius: 25px;
  padding: 4px 12px;
  font-weight: 700;
  font-size: 20px;
  text-transform: uppercase;
}

.ttc {
  text-transform: capitalize;
}

.timeline-item-title.active {
  background: rgb(0, 127, 255) !important;
  color: white !important;
  font-size: 14px;
}

.timeline-card-content {
  min-height: auto !important;
}

/* thankyou page  */

.thankyou-btn {
  border: none;
  background: #3874ff;
  color: white;
  padding: 0.5rem 2rem;
  border-radius: 4px;
  margin-right: 10px;
}

.thankyou-btn2 {
  border: none;
  background: lightgray;
  color: black;
  padding: 0.5rem 2rem;
  border-radius: 2px;
  margin-right: 10px;
}

.confirm-icon {
  width: 35px;
  color: white;
  background: #1fce1f;
  border-radius: 50%;
  padding: 0.1rem;
  height: 35px;
  margin: 10px;
}

.thankyou-page {
  overflow-y: scroll;
  max-height: 100dvh;
}

.thankyou-page .heading {
  width: 60%;
  margin: auto;
  background: white !important;
  margin-top: 40px;
  margin-bottom: 10px;
  color: #1fce1f;
  /* display: flex; */
  align-items: center;
  margin-bottom: 10px;
  text-align: center;
}

.thankyou-page .heading h3 {
  color: #1fce1f;
  font-weight: 700;
  margin-bottom: 0;
  font-size: 22px;
}

.guest_name {
  color: black;
  margin-top: 15px;
}

.booking-summary {
  color: darkgray;
  margin: 2px 0 15px 0;
  font-size: 14px;
}

.thankyou-page ._header {
  background: #3874ff;

  padding: 100px 30px;

  text-align: center;
}

.thankyou-page ._header .logo {
  max-width: 200px;

  margin: 0 auto 50px;
}

.thankyou-page ._header .logo img {
  width: 100%;
}

.thankyou-page ._header h1 {
  font-size: 65px;

  font-weight: 800;

  color: white;

  margin: 0;
}

.thankyou-page ._body {
  margin: -70px 0 30px;
}

.thankyou-page ._body ._box {
  margin: auto;

  max-width: 80%;

  padding: 50px;

  background: white;

  border-radius: 3px;

  box-shadow: 0 0 35px rgba(10, 10, 10, 0.12);

  -moz-box-shadow: 0 0 35px rgba(10, 10, 10, 0.12);

  -webkit-box-shadow: 0 0 35px rgba(10, 10, 10, 0.12);
}

.thankyou-page ._body ._box h2 {
  font-size: 32px;

  font-weight: 600;

  color: #545454;

  margin-bottom: 20px;
}

.thankyou-page ._body ._box p {
  font-size: 21px;
}

.thankyou-page ._footer {
  text-align: center;

  padding: 50px 30px;
}

.thankyou-page ._footer .btn {
  background: #545454;

  color: white;

  border: 0;

  font-size: 14px;

  font-weight: 600;

  border-radius: 0;

  letter-spacing: 0.8px;

  padding: 20px 33px;

  text-transform: uppercase;
}

.thankyou-page ._footer .btn:hover,
.thankyou-page ._footer .btn:active,
.thankyou-page ._footer .btn:focus {
  background: #3874ff;
}

/* 404 page  */

#error-page {
  display: flex;

  align-items: center;

  justify-content: center;

  background: #fff;

  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);

  padding: 30px;

  max-width: 600px;

  margin: 0 auto;
}

#error-page .content {
  /* max-width: 600px; */

  text-align: center;
}

.content h2.header {
  font-size: 130px;

  line-height: 1em;

  position: relative;
}

.content h2.header:after {
  position: absolute;

  content: attr(data-text);

  top: 0;

  left: 0;

  right: 0;

  background: -webkit-repeating-linear-gradient(
    -45deg,
    #71b7e6,
    #69a6ce,
    #b98acc,
    #ee8176,
    #b98acc,
    #69a6ce,
    #9b59b6
  );

  background-size: 400%;

  -webkit-background-clip: text;

  -webkit-text-fill-color: transparent;

  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.25);

  animation: animate 10s ease-in-out infinite;

  background-position: center;
}

@keyframes animate {
  0% {
    background-position: 0 0;
  }

  25% {
    background-position: 100% 0;
  }

  50% {
    background-position: 100% 100%;
  }

  75% {
    background-position: 0% 100%;
  }

  100% {
    background-position: 0% 0%;
  }
}

.content h4 {
  font-size: 1.5em;

  margin-bottom: 20px;

  text-transform: uppercase;

  color: #000;

  font-size: 2em;

  /* max-width: 600px; */

  position: relative;
}

.content h4:after {
  position: absolute;

  content: attr(data-text);

  top: 0;

  left: 0;

  right: 0;

  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.4);

  -webkit-background-clip: text;

  -webkit-text-fill-color: transparent;
}

.content p {
  font-size: 1.2em;

  color: #0d0d0d;
}

.content .btns {
  margin: 25px 0;

  display: inline-flex;
}

/* message text css  */
.message-type-box.v-message-type-box {
  background-color: transparent;
  border: 0;
  padding-bottom: 10px;
}
.message-type-box.v-message-type-box button.send-btn {
  padding: 11px 23px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.messageTxt {
  width: 100%;
  height: 2.3rem;
  border: none;
  outline: none;
}
.messageTxt.v-messageTxt{
  padding: 11px 17px;
    height: auto;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    background: #efefef;
    color: #000;
}
.messageTxt2 {
  width: 100%;
  height: 5.3rem;
  border: none;
  outline: none;
}

.image-object {
  width: 180px;
  height: 130px;
  margin-top: 20px;
  object-fit: cover;
  border-radius: 4px;
}

.image-detail {
  width: 100%;
  max-width: 400px;
  height: 250px;
  object-fit: contain;
  border-radius: 5px;
  margin-top: 20px;
  background: #eee;
}

.namehead {
  display: flex;
  justify-content: space-between;
}

.msgCount {
  padding: 2px 5px;
  background: #748ce7;
  border-radius: 10px;
  color: #ffffff;
  font-size: 11px;
  line-height: 13px;
}

.approved-btn {
  background: #3874ff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 0.3rem 1rem;
  margin-left: 10px;
}

.modal-card {
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  /* padding: 30px; */
  border-radius: 5px;
  transition: all 0.3s;
  text-align: left;
}

.card__base {
  background-color: #ffffff;
  padding: 26px 20px;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.add_new_btn {
  border: none;
  background-color: #3874ff;
  color: white;
  padding: 0.5rem 1.5rem;
  border-radius: 4px;
  font-size: 14px;
  height: fit-content;
  font-weight: 700;
}

.add_new_btn2 {
  border: none;
  background-color: #3874ff;
  color: white;
  padding: 0.5rem 1.2rem;
  border-radius: 4px;
  font-size: 13px;
  height: fit-content;
  font-weight: 700;
}

.cancel_btn {
  border: none;
  /* background-color: lightgray; */
  color: black;
  padding: 0.5rem 1.5rem;
  border-radius: 4px;
  font-size: 14px;
}

.edit_btn {
  border: none;
  background-color: lightgray;
  color: black;
  padding: 0.5rem 1.5rem;
  border-radius: 4px;
  font-size: 14px;
}

.cancel_btn:hover {
  background-color: lightgray;
}

.tenancy-head {
  font-weight: 700;
  color: #161616;
  font-size: 1.1rem;
}

.sub_heading {
  color: darkgray;
  font-size: 1rem;
}

.block-info {
  /* border-left: 3px solid #3874ff; */
  /* background-color: rgba(17, 154, 228, 0.12); */
  /* background-color: rgb(255 81 226 / 12%); */
  background-color: rgb(219 248 252);
  border-radius: 2px;
  padding: 14px 10px 12px 16px;
  margin-bottom: 1.25em;
  border-radius: 6px;
}

.btn_verify {
  background-color: transparent;
  border: 1px rgba(82, 82, 82, 0.4) solid;
  color: #525252;
  border-radius: 4px;
  padding: 0.3rem 1rem;
  font-weight: 700;
}

.btn_verify:hover {
  border: 1px #3874ff solid;
}

.document_upload {
  background: linear-gradient(to right, #3874ff 50%, rgba(255, 255, 255, 0) 0%),
    linear-gradient(#3874ff 50%, rgba(255, 255, 255, 0) 0%),
    linear-gradient(to right, #3874ff 50%, rgba(255, 255, 255, 0) 0%),
    linear-gradient(#3874ff 50%, rgba(255, 255, 255, 0) 0%);
  background-position: top, right, bottom, left;
  background-repeat: repeat-x, repeat-y;
  background-size: 15px 1px, 1px 15px;
  border-radius: 5px;
  text-align: center;
  /* min-height: 310px; */
  padding: 25px;
}

.document_upload svg {
  font-size: 5rem;
  color: #3874ff;
}

.modal-h {
  max-height: 75dvh;
}

.payment__card {
  background-color: rgba(234, 236, 240, 0.35);
  border: 1px solid #d4d2d2;
  border-radius: 5px;
  padding: 1.25em;
}

.select__input {
  border: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 4px;
  height: 3rem;
}

/* .select__input div{
  padding: 10px;
} */

.outlined-radio {
  padding: 16px 5px 8px 5px;
  /* border: 1px solid #EEEFEF; */
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  width: 100%;
  /* margin-left: 10px; */
}

.outlined-radio--selected {
  border: 1px solid #161616;
}

.modal_link {
  color: #3874ff;
  background-color: transparent;
  padding-left: 0.5em;
  padding-right: 0;
  align-items: center;
  border: none;
  background: none;
  font-weight: 700;
}

.modal_link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.image-object2 {
  width: 120px;
  height: 70px;
  margin-top: 20px;
  object-fit: contain;
  border-radius: 4px;
}

/* .form-field__wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
} */

.form-field__wrapper h5 {
  color: #161616;
  font-weight: 600;
  font-size: 18px;
}

.btn__info {
  color: #ffffff;
  background-color: #465a5c;
  padding: 0.5em 2em;
  border-radius: 20px;
  border: none;
}

.agreement h6 {
  font-size: 20px;
  font-weight: 700;
}

.agreement h5 {
  font-size: 17px;
  font-weight: 600;
}

.image_summary {
  font-size: 14px;
}

.image-object3 {
  width: 36px;
  height: 36px;
  object-fit: cover;
  border-radius: 4px;
}

.select__input fieldset {
  border: none !important;
}

.image_name {
  font-size: 13px;
  margin-top: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 75%;
  overflow: hidden;
  text-align: center;
}

.image_border {
  border: 1px solid rgba(0, 0, 0, 0.23);
}

.image_border:hover {
  border: 1px solid rgba(0, 0, 0, 0.87);
}

.outlined-radio:hover {
  border: 1px solid rgba(0, 0, 0, 0.87);
}

.tenancy-card {
  background: #ffffff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 10px 10px;
  border-radius: 5px;
  transition: all 0.3s;
  text-align: left;
  margin-top: 15px;
  height: auto;
  cursor: pointer;
  /* border: 1px solid #dee2e6; */
  align-items: center;
}

.custom-table .tb-row {
  height: 4rem;
  vertical-align: middle;
}

.custom-table th {
  border-bottom: 1px solid #dee2e6 !important;
  font-weight: 500;
}

.custom-table td {
  font-size: 14px;
}

.confirm-payment {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  min-height: 4rem;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.confirm-payment p {
  margin-right: 10px;
}

.confirm-payment-btn {
  border: none;
  background-color: #3874ff;
  color: white;
  padding: 0.5rem 1.5rem;
  border-radius: 4px;
  font-size: 13px;
  display: flex;
  align-items: center;
}

.confirm-payment-btn2 {
  border: 2px solid #3874ff;
  background-color: white;
  color: #3874ff;
  padding: 0.3rem 2.5rem;
  border-radius: 4px;
  font-size: 16px;
  display: flex;
  align-items: center;
  font-weight: 700;
  cursor: none;
}

.edit-payment-btn {
  background: none;
  border: none;
  margin-right: 20px;
  font-weight: 600;
}

.edit-payment-btn:hover {
  text-decoration: underline;
}

.payment_form__wrapper h5 {
  color: #161616;
  font-weight: 600;
  font-size: 15px;
}

.payment_form__wrapper p {
  font-size: 13px;
}

.payment_form__wrapper input {
  padding: 0.8rem !important;
}

.payment_form__wrapper2 div {
  padding: 0.6rem !important;
}

.tenancy-icon {
  background: #f0f0f0;
  border-radius: 5px;
  padding: 0.3rem;
  margin-right: 10px;
}

.tenancy-icon svg {
  font-size: 30px;
}

.tenancy-value p {
  font-weight: 600;
  font-size: 13px;
}

.tenancy-value span {
  font-weight: 700;
  font-size: 13px;
}

.tennacy-status {
  display: flex;
  align-items: center;
}

.tennacy-status svg {
  font-size: 20px;
  margin-right: 5px;
}

/* .MuiPaper-elevation.MuiPaper-rounded {
  box-shadow: 0 0 1px #d1d1d1 !important;
} */

.tenancy-popover {
  color: #222834 !important;
  font-size: 0.9rem;
  padding: 0.5rem;
  display: flex;
  align-items: center;
}

.tenancy-popover span {
  color: #222834 !important;
  margin-left: 0.5rem;
  margin-top: 0.1rem;
  font-weight: 500 !important;
}

.tenancy-popover:hover {
  background-color: #eff2f6;
  padding: 0.5rem;
  border-radius: 0px;
}

.tenancy-heading-bold {
  font-weight: 700;
}

.tenancy-detail-heading {
  font-weight: 700;
  font-size: 18px;
  color: #3874ff;
  /* border-bottom: 2px solid #3874ff; */
  /* color: white; */
  /* padding: 0.5rem; */
  /* border-radius: 4px; */
  width: fit-content;
  /* padding: 0.5rem 4rem; */
}

.tenancy-data .data {
  color: darkgray;
  font-size: 14px;
}

.block_warning {
  background-color: rgb(255 69 69 / 40%);
  border-color: #ff453a;
}

.block_warning h6 {
  font-size: 18px;
  font-weight: 700;
}
.new_menu {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
}
/* .new_menu .route-icon,
.link .route-icon {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
} */

.sidebar-icon {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  object-fit: cover;
}

.quesCont {
  display: flex;
  flex-direction: column-reverse;
}
.custmMsg {
  padding: 2px 10px;
  margin-top: 5px;
}

.mobile-chat-tabs ul.v-main-heading li {
  cursor: pointer;
  padding: 10px 9px;
  border-radius: 40px;
  background: #3874ff;
  color: #ffff;
  font-weight: 600;
  font-size: 14px;
  width: 100%;
  text-align: center;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  -ms-border-radius: 40px;
  -o-border-radius: 40px;
}
.mobile-chat-tabs .v-all-conversation-tab {
  max-height: 0;
  transition: max-height 0.15s ease-out;
  overflow: hidden;
}
.mobile-chat-tabs .v-all-conversation-tab.show {
  max-height: 1000px;
  transition: max-height 0.25s ease-in;
}
.mobile-chat-tabs .v-all-conversation-tab {
  position: fixed;
  z-index: 99;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  /* background: rgba(66, 165, 245, 0.69); */
  /* display: grid; */
  fill: rgba(66, 165, 245, 0.69);
  backdrop-filter: blur(4px);
}
.mobile-chat-tabs .v-all-conversation-tab .main-cont {
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  position: relative;
  bottom: -100%;
  transform: translateY(-100%);
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  min-height: 300px;
}
.mobile-chat-tabs .v-all-conversation-tab h3 {
  font-size: 15px;
  margin-top: 20px;
  text-decoration: underline;
  color: #3874ff;
  text-transform: capitalize;
  position: relative;
}
.mobile-chat-tabs .v-all-conversation-tab h3::after {
  content: "";
  position: absolute;
  top: -15px;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  width: 50px;
  height: 2px;
  background-color: #bdbdbd;
}
.mobile-chat-tabs .v-all-conversation-tab h3 span svg {
  font-size: 17px;
  cursor: pointer;
}
.mobile-chat-tabs .v-all-conversation-tab ul {
  max-height: 220px;
  overflow: auto;
  padding-right: 10px;
  margin-right: -10px;
  /* display: flex !important;
  flex-direction: column-reverse; */
}

.v-card-table-res {
  padding-right: 15px !important;
  margin-right: -15px;
}
.v-left-select-right-button .add_new_btn {
  min-width: 128px;
  display: flex;
  align-items: center;
  line-height: 24px;
  margin: 0 auto;
  min-height: 48px;
}
.v-add-tenancy-responsive .outlined-radio {
  padding: 15px 15px 15px 15px;
}
.MuiDataGrid-cell--withRenderer button {
  min-width: 30px !important;
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (max-width: 1200px) {
  .unit-v-grid div {
    width: 100% !important;
  }
  .unit-v-grid div.v-check-box {
    width: fit-content !important;
    margin-top: 10px;
  }
  .unit-v-grid div.v-check-box div {
    width: fit-content !important;
  }
  .qVxlE {
    /* margin-top: -6px !important; */
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
  .maintenance-pad {
    padding: 0rem 0rem 0rem 0rem;
  }
  .v-add-tenancy-responsive .MuiStepper-root {
    justify-content: center;
    flex-wrap: wrap;
  }
  .v-add-tenancy-responsive .MuiStepper-root .MuiStepConnector-root {
    display: none;
  }
}

@media screen and (max-width: 991px) {
  .bottom_section {
    display: none;
  }
  .routes {
    height: calc(100vh - 80px) !important;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media screen and (max-width: 900px) {
  .v-table-card-design-tenancies .MuiGrid-container .MuiGrid-item {
    justify-content: space-between;
    padding-left: 0 !important;
    align-items: center;
    border-bottom: 1px solid #d9d9d9;
    border-right: 0;
    padding: 10px;
    padding-right: 0;
    margin-bottom: 0 !important;
  }
  .v-table-card-design-tenancies .MuiGrid-container .MuiGrid-item:last-child {
    border-bottom: 0;
  }
  .v-table-card-design-tenancies .MuiGrid-container .MuiGrid-item:first-child {
    border-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
  .v-table-card-design-tenancies .MuiGrid-container {
    position: relative;
  }
  .v-table-card-design-tenancies .MuiGrid-container .MuiGrid-item .border-left {
    position: absolute;
    top: 8px;
    right: 8px;
  }
}
@media screen and (max-width: 768px) {
  .routes {
    height: calc(100vh - 70px) !important;
  }
}
@media only screen and (max-width: 768px) {
  .page-container2 .chat-section {
    /* height: 100% !important; */
    height: calc(100dvh - 14rem) !important;
  }
  .page-container2 {
    margin: 0;
  }
  .conversation,
  .questions-you-can-ask .scroll-container {
    height: 100%;
    max-height: 100%;
  }
  .conversation {
    height: fit-content;
  }
  .page-container2,
  .page-container2 .conversation .smallscroll {
    height: 100% !important;
  }
  .receiver-message {
    margin-right: 0;
  }
  .imgBlockCont {
    height: 41px;
  }
  .message-type-box {
    position: fixed;
    background: #fff;
    border-radius: 9px;
    bottom: 0;
    margin-bottom: 0;
  }
  .message-type-box1 {
    position: fixed !important;
    bottom: 0;
    left: 0;
  }
  .MuiStepper-root .MuiStep-root .MuiStepConnector-root {
    display: none;
  }
  .MuiStepper-root .MuiStep-alternativeLabel {
    padding: 0 6px;
  }
  .MuiStepper-root
    .MuiStep-alternativeLabel
    .MuiStepLabel-alternativeLabel
    span.MuiStepLabel-label {
    margin-top: 4px;
  }
  .v-add-tenancy-responsive
    .MuiStepper-root
    span.MuiStepLabel-root
    span.MuiStepLabel-labelContainer {
    display: none;
  }
  .property_upper.v-property_upper-res {
    padding-bottom: 5px;
    margin-bottom: -5px;
  }
  .v-timeline-design .jKxxvP,
  .v-timeline-design .qLlNn {
    padding: 0;
  }
  .v-timeline-design .ilCSCJ {
    right: 0;
  }
  .v-timeline-design .TimelineTitleWrapper-sc-1427v1d-3 {
    min-width: 102px;
  }
  img.document-img {
    height: calc(100vh - 68vh);
  }
  .document_detail_modal {
    height: calc(100vh - 51vh);
  }

  .modal_document-container {
    padding: 15px;
  }
  .modal_document-container .modal_title {
    padding-bottom: 0;
    margin-bottom: 10px;
  }
  .modal_document-container .modal_title.v-modal_title {
    margin: 0;
    padding: 13px;
    padding-bottom: 2px;
  }
  .modal_document-container .margin-top--lg.v-modal_actions {
    margin: 0;
    padding: 9px 0px;
    padding-bottom: 0px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

.delete-icon svg {
  font-size: 20px;
  color: red;
}
@media only screen and (max-width: 576px) {
  .all-head .mian-heading {
    font-size: 23px;
  }
  .v-left-select-right-button .add_new_btn {
    min-width: auto;
  }
  .table-heading {
    padding: 1.5rem 0rem 0rem 0rem !important;
  }
  .table-heading.px-4.px-md-3 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .search-icon {
    background: #ecf0f5;
    margin-right: 0;
    padding-right: 11px;
    font-size: 22px;
    width: 29px;
  }
  .filter {
    overflow: hidden;
  }
  .v-work-status {
    -moz-column-count: 1 !important;
    -moz-column-gap: 6px !important;
    -webkit-column-count: 1 !important;
    -webkit-column-gap: 6px !important;
    column-count: 1 !important;
    column-gap: 6px !important;
  }
  .MuiStepper-root
    .MuiStep-alternativeLabel
    .MuiStepLabel-alternativeLabel
    span.MuiStepLabel-label {
    display: none;
  }
  .mobile-chat-tabs ul.v-main-heading li {
    padding: 5px 7px;
    font-size: 12px;
  }
  .filter input {
    font-size: 14px;
  }
  .main2 {
    padding-bottom: 1rem;
  }
  .vmargin-respon.form-h.overflow-y.mg10.pb-3.pb-md-0.overflow-x-hidden {
    margin: 10px;
    margin-right: -5px;
  }
  .v-preview.preview {
    height: auto;
  }
  .v-property_upper-res .property_heading {
    padding: 8px;
    margin-right: 10px;
  }
  button.add_new_btn.v-add_new_btn {
    padding: 8px 10px;
  }
  .v-spacin-setup .py-4.px-3.p-sm-4 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.owner-width {
  width: 70%;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}
.condvheads {
}
.condvheads:hover {
  cursor: pointer;
}

.czx {
  z-index: 1;
}

.cstmuiSlct {
}
.cstmuiSlct .MuiSelect-select {
  padding: 13px 10px 13px 10px;
  border: none;
}

.cstmuiSlct .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: rgb(247 247 247 / 23%);
}

.tenancy_value h6 {
  color: #3874ff;
}

.tenancy_value a {
  color: #3874ff;
}

.download-btn {
  border: none;
  background-color: rgb(180, 180, 180);
  color: white;
  padding: 0.5rem 1.5rem;
  border-radius: 3px;
  font-size: 0.9rem;
}

.document-card {
  border-radius: 6px !important;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.1) !important;
}

.dashborad-link {
  width: fit-content;
  display: block;
}

.document_icon {
  background: #3874ff;
  height: fit-content;
  border-radius: 4px;
}

.document_icon svg {
  color: white;
  font-size: 50px;
  padding: 0.2rem;
  cursor: pointer;
}

.document_content{
  margin-right: 10px;
}

@media only screen and (max-width: 400px) {
  .v-copyright-set{
    font-size: 9px;
  }
  .table-heading .add-heading3 h3.margin-b-0 {
    font-size: 13px;
  }
  .table-heading .d-flex button {
    font-size: 13px;
    padding: 5px 7px 2px;
  }
}


.MuiButtonBase-root span.MuiTouchRipple-root {
  display: none !important;
}