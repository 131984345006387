



.header_button{
    color: #fff;
    text-transform: capitalize;
}

.header_link:hover{
    color: #3874ff;
}

.header_logo{
    width: 160px;
}

.header_logo_sm{
    width: 120px;
}

.popoverpadding{
    padding: 5px;
    margin: 5px;
    padding-right: 15px;
}
@media only screen and (max-width: 768px) {


    .module_background{
        
        padding: 60px 0px 60px 0px !important;
    }

    .module_background2{
       
        padding: 60px 0px 60px 0px !important;
    }

    .header_background{
        /* background-color: white !important; */
        color: black !important;
        position: fixed !important;
        /* top: 0 !important; */
        width: 100% !important;
        z-index: 999;
        box-shadow: none !important;
    }

    .header_container{
        width: 100% !important;
        margin: 0 auto;
    
    }

    

}





@media only screen and (min-width: 768px) and (max-width: 967px) {

    .header_background{
        /* background-color: white !important; */
        color: black;
        position: fixed !important;
        /* top: 0 !important; */
        width: 100% !important;
        z-index: 999;
        box-shadow: none !important;
    }

}


@media only screen and (min-width: 768px) {

    .header_background{
        /* background-color: white !important; */
        color: black;
        position: fixed !important;
        /* top: 0 !important; */
        width: calc(100vw - 17px) !important;
        z-index: 999;
        box-shadow: none !important;
    }

    .header_container{
        width: 85% !important;
        margin: 0 auto;
    
    }


}

